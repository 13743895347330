import axios from 'axios'
import moment from 'moment'

let baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL

const contentType = 'application/json'

export const cancelAppointment = async (cancelReasonCode, customerId, existingAppointment) => {
  const {
    appointmentDate,
    appointmentTimeSlot,
    customerCellphone,
    customerFirstName,
    customerLastName,
    externalContractNumber,
    productSequenceNumber,
    serviceAvengerContractNumber,
    serviceOrderNumber,
    serviceRequestType,
    servicerID,
    servicerName,
    servicerPhone,
    servicerWebsite,
    serviceSite,
    authorizationNumber,
    providerType,
  } = existingAppointment

  return await axios(baseURL + 'standard/cancel/serviceappointment', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber,
      externalContractNumber,
      externalOrderNumber: '', // (Leave blank)
      serviceOrderNumber,
      servicerSource: 'SP', // (SP = Service Power)
      crmKey: customerId,
      firstName: customerFirstName,
      lastName: customerLastName,
      consumerPhone: customerCellphone,
      productSeqeuenceNumber: productSequenceNumber,
      servicerID,
      appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
      appointmentTimeSlot: appointmentTimeSlot,
      serviceType: serviceRequestType,
      serviceLocation: serviceSite,
      servicerName,
      servicerPhone,
      servicerWebsite,
      cancelReason: cancelReasonCode,
      authorizationNumber,
      providerType,
    },
  })
}

export const getCancelReasons = async () => {
  return await axios(baseURL + 'standard/variables/getcancelreasons', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceNetworkID: 'SP', //SP = ServicePower
    },
  })
}

export const getExternalPayments = async (contractNumber, externalContractNumber) => {
  return await axios(baseURL + 'standard/lookups/externalpayments', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      externalContractNumber: externalContractNumber,
      dealerNumber: externalContractNumber,
    },
  })
}

export const getServiceOrders = async (contractNumber) => {
  return await axios(baseURL + 'standard/lookups/getserviceorders', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // KEY,  // changed this
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
    },
  })
}

export const getFailureTypes = async (tierCode) => {
  return await axios(baseURL + 'standard/variables/getfailuretypes', {
    method: 'POST',
    headers: {
      // Authorization: KEY,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      'Content-Type': contentType,
    },
    data: {
      tierID: tierCode,
    },
  })
}

export const getAppointments = async (
  address1,
  address2,
  bookingType,
  businessName,
  cellPhone,
  city,
  countryCode,
  customerId,
  dealerId,
  email,
  firstName,
  lastName,
  otherPhone,
  stateProvince,
  zipCode,
  brandCode,
  modelNumber,
  productCode,
  productTierCode,
  serialNumber,
  beginSearchDate,
  procedureCode,
  serviceElevation,
  serviceNotice,
  serviceType,
  contractNumber,
  beginDt,
  endDt,
  warrantyType,
  warrantyTypeId,
  serviceTypeId,
  serviceLocationId,
  sequenceNumber,
) => {
  // standard/scheduling/serviceappointment
  // standard/provider/availability
  return await axios(baseURL + 'standard/provider/availability', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // KEY, // changed this
    },
    data: {
      customer: {
        address1,
        address2,
        businessName,
        cellPhone,
        city,
        countryCode,
        customerId,
        email,
        firstName,
        lastName,
        otherPhone,
        stateProvince,
        zipCode,
      },
      product: {
        brandCode,
        installDate: '',
        modelNumber,
        productCode,
        serialNumber,
        tierCode: productTierCode,
        productSequenceNumber: sequenceNumber,
      },
      service: {
        //if before today, use today.
        // beginSearchDate: moment(beginSearchDate).isSameOrAfter(moment(), 'day')
        //   ? beginSearchDate
        //   : moment().format('YYYY-MM-DD hh:mm:ss'),
        beginSearchDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        numberOfDays: 14,
        procedureCode,
        serviceElevation: serviceElevation?.trim().length > 0 ? serviceElevation?.trim() : 'Normal',
        serviceNotice,
        serviceType,
        bookingType,
        dealerID: dealerId,
        serviceLocationId: serviceLocationId,
        serviceTypeId: serviceTypeId,
      },
      warranty: {
        contractNumber,
        coverageBeginDate: moment(beginDt).format('YYYY-MM-DD'),
        coverageEndDate: moment(endDt).format('YYYY-MM-DD'),
        warrantyType,
        warrantyTypeId: warrantyTypeId,
      },
      type: 'availability',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
      // "countryId": 237,
      // "postCode": 331,
      // "plusCode": "76QXQR87+PJ",
    },
  })
}

export const updateProduct = async (
  contractNumber,
  dealerCustomerID,
  externalContractNumber,
  sequenceNumber,
  subcategory,
  brand,
  modelNumber,
  serial,
  serviceLocation,
  productPurchaseDate, // added this
) => {
  return await axios(baseURL + 'wholehome/updateproduct/productupdate', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      contractInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        dealerCustomerID,
      },
      productInfo: {
        productSequenceNumber: sequenceNumber,
        productSubCategory: subcategory,
        manufacturerName: brand,
        modelNumber,
        serialNumber: serial,
        serviceLocation,
        productPurchaseDate, // added this
      },
    },
  })
}

export const addProduct = async (
  externalContractNumber,
  onPointContractNumber,
  dealerCustomerID,
  firstName,
  lastName,
  address1,
  address2,
  city,
  state,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  country,
  productSequenceNumber,
  productSubCategory,
  manufacturerName,
  modelNumber,
  serialNumber,
  productCondition,
  serviceLocation,
  installDt,
  purchaseDt,
) => {
  return await axios(baseURL + 'wholehome/addproduct/productupdate', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      contractInfo: {
        externalContractNumber,
        onPointContractNumber,
        dealerCustomerID,
      },
      customerInfo: {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        country,
      },
      productInfo: {
        productSequenceNumber,
        productCategory: 'Major Appliance',
        productSubCategory,
        manufacturerName,
        modelNumber,
        serialNumber,
        productCondition,
        productPurchasePrice: 500,
        productPurchaseDate: moment(purchaseDt).format('YYYY-MM-DD'),
        productInstallDate: moment(installDt).format('YYYY-MM-DD'),
        serviceLocation,
      },
      misc: {
        miscGeneral1: '',
      },
    },
  })
}

export const getContractDetails = async (contractNumber) => {
  const token = localStorage.getItem('loginToken')
  return await axios(baseURL + 'standard/OPgetcontract/details', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
      // Authorization: KEY,
    },
    data: {
      onPointContractNumber: contractNumber,
    },
  })
}

export const getBookedAppointment = async (contractNumber, serviceOrderNumber) => {
  return await axios(baseURL + 'standard/lookups/getbookedappointments', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      serviceOrderNumber: serviceOrderNumber,
    },
  })
}

export const getManufacturers = async () => {
  return await axios(baseURL + 'standard/variables/getmanufacturers', {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
  })
}

export const getServiceOrderDetails = async (serviceOrderNumber) => {
  return await axios(baseURL + 'standard/lookups/claimdetails', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceOrderNumber,
    },
  })
}

export const getSubcategories = async () => {
  return await axios(baseURL + 'standard/variables/getsubcategories', {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
  })
}

export const bookAppointment = async (
  bookingType = '',
  firstName = '',
  lastName = '',
  businessName = '',
  address1 = '',
  address2 = '',
  city = '',
  stateProvince = '',
  zipCode = '',
  cellPhone = '',
  otherPhone = '',
  email = '',
  countryCode = '',
  customerId = '',
  sequenceNumber = '',
  catCode = '',
  brandCode = '',
  productCode = '',
  productTierCode = '',
  modelNumber = '',
  serialNumber = '',
  warrantyType = '',
  coverageBeginDate = '',
  coverageEndDate = '',
  serviceAvengerContractNumber = '',
  externalContractNumber = '',
  failureDate = '',
  serviceRequestType = '',
  serviceElevation = '',
  serviceOrderNumber = '',
  serviceSite = '',
  failureType = '',
  functionalityStatus = '',
  symptomCode = '',
  failureNote = '',
  selectedAppointment = '',
  specialInstructions = '',
  serviceNotice = '',
  customerRequestedDate = '',
  customerRequestedWindow = '',
  selectedRepeatOrder = '',
  bookingNote = '',
  dealerCustomerID = '',
  sortOrderReason = '',
  selectedSlot = '',
  isFnolCalled = '',
  installDt = '',
  procedureCode,
  serviceLocationId,
  warrantyTypeId,
  serviceTypeId,
  dealerName,
  productPurchaseDate = '',
  subCategory = '',
) => {
  // const { appointmentInfo } = selectedAppointment;
  // const {
  //   appointmentBrand,
  //   appointmentProduct,
  //   timeSlotData,
  //   svcrPhone,
  //   svcrName,
  //   serviceCenterID,
  //   svcrStatus
  // } = appointmentInfo;
  // const { svcrID, offerID, availableDate, timePeriod } = timeSlotData;

  let filteredSlot
  if (selectedAppointment?.serviceProvider === 'A&E') {
    filteredSlot = selectedAppointment?.timeSlot.filter((slot) => slot.slotName === selectedSlot)
  }
  return await axios(baseURL + 'standard/book/appointment', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      // Authorization: KEY,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // 'Bearer 2fbd315d-9a1c-4583-b188-7f354efd8b07', commented
    },
    data: {
      customer: {
        firstName,
        lastName,
        businessName,
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        countryCode,
        crmKey: customerId,
        repeatCall: serviceNotice === 'Repeat Request' ? 'YES' : 'NO', // to be added,
        initialAppointmentDate: selectedAppointment?.availableDate
          ? moment(selectedAppointment?.availableDate).format('YYYY-MM-DD')
          : selectedAppointment?.appointmentInfo?.svcrName !== 'NO SERVICERS FOUND'
          ? moment().format('YYYY-MM-DD')
          : '', // to be added,
        initialAppointmentWindow: selectedSlot
          ? selectedSlot
          : selectedAppointment?.appointmentInfo?.svcrName !== 'NO SERVICERS FOUND'
          ? '8-17'
          : '', // to be added
      },
      product: {
        sequenceNumber,
        brandCode,
        productCode,
        appointmentBrand:
          selectedAppointment?.appointmentBrand ||
          selectedAppointment?.appointmentInfo?.appointmentBrand ||
          '',
        appointmentProduct:
          selectedAppointment?.appointmentProduct ||
          selectedAppointment?.appointmentInfo?.appointmentProduct ||
          '',
        catCode,
        tierCode: productTierCode,
        modelNumber,
        serialNumber,
        installDate: moment(installDt).format('YYYY-MM-DD'),
        productPurchaseDate: moment(productPurchaseDate).format('YYYY-MM-DD'),
        productSubCategory: subCategory,
      },
      warranty: {
        warrantyType,
        coverageBeginDate: moment(coverageBeginDate).format('YYYY-MM-DD'),
        coverageEndDate: moment(coverageEndDate).format('YYYY-MM-DD'),
        serviceAvengerContractNumber,
        externalContractNumber,
        warrantyTypeId,
      },
      service: {
        serviceOrderNumber,
        serviceCallDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        failureDate: moment(failureDate).format('YYYY-MM-DD'),
        serviceRequestType,
        serviceElevation,
        serviceSite,
        failureType,
        functionalityStatus,
        symptomCode,
        failureNote,
        bookingType: selectedAppointment?.appointmentInfo
          ? 'forced'
          : selectedAppointment?.svcrType === 'WEB'
          ? 'soft'
          : 'firm',
        providerType: selectedAppointment?.appointmentInfo
          ? 'OPW'
          : selectedAppointment?.svcrType === 'WEB'
          ? 'OPW'
          : 'A&E',
        isReschedule: false,
        serviceNotice,
        procedureCode: procedureCode ? procedureCode : 'Field Service',
        relatedTo: serviceNotice === 'Repeat Request' ? selectedRepeatOrder : null, // to be added
        bookingNote: bookingNote,
        dealerId: dealerCustomerID,
        dealerName: dealerName,
        isFnolCalled: isFnolCalled,
        serviceLocationId: serviceLocationId,
        serviceTypeId: serviceTypeId,
      },
      appointment: {
        groupID: selectedAppointment?.servicerAccount,
        offerKey: selectedAppointment?.offerID,
        appointmentDate: selectedAppointment?.availableDate
          ? moment(selectedAppointment?.availableDate).format('YYYY-MM-DD')
          : selectedAppointment?.appointmentInfo?.svcrName !== 'NO SERVICERS FOUND'
          ? moment().format('YYYY-MM-DD')
          : '',
        appointmentTimeSlot: selectedSlot
          ? selectedSlot
          : selectedAppointment?.appointmentInfo?.svcrName !== 'NO SERVICERS FOUND'
          ? '8-17'
          : '',
        appointmentDay: selectedAppointment?.availableDate
          ? moment(selectedAppointment?.availableDate).format('dddd')
          : selectedAppointment?.appointmentInfo?.svcrName !== 'NO SERVICERS FOUND'
          ? moment().format('dddd')
          : '',
        consumerRequestDate: moment(customerRequestedDate).format('YYYY-MM-DD'),
        consumerRequestTime: customerRequestedWindow, // changed
        specialInstructions,
        preferredServicerName: 'Preferred Service Provider',

        firstAvailableDate:
          selectedAppointment?.serviceProvider === 'A&E'
            ? selectedAppointment?.firstAvailableDate
            : '',
        providerId:
          selectedAppointment?.serviceProvider === 'A&E' ? filteredSlot[0]?.providerId : '',
        workAreaCode:
          selectedAppointment?.serviceProvider === 'A&E' ? selectedAppointment?.workAreaCode : '',
        capacityArea:
          selectedAppointment?.serviceProvider === 'A&E' ? selectedAppointment?.capacityArea : '',
      },
      servicer: {
        servicerID: selectedAppointment?.appointmentInfo
          ? selectedAppointment?.appointmentInfo?.servicerId
          : selectedAppointment.provider === 'A&E'
          ? selectedAppointment.servicerId
          : selectedAppointment.servicerId,
        servicerAccount: selectedAppointment?.appointmentInfo
          ? selectedAppointment?.appointmentInfo?.serviceCenterID
          : selectedAppointment.servicerAccount,
        servicerName:
          selectedAppointment?.appointmentInfo?.svcrName || selectedAppointment.serviceProvider,
        servicerPhone:
          selectedAppointment.svcrType === 'WEB'
            ? selectedAppointment?.dispatchMethodPhone
            : selectedAppointment?.servicerAccount,
        servicerWebsite: '',
        bookingType: selectedAppointment?.appointmentInfo
          ? 'forced'
          : selectedAppointment?.svcrType === 'WEB'
          ? 'soft'
          : 'firm',
        sortOrder:
          selectedAppointment?.appointmentInfo?.sortOrder || selectedAppointment?.sortOrder,
        sortOrderReason: sortOrderReason,
        serviceUnitNumber: selectedAppointment.serviceUnitNumber || '',
      },
      parts: {
        partDistibutorID: '',
        partDistributorName: '',
        partNumber: '',
        partDescription: '',
        partQuantity: 0,
        partPrice: 0.0,
        shipperCompany: '',
        shipperTracking: '',
      },
      payment: {
        deductible: 0.0,
        poNumber: '',
        poAmount: 0.0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500.0,
      },
      additionalFunctions: {
        status1: '',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneMinuteDifference: new Date().getTimezoneOffset(),
    },
  })
}

export const rebookAppointment = async (
  bookingType,
  firstName,
  lastName,
  businessName,
  address1,
  address2,
  city,
  stateProvince,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  countryCode,
  customerId,
  sequenceNumber,
  brandCode,
  catCode,
  productCode,
  productTierCode,
  modelNumber,
  serialNumber,
  warrantyType,
  coverageBeginDate,
  coverageEndDate,
  serviceAvengerContractNumber,
  externalContractNumber,
  failureDate,
  serviceRequestType,
  serviceElevation,
  serviceOrderNumber,
  externalAppointmentNumber,
  providerType,
  serviceSite,
  failureType,
  functionalityStatus,
  symptomCode,
  failureNote,
  selectedAppointment,
  specialInstructions,
  installDt,
) => {
  const { appointmentInfo } = selectedAppointment
  const {
    appointmentBrand,
    appointmentProduct,
    timeSlotData,
    svcrPhone,
    svcrName,
    serviceCenterID,
  } = appointmentInfo
  const { svcrID, offerID, availableDate, timePeriod } = timeSlotData

  return await axios(baseURL + 'standard/booking/serviceappointment', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      customer: {
        firstName,
        lastName,
        businessName,
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        email,
        countryCode,
        crmKey: customerId,
      },
      product: {
        sequenceNumber,
        brandCode,
        productCode,
        appointmentBrand,
        appointmentProduct,
        catCode,
        tierCode: productTierCode,
        modelNumber,
        serialNumber,
        installDate: moment(installDt).format('YYYY-MM-DD'),
      },
      warranty: {
        warrantyType,
        coverageBeginDate: moment(coverageBeginDate).format('YYYY-MM-DD'),
        coverageEndDate: moment(coverageEndDate).format('YYYY-MM-DD'),
        serviceAvengerContractNumber,
        externalContractNumber,
      },
      service: {
        serviceOrderNumber,
        externalAppointmentNumber,
        providerType,
        serviceCallDate: moment().format('YYYY-MM-DD'),
        failureDate: moment(failureDate).format('YYYY-MM-DD'),
        serviceRequestType,
        serviceElevation,
        serviceSite,
        failureType,
        functionalityStatus,
        symptomCode,
        failureNote,
        bookingType,
      },
      appointment: {
        groupID: svcrID,
        offerKey: offerID,
        appointmentDate: availableDate ? moment(availableDate).format('YYYY-MM-DD') : '',
        appointmentTimeSlot: timePeriod,
        consumerRequestDate: availableDate ? moment(availableDate).format('YYYY-MM-DD') : '',
        consumerRequestTime: timePeriod,
        specialInstructions,
      },
      servicer: {
        servicerID: serviceCenterID,
        servicerName: svcrName,
        servicerPhone: svcrPhone,
        servicerWebsite: '',
      },
      parts: {
        partDistibutorID: '',
        partDistributorName: '',
        partNumber: '',
        partDescription: '',
        partQuantity: 0,
        partPrice: 0.0,
        shipperCompany: '',
        shipperTracking: '',
      },
      payment: {
        deductible: 0.0,
        poNumber: '',
        poAmount: 0.0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500.0,
      },
      additionalFunctions: {
        status1: 'Appointment Change',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
    },
  })
}

export const createFNOL = async (
  address1,
  address2,
  city,
  countryCode,
  deductible,
  claimFailureNote,
  claimFailureType,
  claimFunctionalityStatus,
  claimRequestPriority,
  claimStorySymptom,
  contractNumber,
  customerId,
  email,
  externalContractNumber,
  failureDt,
  firstName,
  hasConcessionFlg,
  lastName,
  mobileNumber,
  sequenceNumber,
  serviceNotice,
  servicerNotes,
  serviceType,
  state,
  zipCode,
  isFnolCalled,
  customerRequestedDate,
  customerRequestedWindow,
) => {
  let functionalStatus
  let failureType
  let claimReimbursementMethod = serviceNotice
  let claimStatusApproveReject
  switch (serviceType) {
    case 'Standard Exchange':
      claimStatusApproveReject = 'Standard Exchange'
      break
    case 'Advanced Exchange':
      claimStatusApproveReject = 'Advanced Exchange'
      break
    case 'Outbound Shipment Only':
      claimStatusApproveReject = 'Outbound Shipment Only'
      break
    default:
      if (deductible > 0 && hasConcessionFlg === true) {
        claimStatusApproveReject = 'Approved'
      } else {
        claimStatusApproveReject = 'Deductible'
      }
      break
  }

  switch (claimFunctionalityStatus) {
    case 'Functional':
      functionalStatus = 'F'
      break
    case 'Non-functional':
      functionalStatus = 'N'
      break
    case 'Intermittent':
      functionalStatus = 'I'
      break
    default:
      functionalStatus = 'F'
  }

  switch (claimFailureType) {
    case 'Standard':
      failureType = 'ST'
      break
    case 'Accidental Damage':
      failureType = 'AD'
      break
    case 'Theft':
      failureType = 'TH'
      break
    default:
      failureType = 'ST'
  }

  if (deductible > 0 && hasConcessionFlg) {
    claimReimbursementMethod = 'Concession'
  }
  if (serviceType === 'Advanced Exchange' && hasConcessionFlg) {
    claimReimbursementMethod = 'Concession'
  }

  return await axios(baseURL + 'standard/claims/fnolrequest', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
      'Content-Type': contentType,
    },
    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        productSequenceNumber: sequenceNumber,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email,
        address1,
        address2,
        city,
        state,
        zipCode,
        country: countryCode,
        mobileNumber: mobileNumber.replace(/\D/g, ''),
      },
      claimInfo: {
        serviceType: 'Service',
        callerType: 'Customer',
        externalClaimNumber: 'UNKNOWN',
        claimRequestPriority,
        claimDateConsumerFiled: moment(failureDt).format('YYYY-MM-DD'),
        claimDateRepaired: '',
        claimDateAdjudicated: '',
        claimStatusApproveReject,
        claimAmount: 0.0,
        claimReimbursementAmount: 0.0,
        claimReimbursementMethod,
        claimStorySymptom,
        claimFunctionalityStatus: functionalStatus,
        claimFailureType: failureType,
        claimFailureNote,
        claimPaymentMethodAccount: sessionStorage.getItem('email'),
      },
      appointmentInfo: {
        preferenceDate1: moment(customerRequestedDate).format('YYYY-MM-DD'),
        preferenceTimeSlot1: customerRequestedWindow,
        preferenceDate2: null,
        preferenceTimeSlot2: null,
        preferenceDate3: null,
        preferenceTimeSlot3: null,
        appointmentNotes: servicerNotes,
        preferredServicerName: '',
        preferredServicerAddress1: '',
        preferredServicerAddress2: '',
        preferredServicerCity: '',
        preferredServicerState: '',
        preferredServicerZipcode: '',
        preferredServicerPhone: '',
        crmKey: customerId,
      },
    },
  })
}

export const getSubcategoriesByBrand = async (brand) => {
  return await axios(baseURL + 'standard/storedprocedures/getbrandsfromxref', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      lookupType: brand,
      brandCode: '',
      productCode: '',
    },
  })
}

export const getNewContractModels = async (brandCode, productCode) => {
  return await axios(baseURL + 'standard/storedprocedures/getmodelsfromxref', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      brandCode,
      productCode,
    },
  })
}

export const createContract = async (
  firstName,
  lastName,
  address1,
  address2,
  city,
  stateProvince,
  zipCode,
  cellPhone,
  otherPhone,
  email,
  subcategory,
  brand,
  model,
  serial,
  purchaseDt,
  installDt,
  subCatCode,
  dealerInvoiceNumber,
) => {
  return await axios(baseURL + 'standard/avengerui/createcontract/initialcontract', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      customer: {
        firstName,
        lastName,
        businessName: '',
        address1,
        address2,
        city,
        stateProvince,
        zipCode,
        cellPhone,
        otherPhone,
        countryCode: 'USA',
        dealerCustomerID: '',
        email,
        languageCode: 'en-us',
      },
      contract: {
        contractPurchaseDate: moment(installDt).format('YYYY-MM-DD'),
        contractOtherTax: 0,
        contractRetailPrice: 1.0,
        contractSalesTax: 0,
        contractSKU: subcategory,
        contractSourceName: brand,
        dealerID: 'NA',
        dealerInvoiceNumber,
      },
      product: {
        catalogItemNumber: '1',
        manufacturerName: brand,
        modelName: subcategory,
        modelNumber: model,
        oemLaborWarrantyTerm: '0',
        oemMajorCompTerm: '0',
        oemOtherTerm: '0',
        oemPartsWarrantyTerm: '0',
        productCondition: 'N',
        productDeliveryInstallDate: moment(installDt).format('YYYY-MM-DD'),
        productPurchaseDate: moment(purchaseDt).format('YYYY-MM-DD'),
        productPurchasePrice: 600,
        serialNumber: serial,
        subCategory: subCatCode,
      },
      general1: '',
      general2: '',
      general3: '',
      general4: '',
    },
  })
}

export const getServicer = async (servicerNumber) => {
  return await axios(baseURL + 'standard/lookups/servicerlookup', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: { servicerNumber },
  })
}

export const updateServicer = async (
  serviceOrderNumber,
  servicerNumber,
  servicerName,
  servicerSystemId,
  appointmentDate,
  appointmentTimeSlot,
) => {
  return await axios(baseURL + 'standard/claims/update/serviceprovider', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      serviceOrderNumber,
      servicerNumber,
      servicerName,
      servicerPhone: '',
      servicerSystemID: servicerSystemId,
      appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
      appointmentTimeSlot,
    },
  })
}

export const processReimbursement = async (
  address,
  city,
  claimPayment,
  claimReimbursementMethod,
  contractNumber,
  correctiveActionDescription,
  countryCode,
  dealerPricingGroupNumber,
  email,
  externalContractNumber,
  failureCode,
  firstName,
  foodLoss,
  freight,
  hasSubclaimFlg,
  labor,
  lastName,
  mileage,
  parts,
  phone,
  postalCode,
  repairDt,
  serviceOrderNumber,
  serviceOrderNumberSub,
  state,
  tax,
  travel,
) => {
  return await axios(baseURL + 'standard/approvedclaims/payouts', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        paymentNoticeName: dealerPricingGroupNumber,
        serviceOrderNumber: hasSubclaimFlg ? serviceOrderNumberSub : serviceOrderNumber,
        sequenceNumber: 1,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email: email,
        address1: address,
        address2: '',
        city,
        state,
        zipCode: postalCode,
        country: countryCode,
        mobileNumber: phone,
      },
      claimInfo: {
        claimCashDisbursementReason: 'Reimbursement',
        claimStoryReason: failureCode,
        claimCorrectiveAction: 'Repaired',
        claimCorrectionDescription: correctiveActionDescription,
        claimDateConsumerFiled: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimFailureCauseType: 'Normal Wear And Tear',
        claimTechnicianName: 'Customer Dispatch',
        claimDateRepaired: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimOutcome: 'Repaired',
        claimDateAdjudicated: moment.utc().format('YYYY-MM-DD'),
        claimStatusApproveReject: 'Approve',
        claimAmountTotal: claimPayment,
        claimLaborAmount: labor,
        claimPartsAmount: parts,
        claimMileageAmount: mileage,
        claimTravelAmount: travel,
        claimTaxAmount: tax,
        claimFreightShippingAmount: freight,
        claimFoodLossAmount: foodLoss,
        claimReimbursementAmount: claimPayment,
        claimReimbursementMethod: claimReimbursementMethod,
        paypalAccountNumber: email,
        paymentReferenceNumber: claimReimbursementMethod + ' Reimbursement',
      },
    },
  })
}

export const processBuyout = async (
  address,
  buyoutAmount,
  city,
  correctiveActionDescription,
  claimReimbursementMethod,
  contractNumber,
  countryCode,
  dealerPricingGroupNumber,
  email,
  externalContractNumber,
  failureCode,
  firstName,
  hasSubclaimFlg,
  lastName,
  phone,
  postalCode,
  repairDt,
  serviceOrderNumber,
  serviceOrderNumberSub,
  state,
) => {
  return await axios(baseURL + 'standard/approvedclaims/payouts', {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber,
        paymentNoticeName: dealerPricingGroupNumber,
        serviceOrderNumber: hasSubclaimFlg ? serviceOrderNumberSub : serviceOrderNumber,
        sequenceNumber: 1,
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: '',
        email: email,
        address1: address,
        address2: '',
        city,
        state,
        zipCode: postalCode,
        country: countryCode,
        mobileNumber: phone?.replace(/\D/g, '').replace('+', ''),
      },
      claimInfo: {
        claimCashDisbursementReason: 'Buyout',
        claimStoryReason: failureCode,
        claimCorrectiveAction: 'Cash-Out',
        claimCorrectionDescription: correctiveActionDescription,
        claimDateConsumerFiled: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimFailureCauseType: 'Normal Wear And Tear',
        claimTechnicianName: 'Customer Dispatch',
        claimDateRepaired: moment.utc(repairDt).format('YYYY-MM-DD'),
        claimOutcome: 'Other',
        claimDateAdjudicated: moment.utc().format('YYYY-MM-DD'),
        claimStatusApproveReject: 'Approve',
        claimAmountTotal: buyoutAmount,
        claimLaborAmount: 0.0,
        claimPartsAmount: 0.0,
        claimMileageAmount: 0.0,
        claimTravelAmount: 0.0,
        claimTaxAmount: 0.0,
        claimFreightShippingAmount: 0.0,
        claimFoodLossAmount: 0.0,
        claimReimbursementAmount: buyoutAmount,
        claimReimbursementMethod: claimReimbursementMethod,
        paypalAccountNumber: email,
        paymentReferenceNumber: claimReimbursementMethod + ' Reimbursement',
      },
    },
  })
}

export const getDispatchMessageList = async () => {
  const response = await axios(baseURL + 'standard/storedprocedures/getdispatchmessagelist', {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      // Authorization: KEY,
    },
  })

  return response
}

export const getContractSearch = async () => {
  const response = await axios('https://es.opwforge.com/pcrs_glue/_search', {
    auth: {
      username: 'kustomer',
      password: 'TA3#2r88k52u',
    },
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      query: {
        term: {
          'Contract Number.keyword': 'ON11397115',
        },
      },
    },
  })

  return response
}
