import React, { Component } from 'react'
import { Accordion, ListGroup } from 'react-bootstrap'
import './Appointment.css'
import './Accordion.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus, faCalendarMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

class ForceAppointment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataObject: {},
    }
  }

  displayAppointments(appointments) {
    if (appointments && appointments.length) {
      return appointments.map((appointment) => {
        return (
          <ListGroup.Item
            style={{
              color: 'black',
              'background-color': 'white',
              border: '1px solid black',
              padding: '2px',
              'border-radius': '5px',
              'font-size': '0.5rem',
            }}
            variant={this.getVariant(appointment)}
            onClick={this.getOnClick(appointment)}
            className={this.getClassName(appointment)}
          >
            {appointment}
          </ListGroup.Item>
        )
      })
    } else {
      return <></>
    }
  }

  getClassName() {
    const { selectedAppointment, appointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'text-center fw-bold selectedAppointment'
    } else {
      return appointment === null ? 'text-center' : 'text-center availableAppointment'
    }
  }

  getVariant(appointment) {
    const { selectedAppointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'warning'
    } else {
      return appointment === null ? 'light' : 'success'
    }
  }

  getAppointmentAvailability(appointment) {
    const { selectedAppointment } = this.props

    if (selectedAppointment === appointment && selectedAppointment != null) {
      return 'Selected'
    } else {
      return appointment === null ? 'Unavailable' : 'Available'
    }
  }

  getOnClick(appointment) {
    const { selectedAppointment, showPopupAppointmentDetail } = this.props

    if (appointment != null && appointment !== selectedAppointment) {
      return () => showPopupAppointmentDetail(appointment, this.props.appointment, this.props.date)
    } else if (appointment != null && appointment === selectedAppointment) {
      return () => showPopupAppointmentDetail(null)
    } else {
      return null
    }
  }

  render() {
    return (
      <Accordion.Item
        eventKey={this.props.index}
        style={{
          'font-size': '0.5rem',
          'margin-right': 'auto',
          'margin-left': 'auto',
          'margin-top': '0px',
          'margin-bottom': '10px',
          color: 'black',
          'background-color': 'white',
          border: '1px solid gray',
          padding: '5px 5px 5px',
          'border-radius': '1px',
          width: '120px',
        }}
      >
        <Accordion.Header>
          <div
            style={{
              'font-size': '0.6rem',
              'text-align': 'left',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
              width: '100px',
              float: 'left',
              'line-height': '1',
            }}
          >
            {' '}
            {this.props.type === 'firm' ? (
              <FontAwesomeIcon
                style={{
                  color: '#0dcaf0',
                  'font-size': '0.7rem',
                  'margin-right': '5px',
                  float: 'left',
                }}
                icon={faCalendarPlus}
              />
            ) : (
              <FontAwesomeIcon
                style={{ 'font-size': '0.7rem', 'margin-right': '5px', float: 'left' }}
                icon={faCalendarMinus}
              />
            )}
            {this.props.servicer}
          </div>
          <FontAwesomeIcon
            style={{ color: '#0dcaf0', 'font-size': '0.7rem', 'margin-left': '0px' }}
            icon={faPlusCircle}
          />
        </Accordion.Header>
        <Accordion.Body style={{ padding: '10px 0 0 0', 'text-align': 'left' }}>
          <ListGroup variant='flush'>
            <ListGroup.Item>Servicer Id: {this.props.appointment.servicerAccount}</ListGroup.Item>
            <ListGroup.Item>
              Dispatch Method: {this.props.appointment.dispatchMethodPhone}
            </ListGroup.Item>
            <ListGroup.Item>{this.props.appointment.dispatchMethodEmail}</ListGroup.Item>
            <ListGroup.Item>
              Dispatch Address: {this.props.appointment.dispatchAddress}
            </ListGroup.Item>
          </ListGroup>
          <p style={{ 'margin-bottom': '10px' }}>Availability:</p>
          <ListGroup variant='flush'>{this.displayAppointments(this.props.appointments)}</ListGroup>
          {this.props.type === 'firm' && (
            <p style={{ 'font-size': '0.4rem', 'font-weight': 'bold', 'margin-bottom': '5px' }}>
              Notes:
            </p>
          )}
          {this.props.type === 'firm' && (
            <p style={{ 'font-size': '0.4rem', 'margin-bottom': '5px' }}>
              Call us at 1-800-665-2127. We’ll do our best to reschedule a time convenient for you
              or have your local service unit contact you for rescheduling.
            </p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    )
  }
}

export default ForceAppointment
