import { useState, useEffect } from 'react'
import RescheduleDialog from './RescheduleDialog'
import RescheduleAppointmentDialog from './RescheduleAppointmentDialog'
import ConfirmationRescheduleDialog from './ConfirmationRescheduleDialog'
import ReassignDialog from './ReassignDialog'
import ConfirmationDialog from './ConfirmationDialog'
import FinalConfirmationDialog from './FinalConfirmationDialog'
import CancelServiceOrderDialog from './CancelServiceOrder'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

export default function ExistingAppointmentDialog(props) {
  // TODO: Have Manoj team look and resolve if not used
  // eslint-disable-next-line no-unused-vars
  const {
    showExistingAppointmentDialog,
    rescheduleDialogParent,
    setRescheduleDialogParent,
    closeRescheduleDialogParent,
    existingAppointment,
    reassignDialogParent,
    closeReassignDialogParent,
    cancelDialogParent,
    closeCancelDialogParent,
    getServicerOrderParent,
    contractNumber,
  } = props

  const today = moment()
  const [servicerData, setServicerData] = useState()
  const [uiservicerData, setUiservicerData] = useState()
  const [reqBodyGetAvailability, setReqBodyGetAvailability] = useState()

  //re-assign
  const [serviceOrder, setServiceOrder] = useState({})
  const [reassignOpen, setReassignOpen] = useState(false)
  const [reassignData, setReassignData] = useState()
  const [bookingContent, setBookingContent] = useState([])
  const [handleConfirmDialog, setHandleConfirmDialog] = useState(false)
  const [finalConfirmDialog, setFinalConfirmDialog] = useState(false)
  const [appointmentDate, setAppointmentDate] = useState('')
  const [appointmentDay, setAppointmentDay] = useState('')
  const [reassignReason, setReassignReason] = useState()
  const [reassignIndex, setReassignIndex] = useState()
  const [totalReassignData, setTotalReassignData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [activefirmAvailabilitydialog, setActivefirmAvailabilitydialog] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [firmAvilabilityDialog, setFirmAvilabilityDialog] = useState(false)
  const [dropDownAvailability, setDropDownAvailability] = useState(false)
  const [consumerDetails, setConsumerDetails] = useState({})
  const [productDetails, setProductDetails] = useState({})
  const [reassignNote, setReassignNote] = useState('')
  const [failureId, setFailureId] = useState('')
  const [reassignDataObj, setReassignDataObj] = useState(null)

  const handleClose = () => {
    setReassignOpen(false)
    closeReassignDialogParent()
  }

  const getServiceOrderAfterBooking = async () => {}

  //cancel
  const [cancelReasons, setCancelReasons] = useState([])
  const [openCancelService, setOpenCancelService] = useState(false)

  const getCancelReasons = async () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL
        }getReasonList?providerType=${'BOTH'}&reasonType=CANCEL`,
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setCancelReasons(response?.data?.items)
        }
      })
      .catch((err) => {
        toast.error(err.message || 'Something Went Wrong')
      })
  }

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData) {
      getCancelReasons()
    }
  }, [showExistingAppointmentDialog, servicerData])

  const handleToggleCancelService = () => {
    setOpenCancelService((prev) => !prev)
    closeCancelDialogParent()
  }

  //reschedule
  const [rescheduleDialog, setRescheduleDialog] = useState(false)
  const [rescheduleAppointmentDialog, setRescheduleAppoitnmentDialog] = useState(false)
  const [rescheduleReasonList, setRescheduleReasonList] = useState([])
  const [rescheduleDialogValue, setRescheduleDialogValue] = useState(null)
  const [rescheduleAppointmentDialogData, setRescheduleAppointmentDialogData] = useState(null)
  const [rescheduleAppointmentAEObject, setRescheduleAppointmentAEObject] = useState(null)
  const [serviceOrderListObject, setServiceOrderListObject] = useState(null)
  const [finalBookingTimeSlot, setFinalBookingTimeSlot] = useState('')
  const [rescheduleResponse, setRescheduleResponse] = useState()
  const [openTimeSlotDialog, setOpenTimeSlotDialog] = useState(false)
  const [finalConfirmationReschedule, setFinalConfirmationReschedule] = useState(false)

  const getRescheduleReasonList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=${
        servicerData?.providerType === 'A&E' ? 'A%26E' : 'OPW'
      }&reasonType=RESCHEDULE`,
    )
    if (response && response?.data?.isSuccess) {
      setRescheduleReasonList(response?.data?.items)
      // const item = response?.data?.items.filter((item) => item?.reasonCodeMasterId === 1)
      // setRescheduleDialogValue(item[0].reasonName)
    }
  }

  const handleRescheduleDialog = () => {
    setRescheduleDialog(false)
    closeRescheduleDialogParent()
    setRescheduleDialogValue(undefined)
  }

  const handleOpenRescheduleAppointMentDialog = async () => {
    if (serviceOrderListObject?.serviceOrder?.providerType === 'A&E') {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/provider/availability`,
          rescheduleAppointmentAEObject,
        )
        if (response && response.data.isSuccess) {
          const data = response.data.data
          let list = []
          const dateArray = []
          for (let i = 0; i < 14; i++) {
            const date = today.clone().add(i, 'days')
            const todayDate = date.format('YYYY-MM-DD')
            const day = date.format('dddd')
            // list = data.filter((item) => moment(item.appointmentInfo.timeSlotData.availableDate).format('YYYY-MM-DD').isSame(date.format('YYYY-MM-DD'), "date"))
            // list = data.filter((item) => item.appointmentInfo.timeSlotData.availableDate === todayDate)
            list = data.filter((item) => item.date === todayDate)
            dateArray.push({
              dateObject: date,
              date: date.format('YYYY-MM-DD'),
              timeBand: list[0]?.availability,
              day: day,
            })
          }
          setRescheduleAppointmentDialogData(dateArray)
          setRescheduleDialog(false)
          setRescheduleAppoitnmentDialog(true)
        }
      } catch (error) {
        toast.error(error.message || 'Something Went Wrong')
      }
    } else {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
      const offsetInMinutes = new Date().getTimezoneOffset()
      const reqBody = {
        servicerAccountId: servicerData?.servicerAccountId,
        customer: {
          zipCode: rescheduleAppointmentAEObject?.customer?.zipCode,
        },
        product: {
          brandCode: rescheduleAppointmentAEObject?.product?.brandCode,
          tierCode: rescheduleAppointmentAEObject.product?.tierCode,
          productCode: rescheduleAppointmentAEObject.product?.tierCode,
        },
        service: {
          beginSearchDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          numberOfDays: 7,
        },
        warranty: {
          contractNumber: contractNumber,
        },
        type: 'forced',
        timeZone: timeZone,
        timeZoneMinuteDifference: offsetInMinutes,
      }
      setReqBodyGetAvailability(reqBody)
      const response = await axios.post(
        `${process.env.REACT_APP_API_SDPROVIDER_URL}v2/getWeeklyAvailabilityByServicer`,
        reqBody,
      )

      if (response && response.data.isSuccess) {
        if (response.data.data && Object.keys(response.data.data).length) {
          const data = response.data.data
          const dateArray = []
          const orderMapping = { morning: 0, evening: 2, afternoon: 1 }
          function customSort(a, b) {
            return orderMapping[a] - orderMapping[b]
          }
          for (let i = 0; i < 14; i++) {
            const date = today.clone().add(i, 'days')
            const day = date.format('dddd')
            let timeBand = []
            if (data[day]) {
              timeBand = data[day][0].timeBand.sort(customSort)
            }

            dateArray.push({
              dateObject: date,
              date: date.format('YYYY-MM-DD'),
              day: day,
              timeBand: timeBand,
              sameDay:
                timeBand.length > 0
                  ? data[day][0].sameDayCutOff + '' + data[day][0].sameDayAmPm
                  : '',
              nextDay:
                timeBand.length > 0
                  ? data[day][0].nextDayCutOff + '' + data[day][0].nextDayAmPm
                  : '',
              servicerId: timeBand.length > 0 ? data[day][0].servicerAccount : '',
              servicerName: timeBand.length > 0 ? data[day][0].serviceProvider : '',
            })
          }
          setRescheduleAppointmentDialogData(dateArray)
          setRescheduleDialog(false)
          setRescheduleAppoitnmentDialog(true)
        } else {
          toast.error(response.data.message)
          setRescheduleDialog(false)
        }
      }
    }
  }

  const handleCloseRescheduleAppointmentDialog = () => {
    setRescheduleAppoitnmentDialog(false)
    getRescheduleReasonList()
    setRescheduleDialogValue(undefined)
    closeRescheduleDialogParent()
  }

  const handleRescheduleAppointment = async (
    selectedData,
    selectedSlot,
    bookingNote,
    setBookingNote,
  ) => {
    const customer = rescheduleAppointmentAEObject?.customer
    const rescheduleCode = rescheduleReasonList.filter(
      (item) => item.reasonName === rescheduleDialogValue,
    )
    const obj = {
      customer: customer,
      product: {
        sequenceNumber: serviceOrderListObject?.product?.productSequenceNumber,
        productPurchaseDate: moment(serviceOrderListObject?.product?.purchaseDate).format(
          'YYYY-MM-DD',
        ),
        productSubCategory: serviceOrderListObject?.product?.productName,
      },
      warranty: {
        warrantyType: serviceOrderListObject?.serviceOrder?.warrantyType,
        coverageBeginDate: moment(serviceOrderListObject?.contract?.effectiveDate).format(
          'YYYY-MM-DD',
        ),
        coverageEndDate: moment(serviceOrderListObject?.contract?.expirationDate).format(
          'YYYY-MM-DD',
        ),
        serviceAvengerContractNumber: serviceOrderListObject?.serviceOrder?.serviceContractNumber,
        externalContractNumber: serviceOrderListObject?.serviceOrder?.externalContractNumber || '',
      },
      service: {
        serviceOrderNumber: serviceOrderListObject?.serviceOrder?.serviceOrderId,
        serviceCallDate: today.format('YYYY-MM-DD'),
        failureDate: moment(serviceOrderListObject?.serviceOrder?.failureDate).format('YYYY-MM-DD'),
        serviceRequestType: serviceOrderListObject?.serviceOrder?.serviceType,
        serviceElevation: serviceOrderListObject?.serviceOrder?.serviceRequestElevation,
        serviceSite: serviceOrderListObject?.serviceOrder?.serviceSite,
        failureType: serviceOrderListObject?.serviceOrder?.failureType,
        functionalityStatus: serviceOrderListObject?.serviceOrder?.functionalityStatus,
        symptomCode: serviceOrderListObject?.serviceOrder?.symptomCode,
        failureNote: serviceOrderListObject?.serviceOrder?.failureNote,
        bookingType: serviceOrderListObject?.serviceOrder?.bookingType,
        providerType: serviceOrderListObject?.serviceOrder?.providerType,
        rescheduleReasonCode: rescheduleCode[0]?.reasonCode,
        isReschedule: true,
        externalAppointmentNumber: serviceOrderListObject?.serviceOrder?.externalAppointmentNumber,
        bookingNote: bookingNote,
      },
      appointment: {
        groupID: serviceOrderListObject?.serviceOrder?.groupID, // to be checked
        offerKey: serviceOrderListObject?.serviceOrder?.offerKey, // to be checked
        appointmentDate:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? selectedData?.date
            : selectedData?.date, // new date
        appointmentTimeSlot:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? selectedSlot
            : selectedSlot, // new date
        consumerRequestDate: moment(
          serviceOrderListObject?.serviceOrder?.consumerRequestDate,
        ).format('YYYY-MM-DD'), // new date
        consumerRequestTime: serviceOrderListObject?.serviceOrder?.consumerRequestWindow, // new date
        specialInstructions: serviceOrderListObject?.specialInstructions,
      },
      servicer: {
        servicerID:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? serviceOrderListObject?.serviceOrder?.servicerID
            : serviceOrderListObject?.serviceOrder?.servicerID ||
              serviceOrderListObject?.serviceOrder?.servicerId ||
              '',
        servicerAccount: serviceOrderListObject?.serviceOrder?.servicerAccountId || '',
        servicerName: uiservicerData?.account?.businessName,
        servicerPhone: uiservicerData?.dispatchMethod?.primaryPhone || '',
        servicerWebsite: uiservicerData?.account?.businessWebsite || '',
        bookingType: 'Reschedule',
        providerType: servicerData?.providerType,
        serviceUnitNumber:
          (selectedData?.timeBand && selectedData?.timeBand[0]?.serviceUnitNumber) || '',
      },
      parts: {
        partDistibutorID: '',
      },
      payment: {
        deductible: 0,
        poNumber: '',
        poAmount: 0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: '',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
    }
    const toastId = toast.loading('Rescheduling Appointment', { autoClose: false })
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/book/appointment`,
        obj,
      )
      if (response && response.data.isSuccess) {
        toast.dismiss(toastId)
        setOpenTimeSlotDialog(false)
        setFinalConfirmationReschedule(true)
        setRescheduleResponse(response.data.data)
        setFinalBookingTimeSlot(obj?.appointment)
      } else {
        toast.dismiss(toastId)
        toast.error(response.error.message || 'Something Went Wrong')
      }
    } catch (error) {
      toast.dismiss(toastId)
    }
    setBookingNote('')
  }

  const handleFinalConfirmationReschedule = () => {
    setRescheduleResponse(undefined)
    setRescheduleDialogValue(undefined)
    setRescheduleAppoitnmentDialog(false)
    setFinalConfirmationReschedule(false)
    closeRescheduleDialogParent()
    getServicerOrderParent()
    // getRescheduleReasonList()
  }

  const openTimeSlot = () => {
    setOpenTimeSlotDialog(true)
  }
  const closeTimeSlot = () => {
    setOpenTimeSlotDialog(false)
  }
  const getServicerData = async () => {
    if (existingAppointment?.industryId && servicerData) {
      let response = await axios.get(
        `${process.env.REACT_APP_API_SDPROVIDER_URL}getServicerDetail?servicerAccountId=${servicerData?.servicerAccountId}&industryId=${existingAppointment.industryId}`,
      )
      if (response.data.isSuccess) {
        setUiservicerData(response.data.data)
      } else {
        // setShowServicerProfile(false)
      }
    }
  }
  const getServiceOrder = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_DEVELOPMENT_URL}getOrderDetails?serviceOrderId=${
          servicerData?.serviceOrderNumber || servicerData?.serviceOrderId
        }`,
      )
      .then((response) => {
        // Handle the successful response
        if (response.data.isSuccess) {
          const obj = {
            customer: {
              address1: response.data.data.consumer.address1,
              address2: response.data.data.consumer.address2,
              businessName: null,
              cellPhone: response.data.data.consumer.phone,
              city: response.data.data.consumer.city,
              countryCode: response.data.data.consumer.countryCode,
              customerId: response.data.data.serviceOrder.crmKey,
              crmKey: response.data.data.serviceOrder.crmKey || 1,
              email: response.data.data.consumer.email,
              firstName: response.data.data.consumer.firstName,
              lastName: response.data.data.consumer.lastName,
              otherPhone: response.data.data.consumer.secondaryPhone,
              stateProvince: response.data.data.consumer.state,
              zipCode: response.data.data.consumer.postalCode,
            },
            product: {
              brandCode: response.data.data.product.brandName,
              installDate: response.data.data.product.installDate,
              modelNumber: response.data.data.product.modelNumber,
              productCode: response.data.data.product.productCode,
              serialNumber: response.data.data.product.serialNumber,
              tierCode: response.data.data.product.productCode,
              totalLiability: response.data.data.product.totalLiability, // to be
              productSequenceNumber: response.data.data.product.productSequenceNumber,
            },
            service: {
              beginSearchDate: today.format('YYYY-MM-DD'),
              numberOfDays: 14,
              procedureCode: response.data.data.serviceOrder?.procedureCode, //"Field Service", // to be added from widget
              serviceElevation: response.data.data.serviceOrder.serviceRequestElevation,
              serviceNotice: response.data.data.serviceOrder.notice, // to be added from widget
              serviceType: response.data.data.serviceOrder.serviceType,
              bookingType: 'firm',
              providerType: 'A&E',
              dealerID: response.data.data.contract.dealerStoreNumber,
              serviceOrderNumber: response.data.data.serviceOrder.serviceOrderId,
            },
            warranty: {
              contractNumber: response.data.data.contract.contractNumber,
              coverageBeginDate: response.data.data.contract.effectiveDate,
              coverageEndDate: response.data.data.contract.expirationDate,
              warrantyType: response.data.data.serviceOrder.warrantyType,
            },
            type: 'reschedule',
          }

          const reassignobj = {
            customer: {
              address1: response.data.data.consumer.address1,
              address2: response.data.data.consumer.address2,
              businessName: null,
              cellPhone: response.data.data.consumer.phone,
              city: response.data.data.consumer.city,
              countryCode: response.data.data.consumer.countryCode,
              customerId: response.data.data.serviceOrder.crmKey,
              crmKey: response.data.data.serviceOrder.crmKey || 1,
              email: response.data.data.consumer.email,
              firstName: response.data.data.consumer.firstName,
              lastName: response.data.data.consumer.lastName,
              otherPhone: response.data.data.consumer.secondaryPhone,
              stateProvince: response.data.data.consumer.state,
              zipCode: response.data.data.consumer.postalCode,
            },
            product: {
              brandCode: response.data.data.product.brandName,
              installDate: response.data.data.product.installDate,
              modelNumber: response.data.data.product.modelNumber,
              productCode: response.data.data.product.productCode,
              serialNumber: response.data.data.product.serialNumber,
              tierCode: response.data.data.product.productCode,
              totalLiability: response.data.data.product.totalLiability, // to be
              productSequenceNumber: response.data.data.product.productSequenceNumber,
            },
            service: {
              beginSearchDate: today.format('YYYY-MM-DD'),
              numberOfDays: 14,
              procedureCode: response.data.data.serviceOrder?.procedureCode, //"Field Service", // to be added from widget
              serviceElevation: response.data.data.serviceOrder.serviceRequestElevation,
              serviceNotice: response.data.data.serviceOrder.notice, // to be added from widget
              serviceType: response.data.data.serviceOrder.serviceType,
              bookingType: response.data.data.serviceOrder.bookingType,
              providerType: response.data.data.serviceOrder.providerType,
              dealerID: response.data.data.contract.dealerStoreNumber,
              serviceOrderNumber: response.data.data.serviceOrder.serviceOrderId,
              serviceLocationId: response.data.data.serviceOrder?.serviceLocationId,
              serviceTypeId: response.data.data.serviceOrder?.serviceTypeId,
            },
            warranty: {
              contractNumber: response.data.data.contract.contractNumber,
              coverageBeginDate: response.data.data.contract.effectiveDate,
              coverageEndDate: response.data.data.contract.expirationDate,
              warrantyType: response.data.data.serviceOrder.warrantyType,
              warrantyTypeId: response.data.data.serviceOrder.warrantyTypeId,
            },
            type: 'reassign',
            countryId: uiservicerData?.account?.countryId,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneMinuteDifference: new Date().getTimezoneOffset(),
          }

          setRescheduleAppointmentAEObject(obj)
          setReassignDataObj(reassignobj)
          setServiceOrderListObject(response.data.data)
          setServiceOrder(response?.data?.data?.serviceOrder)
          setConsumerDetails(response?.data?.data?.consumer)
          setProductDetails(response?.data?.data?.product)
        }
      })
      .catch((err) => {
        toast.error(err.message || 'Something Went Wrong')
      })
  }

  useEffect(() => {
    if (showExistingAppointmentDialog && existingAppointment) {
      setServicerData(existingAppointment)
    }
  }, [showExistingAppointmentDialog, existingAppointment])

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData) {
      getServicerData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, servicerData])

  useEffect(() => {
    if (showExistingAppointmentDialog && uiservicerData) {
      getServiceOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, uiservicerData])

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData && rescheduleDialogParent) {
      setRescheduleDialog(true)
      getRescheduleReasonList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, servicerData, rescheduleDialogParent])

  useEffect(() => {
    if (showExistingAppointmentDialog && reassignDialogParent && reassignDataObj) {
      const getReassignData = async () => {
        const toastId = toast.loading('Loading...', { autoClose: false })
        await axios
          .post(
            `${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/provider/availability`,
            reassignDataObj,
          )
          .then((response) => {
            if (response?.data?.isSuccess) {
              toast.dismiss(toastId)
              const newData = JSON.parse(JSON.stringify(response?.data?.data))
              const filteredArray = newData.map((item) => ({
                ...item,
                availability: item.availability?.filter(
                  (subItem) => subItem.servicerAccount !== servicerData?.servicerAccountId,
                ),
              }))
              setTotalReassignData(filteredArray)
              const firstSeven = filteredArray.slice(0, 7)
              setReassignData(firstSeven)
              setReassignOpen(true)
            }
          })
          .catch((err) => {
            toast.dismiss(toastId)
            toast.error(err.message || 'Something Went Wrong')
          })
      }
      getReassignData()
    }
  }, [
    showExistingAppointmentDialog,
    reassignDialogParent,
    reassignDataObj,
    servicerData?.servicerAccountId,
  ])

  useEffect(() => {
    if (showExistingAppointmentDialog && cancelDialogParent && serviceOrderListObject) {
      setOpenCancelService(true)
    }
  }, [showExistingAppointmentDialog, cancelDialogParent, serviceOrderListObject])

  return (
    <>
      {rescheduleDialog && (
        <RescheduleDialog
          open={rescheduleDialog}
          rescheduleReasonList={rescheduleReasonList}
          handleClose={handleRescheduleDialog}
          value={rescheduleDialogValue}
          handleChange={setRescheduleDialogValue}
          openRescheduleAppointmentDialog={handleOpenRescheduleAppointMentDialog}
        />
      )}
      {rescheduleAppointmentDialog && (
        <RescheduleAppointmentDialog
          open={rescheduleAppointmentDialog}
          rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
          handleClose={handleCloseRescheduleAppointmentDialog}
          servicer={servicerData}
          id={servicerData?.servicerAccountId}
          bookReschedule={(selectedData, selectedSlot, bookingNote, setBookingNote) =>
            handleRescheduleAppointment(selectedData, selectedSlot, bookingNote, setBookingNote)
          }
          openTimeSlot={openTimeSlot}
          closeTimeSlot={closeTimeSlot}
          openTimeSlotDialog={openTimeSlotDialog}
          uiservicerData={uiservicerData}
        />
      )}
      {finalConfirmationReschedule && (
        <ConfirmationRescheduleDialog
          open={finalConfirmationReschedule}
          handleClose={handleFinalConfirmationReschedule}
          data={rescheduleResponse}
          servicer={uiservicerData}
          consumer={serviceOrderListObject}
          finalBookingTimeSlot={finalBookingTimeSlot}
        />
      )}
      {servicerData && servicerData?.bookingType !== 'firm' && (
        <>
          {reassignOpen && (
            <ReassignDialog
              reqBodyGetAvailability={reqBodyGetAvailability}
              rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
              totalReassignData={totalReassignData}
              uiservicerData={uiservicerData}
              setReassignIndex={setReassignIndex}
              setAppointmentDay={setAppointmentDay}
              setAppointmentDate={setAppointmentDate}
              serviceOrder={serviceOrder}
              setFinalBookingTimeSlot={setFinalBookingTimeSlot}
              servicerData={servicerData}
              setActivefirmAvailabilitydialog={setActivefirmAvailabilitydialog}
              setBookingContent={setBookingContent}
              setReassignData={setReassignData}
              data={reassignData}
              setFirmAvilabilityDialog={setFirmAvilabilityDialog}
              handleClose={handleClose}
              reassignOpen={reassignOpen}
              setHandleConfirmDialog={setHandleConfirmDialog}
            />
          )}
          {handleConfirmDialog && (
            <ConfirmationDialog
              totalReassignData={totalReassignData}
              rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
              reassignIndex={reassignIndex}
              reassignReason={reassignReason}
              setReassignReason={setReassignReason}
              dropDownAvailability={dropDownAvailability}
              setDropDownAvailability={setDropDownAvailability}
              setFinalBookingTimeSlot={setFinalBookingTimeSlot}
              getServiceOrderAfterBooking={getServiceOrderAfterBooking}
              appointmentDate={appointmentDate}
              setFailureId={setFailureId}
              productDetails={productDetails}
              reassignNote={reassignNote}
              finalBookingTimeSlot={finalBookingTimeSlot}
              serviceOrder={serviceOrder}
              setReassignNote={setReassignNote}
              servicerData={servicerData}
              setFinalConfirmDialog={setFinalConfirmDialog}
              bookingContent={bookingContent}
              data={reassignData}
              handleConfirmDialog={handleConfirmDialog}
              setHandleConfirmDialog={setHandleConfirmDialog}
              setReassignOpen={setReassignOpen}
              consumerDetails={consumerDetails}
              appointmentDay={appointmentDay}
            />
          )}
          {finalConfirmDialog && (
            <FinalConfirmationDialog
              failureId={failureId}
              consumerDetails={consumerDetails}
              productDetails={productDetails}
              setReassignOpen={setReassignOpen}
              setReassignNote={setReassignNote}
              finalBookingTimeSlot={finalBookingTimeSlot}
              serviceOrder={serviceOrder}
              servicerData={servicerData}
              finalConfirmDialog={finalConfirmDialog}
              setFinalConfirmDialog={setFinalConfirmDialog}
              bookingContent={bookingContent}
              data={reassignData}
              handleConfirmDialog={handleConfirmDialog}
              setHandleConfirmDialog={setHandleConfirmDialog}
              appointmentDay={appointmentDay}
              appointmentDate={appointmentDate}
              closeReassignDialogParent={closeReassignDialogParent}
              getServicerOrderParent={getServicerOrderParent}
            />
          )}
        </>
      )}
      {openCancelService && (
        <CancelServiceOrderDialog
          servicerData={servicerData}
          cancelReasons={cancelReasons}
          openCancelService={openCancelService}
          handleToggleCancelService={handleToggleCancelService}
          serviceOrderListObject={serviceOrderListObject}
          uiservicerData={uiservicerData}
          getServicerOrderParent={getServicerOrderParent}
        />
      )}
    </>
  )
}
