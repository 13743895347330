import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import Icon from "../../img/delivery-box (1) 1.svg";
import { toast } from "react-hot-toast";
import axios from "axios";
// import { StyledScrollbar } from '../../components/StyledComponent/StyledComponent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledScrollbar = {
  "&::-webkit-scrollbar": {
    width: "5px", // Set the width of the scrollbar
    borderRadius: "5px" /* Add rounded corners */,
    backgroundColor: "#f0f0f0" /* Set the background color of the track */,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888", // Set the color of the scrollbar thumb
    borderRadius: "5px", // Set the border radius of the thumb
  },
  "&::-webkit-scrollbar-horizontal": {
    // height: "5px",
    // width: "8px" // Set the height of the scrollbar
  },
  "&::-webkit-scrollbar-vertical": {
    // width: "8px", // Set the width of the scrollbar
  },
};


export default function ConfirmationDialog({ getServiceOrderStatus, reassignIndex, finalBookingTimeSlot, reassignReason, setReassignReason, dropDownAvailability, setDropDownAvailability, setFinalBookingTimeSlot, appointmentDay, getServiceOrderAfterBooking, appointmentDate, setFailureId, reassignNote, setReassignOpen, setOpenReassignTable, serviceOrder, data, setReassignTable, consumerDetails, productDetails, setReassignNote, bookingContent, handleConfirmDialog, setHandleConfirmDialog, setFinalConfirmDialog, servicerData, getServiceOrderLog }) {


  const [sortedTimeBand, setSortedTimeBand] = React.useState([])

  React.useEffect(() => {
    if (bookingContent && Array.isArray(bookingContent?.timeSlot)) {
      let arr = bookingContent?.timeSlot
      let newArr = arr?.map((item) => item.slotName)
      newArr.sort((a, b) => {
        const order = { 'morning': 1, 'afternoon': 2, 'evening': 3 };
        return order[a] - order[b];
      });
      setSortedTimeBand(newArr)

    }
  }, [bookingContent])

  const [reassignList, setReassignList] = React.useState([])

  const getReassignList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=BOTH&reasonType=REASSIGN`
    );
    if (response.data.isSuccess) {
      setReassignList(response.data.items);
    }
  };

  React.useEffect(() => {
    getReassignList();
    setFinalBookingTimeSlot("");
    setReassignReason("");
  }, []);

  const CardsContetnStyle = {
    fontSize: "12px",
  };

  const handleClose = () => {
    setFinalBookingTimeSlot("");
    setDropDownAvailability(false);
    setHandleConfirmDialog(false);
  };

  const handleOpenFinalConfirm = async () => {
    let newcOverageBegindate =
      serviceOrder && serviceOrder?.coverageStartDate
        ? new Date(serviceOrder?.coverageStartDate).toISOString().split("T")[0]
        : "";
    let newcoverageEndDate =
      serviceOrder && serviceOrder?.coverageEndDate
        ? new Date(serviceOrder?.coverageEndDate).toISOString().split("T")[0]
        : "";
    var newserviceCallDate =
      serviceOrder && new Date().toISOString().slice(0, 10);

    let newfailureDate =
      serviceOrder &&
      new Date(serviceOrder?.failureDate).toISOString().split("T")[0];
    let newappointmentDate = appointmentDate
      ? appointmentDate.split("/").reverse().join("-")
      : "";
    let consumerRequestDate =
      serviceOrder && serviceOrder?.consumerRequestDate
        ? new Date(serviceOrder?.consumerRequestDate)
          .toISOString()
          .split("T")[0]
        : "";

    let newinstallDate = new Date(productDetails?.installDate)
      .toISOString()
      .split("T")[0];

    setHandleConfirmDialog(false);
    setFinalConfirmDialog(false);
    setReassignOpen(false);

    const resobj = {
      customer: {
        firstName: consumerDetails?.firstName,
        lastName: consumerDetails?.lastName,
        address1: consumerDetails?.address1,
        address2: consumerDetails?.address2,
        businessName: consumerDetails?.businessName,
        city: consumerDetails?.city,
        stateProvince: consumerDetails?.state,
        zipCode: consumerDetails?.postalCode,
        cellPhone: consumerDetails?.phone,
        otherPhone: consumerDetails?.secondaryPhone,
        email: consumerDetails?.email,
        countryCode: consumerDetails?.countryCode,
        crmKey: serviceOrder?.crmKey,
      },
      product: {
        sequenceNumber: productDetails?.productSequenceNumber,
        brandCode: productDetails?.brandName,
        catCode: productDetails?.industryCode,
        productCode: productDetails?.productCode,
        appointmentBrand: productDetails?.industryName,
        appointmentProduct: productDetails?.appointmentProduct,
        modelNumber: productDetails?.modelNumber,
        serialNumber: productDetails?.serialNumber,
        installDate: newinstallDate,
        tierCode: serviceOrder?.tierCode,
      },
      warranty: {
        warrantyType: serviceOrder?.warrantyType,
        coverageBeginDate: newcOverageBegindate,
        coverageEndDate: newcoverageEndDate,
        serviceAvengerContractNumber: serviceOrder?.serviceContractNumber,
        externalContractNumber: serviceOrder?.externalContractNumber,
        warrantyTypeId: serviceOrder?.warrantyTypeId,
      },
      service: {
        serviceOrderNumber: serviceOrder?.serviceOrderId,
        externalAppointmentNumber: "",
        serviceCallDate: newserviceCallDate,
        failureDate: newfailureDate,
        serviceRequestType: serviceOrder?.serviceType,
        serviceElevation: serviceOrder?.serviceRequestElevation,
        serviceSite: serviceOrder?.serviceSite,
        failureType: serviceOrder?.failureType,
        functionalityStatus: serviceOrder?.functionalityStatus,
        symptomCode: serviceOrder?.symptomCode,
        failureNote: serviceOrder?.failureNote,
        bookingType: bookingContent?.svcrType == `WEB` ? "soft" : "firm", //serviceOrder.bookingType,
        relatedTo: serviceOrder?.serviceOrderId,
        providerType: bookingContent?.svcrType == `WEB` ? "OPW" : "A&E", //serviceOrder?.providerType,
        groupID: serviceOrder?.groupID,
        bookingNote: reassignNote,
        reassignReasonCode: reassignReason,
        dealerId: serviceOrder?.dealerID,
        procedureCode: serviceOrder?.procedureCode,
        serviceLocationId: serviceOrder?.serviceLocationId,
        serviceTypeId: serviceOrder?.serviceTypeId,
        dealerName: serviceOrder?.dealerName,

      },

      appointment: {
        offerKey: bookingContent?.offerID,
        appointmentDate: newappointmentDate,
        appointmentTimeSlot: finalBookingTimeSlot,
        consumerRequestDate: consumerRequestDate,
        consumerRequestTime: serviceOrder?.appointmentTimeSlot,
        specialInstructions: serviceOrder?.specialInstructions,
        providerId: bookingContent?.timeSlot[0]?.providerId,
        firstAvailableDate: bookingContent?.firstAvailableDate,
        capacityArea: bookingContent?.capacityArea,
        workAreaCode: bookingContent?.workAreaCode

      },
      servicer: {
        specialInstructions: serviceOrder?.specialInstructions,
        servicerID: bookingContent?.servicerId,
        servicerAccount: bookingContent?.servicerAccount,
        servicerName: bookingContent?.serviceProvider,
        servicerPhone: bookingContent?.svcrPhone,
        servicerWebsite: "",
        serviceUnitNumber: bookingContent?.serviceUnitNumber,
        serviceNotice:serviceOrder?.notice
      },
      parts: {
        partDistibutorID: "",
        partDistributorName: "",
        partNumber: "",
        partDescription: "",
        partQuantity: 0,
        partPrice: 0,
        shipperCompany: "",
        shipperTracking: "",
      },
      payment: {
        deductible: 0,
        poNumber: "",
        poAmount: 0,
        authorizationNumber: "",
        billTo: "",
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: "",
        status2: "",
        status3: "",
        status4: "",
        status5: "",
        status6: "",
        status7: "",
        status8: "",
        status9: "",
        status10: "",
      },
      visitFrequency: "NO",
    };

    const toastId = toast.loading("Reassign is in Progress", {
      autoClose: false,
    });

    await axios
      .post(
        `${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/reassign/appointment`,
        resobj
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success("Reassigned Successfully");
          // getServiceOrderStatus()
          // setReassignTable(response?.data?.data.subOrderList)
          setFailureId(
            response?.data?.data?.serviceOrderNumber?.serviceOrderNumber
          );
          // getServiceOrderAfterBooking()
          toast.dismiss(toastId);
          setFinalConfirmDialog(true);
          // getServiceOrderLog()
        }
        if (!response.data.isSuccess) {
          toast.error(response.data.data.message || "Something Went Wrong");
          toast.dismiss(toastId);
        }
      })
      .catch((err) => {
        toast.error(err.message || "Something Went Wrong");
      });

    setHandleConfirmDialog(false);
    // setFinalConfirmDialog(true)
  };

  const handleAvailabilityRadio = (event) => {
    setDropDownAvailability(true);
    setFinalBookingTimeSlot(event.target.value);
  };

  const changeDateFormat = () => {
    const [year, month, day] = appointmentDate.split("-");

    // Convert the month number to its corresponding full month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const fullMonthName = monthNames[parseInt(month, 10) - 1];

    // Construct the formatted date string
    const formattedDate = `${fullMonthName} ${parseInt(day, 10)}, ${year}`;
    return formattedDate;
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={handleConfirmDialog}
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "29rem",
            height: "50rem",
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: "center" }}
          sx={{ m: 3, p: 2, mb: 0, pb: 0 }}
          id="customized-dialog-title"
        >
          <img
            src={Icon}
            alt="user-pic"
            height="45px"
            width="45px"
            style={{
              background: "rgba(235, 248, 255, 1)",
              padding: "10px",
              borderRadius: "48%",
            }}
          />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            height: "25px",
            width: "25px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ textAlign: "center" }}>
          <Grid>
            <Grid
              item
              xs={12}
              style={{ justifyContent: "center", marginTop: "1.8rem" }}
            >
              <Grid>
                <span style={{ fontSize: "15px" }}>
                  Please select availability <br /> for {appointmentDay},{" "}
                  {changeDateFormat(appointmentDate)}.
                </span>
              </Grid>

              <Grid
                style={{
                  width: "90%",
                  margin: "auto",
                  marginTop: "20px",
                  marginBottom: "25px",
                  borderRadius: "20px",
                }}
              >
                <Paper
                  elevation={0}
                  style={{ borderRadius: "18px", overflow: "hidden" }}
                >
                  <Grid
                    style={{
                      textAlign: "center",
                      height: "2.6rem",
                      background: "rgba(0, 161, 255, 1)",
                      color: "white",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {bookingContent?.serviceProvider}
                  </Grid>
                  <Grid
                    style={{
                      background: "rgba(242, 244, 255, 1)",
                      padding: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <span style={CardsContetnStyle}>
                      <b>Servicer Account ID : </b> <span style={{ ...CardsContetnStyle, color: "#02A0FC" }}>{bookingContent?.servicerAccount}</span>
                    </span>
                    <span style={CardsContetnStyle}>
                      <b>Dispatch Contact : </b>{" "}
                      <span style={{ ...CardsContetnStyle, color: "#02A0FC" }}>
                        {bookingContent?.dispatchMethodPhone}{" "}
                        {bookingContent?.dispatchMethodEmail && (
                          <>
                            <br />
                            {bookingContent?.dispatchMethodEmail}
                          </>
                        )}
                      </span>
                    </span>
                    <br />
                    <span style={CardsContetnStyle}>
                      <b> Dispatch Address :</b>{" "}
                      {bookingContent?.dispatchAddress}
                    </span>
                    <br />
                    <span style={CardsContetnStyle}>
                      <b>Notes : </b> <span>{bookingContent?.notes}</span>
                    </span>
                    <br />
                  </Grid>
                </Paper>
              </Grid>

              <Grid
                container
                xs={12}
                style={{ flexWrap: "wrap", justifyContent: "space-around" }}
              >
                <br />

                <Grid item xs={3} sx={{ marginTop: "8px" }}>
                  <span style={CardsContetnStyle}>
                    <b> Availability : </b>
                  </span>
                </Grid>
                <Grid container xs={8}>
                  <RadioGroup
                    name="options"
                    value={finalBookingTimeSlot}
                    onChange={handleAvailabilityRadio}
                    row
                  >
                    <Grid container xs={12}>
                      {bookingContent.svcrType === "WEB" ? (
                        <>
                          {Array.isArray(bookingContent?.timeSlot) &&
                            sortedTimeBand.map((time) => (
                              <>
                                <Grid
                                  item
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                  sx={{ margin: 0 }}
                                >
                                  <FormControlLabel
                                    sx={{ margin: 0 }}
                                    value={time}
                                    control={<Radio />}
                                    label={
                                      <span
                                        style={{ fontSize: "12px", margin: 0 }}
                                      >
                                        {time.charAt(0).toUpperCase() +
                                          time.slice(1)}
                                      </span>
                                    }
                                  />
                                </Grid>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          {Array.isArray(bookingContent?.timeSlot) &&
                            bookingContent?.timeSlot?.map((item) => (
                              <Grid
                                item
                                xs={7}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                sx={{ margin: 0 }}
                              >
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  value={item.slotName}
                                  control={<Radio />}
                                  label={
                                    <span
                                      style={{ fontSize: "12px", margin: 0 }}
                                    >
                                      {item.slotName}
                                    </span>
                                  }
                                />
                              </Grid>
                            ))}
                        </>
                      )}
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              {reassignIndex != 0 && dropDownAvailability && (
                <>
                  <Grid style={{ marginTop: ".5rem", fontSize: "12px" }}>
                    <span>
                      Please select a reason for choosing this servicer
                    </span>
                  </Grid>
                  <Grid style={{ width: "90%", margin: "auto" }}>
                    <FormControl
                      style={{ marginTop: "1rem" }}
                      fullWidth
                      size="small"
                    >
                      <Select
                        value={reassignReason ? reassignReason : ""}
                        onChange={(event) => {
                          const reason = event.target.value;
                          setReassignReason(reason);
                        }}
                        style={{ fontSize: "12px" }}
                      >
                        {reassignList?.map((value) => (
                          <MenuItem
                            key={value.reasonCode}
                            value={value.reasonCode}
                            sx={{ ...StyledScrollbar, fontSize: "12px" }}
                          >
                            {value.reasonName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid style={{ marginTop: "1.8rem" }}>
                <Button
                  variant="contained"
                  disabled={
                    (reassignIndex != 0 &&
                      dropDownAvailability &&
                      reassignReason === "") ||
                      finalBookingTimeSlot === ""
                      ? true
                      : false
                  }
                  onClick={() => handleOpenFinalConfirm()}
                  style={{ marginLeft: "20px" }}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
