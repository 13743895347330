import { Button, Dialog, DialogActions, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Icon from "../../img/delivery-box (1) 1.svg"
import { toast } from 'react-hot-toast';

const CancelServiceOrderDialog = ({ openCancelService, handleToggleCancelService, cancelReasons, servicerData, serviceOrderListObject, uiservicerData, getServicerOrderParent }) => {
    const [cancelReasonCode, setCancelReasonCode] = useState({ cancelCode: "", cancelReason: "" })

    const handleCancelOrder = async () => {
        if (!cancelReasonCode) {
            return toast.error("Please Select Cancel Reason")
        }

        handleToggleCancelService()

        const resobj = {
            "serviceAvengerContractNumber": serviceOrderListObject?.contract.contractNumber,
            "serviceOrderNumber": serviceOrderListObject?.serviceOrderId,
            "crmKey": serviceOrderListObject?.serviceOrder?.crmKey,
            "firstName": serviceOrderListObject?.consumer.firstName,
            "lastName": serviceOrderListObject?.consumer.lastName,
            "phone": serviceOrderListObject?.consumer.phone,
            "productSequenceNumber": serviceOrderListObject?.product?.productSequenceNumber,
            "servicerID": serviceOrderListObject?.serviceOrder?.servicerID,
            "servicerAccountId": serviceOrderListObject?.serviceOrder?.servicerAccountId,
            "appointmentDate": new Date(serviceOrderListObject?.serviceOrder?.appointmentDate).toISOString().split('T')[0],
            "appointmentTimeSlot": serviceOrderListObject?.serviceOrder?.appointmentTimeSlot,
            "servicerPhone": uiservicerData?.dispatchMethod.primaryPhone,
            "servicerEmail": uiservicerData?.dispatchMethod.primaryEmail,
            "cancelReason": cancelReasonCode.cancelReason,
            "cancelCode": cancelReasonCode.cancelCode,
            "subStatus": cancelReasonCode.cancelCode, // to be checked
            "providerType": serviceOrderListObject?.serviceOrder?.providerType,
            "servicerName": servicerData?.servicerName || uiservicerData?.account?.businessName
        }

        const toastId = toast.loading("Cancelling Service Order", { duration: 0 })

        await axios.post(`${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/cancel/appointment`, resobj,)
            .then(response => {

                if (response.data.isSuccess) {
                    getServicerOrderParent()
                    toast.dismiss(toastId);
                    toast.success("Cancelled Successfully")
                }
            })
            .catch(err => {
                toast.dismiss(toastId);
                toast.error(err.message || "Something Went Wrong")
            });
    }

    const handleAddcancelReason = (event) => {
        const selectedStatusName = event.target.value;

        const selectedStatus = cancelReasons?.find(
            (status) =>
                selectedStatusName === status.reasonName
        );
        setCancelReasonCode({ cancelCode: selectedStatus.reasonCode, cancelReason: selectedStatusName })
    }

    return (
        <>
            <Dialog
                open={openCancelService}
                aria-labelledby="customized-dialog-title"
                PaperProps={{
                    style: {
                        borderRadius: "20px",
                        width: '380px'
                    }
                }}
            >
                <DialogTitle sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2rem' }} >
                    <div style={{ backgroundColor: "#EBF8FF", borderRadius: "50%", width: "80px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Icon} alt='user-pic' height="45px" width="45px" style={{ background: "rgba(235, 248, 255, 1)", padding: "10px", borderRadius: "50%" }} />
                        </div>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleToggleCancelService}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogActions sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '15px' }}>
                    <Grid sx={{ textAlign: 'center' }}>
                        <p style={{ fontWeight: '600', fontSize: '14px' }}>Do you want to Cancel the Service Order ID?</p>
                    </Grid>
                    <p style={{ fontSize: '12px', color: '#5C678F', textAlign: 'center' }}>This action will cancel all the scheduled appointments for this service order ID.</p>
                    <p style={{ fontSize: '12px', color: '#5C678F', textAlign: 'center', marginTop: '10px' }}>Please provide the reason to cancel :</p>
                    <Grid sx={{ margin: '10px' }}>
                        <FormControl size="small" sx={{ minWidth: 300 }}>
                            <InputLabel id="demo-select-small-label" sx={{ fontSize: 'small' }}>Cancel</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                value={cancelReasonCode.cancelReason}
                                label="Cancel"
                                onChange={(event) => handleAddcancelReason(event)}
                                sx={{ fontSize: "12px" }}
                            >
                                {
                                    Array.isArray(cancelReasons) && cancelReasons?.map((item) => (
                                        <MenuItem key={item.reasonCode} value={item?.reasonName} sx={{ fontSize: 'small' }}>{item?.reasonName}</MenuItem>

                                    ))
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ margin: '1.5rem' }}>
                        <Button sx={{ textTransform: "capitalize" }} size='large' variant='outlined' color='primary' onClick={handleToggleCancelService} >No</Button>
                        <Button style={{ textTransform: "capitalize", marginLeft: 10 }} disabled={cancelReasonCode.cancelReason === "" ? true : false} size='large' variant='contained' color='primary' onClick={() => handleCancelOrder()} >Yes</Button>
                    </Grid>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default CancelServiceOrderDialog;