import React, { Component, Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

class Customer extends Component {
  render() {
    const {
      handleInputChange,
      customerRequestedDate,
      customerRequestedWindow,
      customerRequestedWindowArray,
      handleCustomerRequestedDate,
      symptomCode,
      symptomCodeList,
      failureDt,
      isSymptomCodeValidated,
      isSymptomCodeValid,
      handleFailureDateSelect,
      isFailureNoteValidated,
      isFailureNoteValid,
      failureNote,
      servicerNotes,
    } = this.props;
    return (
      <Fragment>
        <Form.Group
          as={Row}
          controlId="symptomCode"
          className="mb-1"
          style={{ marginTop: "4rem" }}
        >
          <Form.Label column xs={6}>
            Symptom Code:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              className={
                !isSymptomCodeValidated
                  ? null
                  : isSymptomCodeValid
                  ? "is-valid"
                  : "is-invalid"
              }
              as="select"
              value={symptomCode}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              <option value="">Select symptom code...</option>
              {symptomCodeList?.map((symptomCode) => (
                <option
                  key={symptomCode.description}
                  value={symptomCode.description}
                >
                  {symptomCode.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a symptom code.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureDt">
          <Form.Label column xs={6}>
            Failure Date:
          </Form.Label>
          <Col xs={6}>
            <DatePicker
              as={Form.Control}
              selected={new Date()}
              onSelect={(date) => handleFailureDateSelect(date)}
              value={failureDt}
              maxDate={new Date()}
              disabledKeyboardNavigation
              aria-describedby="failureDtHelpBlock"
              className="custom-datepicker"
            />
            <Form.Text id="failureDtHelpBlock" muted>
              <br></br>If unknown, use today's date.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="failureNote" className="mb-1">
          <Form.Label column xs={6}>
            Complaint:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="failure note"
              value={failureNote}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
              className={
                !isFailureNoteValidated
                  ? null
                  : isFailureNoteValid
                  ? "is-valid"
                  : "is-invalid"
              }
            />
            <Form.Control.Feedback type="invalid">
              Please provide the complaint.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="servicerNotes" className="mb-1">
          <Form.Label column xs={6}>
            Servicer Notes:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="textarea"
              placeholder="notes"
              value={servicerNotes}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="customerRequestedDate">
          <Form.Label column xs={6}>
            Customer Requested Date:
          </Form.Label>
          <Col xs={6}>
            <DatePicker
              as={Form.Control}
              selected={new Date()}
              onSelect={(date) => handleCustomerRequestedDate(date)}
              value={customerRequestedDate}
              minDate={new Date()}
              disabledKeyboardNavigation
              aria-describedby="failureDtHelpBlock"
              className="custom-datepicker"
            />
            <Form.Text id="failureDtHelpBlock" muted>
              <br></br>If unknown, use today's date.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customerRequestedSlot">
          <Form.Label column xs={6}>
            Customer Requested Window:
          </Form.Label>
          <Col xs={6}>
            <Form.Control
              as="select"
              value={customerRequestedWindow}
              defaultValue={"Morning"}
              size="sm"
              onChange={(event) => {
                handleInputChange(event);
              }}
            >
              {customerRequestedWindowArray?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Fragment>
    );
  }
}

export default Customer;
