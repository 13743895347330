import { EventType } from '@azure/msal-browser'
import { MsalContext } from '@azure/msal-react'
import {
  faCalendarMinus,
  faCalendarPlus,
  faInfoCircle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import axios from 'axios'
import parse from 'html-react-parser'
import moment from 'moment'
import React, { Component, Fragment, createRef } from 'react'
import {
  Alert,
  Button,
  Card,
  CloseButton,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Navbar,
  Row,
} from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { loginRequest } from '../Auth/authConfig'
import {
  addProduct,
  bookAppointment,
  cancelAppointment,
  createFNOL,
  getAppointments,
  getBookedAppointment,
  getCancelReasons,
  getContractDetails,
  getContractSearch,
  getDispatchMessageList,
  getFailureTypes,
  getManufacturers,
  getServiceOrders,
  getSubcategories,
  rebookAppointment,
  updateProduct,
} from '../api/serviceavenger'
import logo from '../img/ServiceAvengerLogo.png'
import './App.css'
import AppointmentList from './AppointmentList'
import AppointmentSuccessDialog from './AppointmentSuccessDialog'
import AssignmentDialog from './AssignmentDialog'
import ConfirmationDialog from './ConfirmationDialog'
import ContractModal from './ContractModal'
import Customer from './Customer'
import ExistingAppointment from './ExistingAppointment'
import ExistingAppointmentDialog from './ExistingAppointmentDialogs/ExistingAppointmentDialog'
import ForceAppointmentList from './ForceAppointmentList'
import ForceAssignmentDialog from './ForceAssignmentDialog'
import ForceBook from './ForceBook'
import LossCodeList from './LossCodeList'
import Product from './Product'
import ProductList from './ProductList'
import Service from './Service'
import ServiceOrderList from './ServiceOrderList'
import Panel from './OpenSearch/Panel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

class App extends Component {
  static contextType = MsalContext

  constructor(props) {
    super(props)
    this.productDetailsRef = createRef()
    this.serviceOrderRef = createRef()
    this.serviceInformationRef = createRef()
    this.accordianRef = React.createRef()
  }

  state = {
    address: '',
    address2: '',
    zipCode: '',
    brandCode: '',
    appointmentWorking: false,
    appointmentList: [],
    beginSearchDate: moment().format('MM/DD/YYYY'), // default to today's date then let did mount set the begin date.
    bookingType: 'Standard',
    brand: '', // mfg
    cancelReasonCode: '',
    cancelReasons: [],
    city: '',
    claimReimbursementMethod: '',
    condition: 'O', // default to O
    contextType: '',
    contractList: [],
    contractNumber: '', // onpoint contract number
    contractStatus: '',
    contractStatusCode: '',
    contractType: 'Service Contract', // default to Service Contract
    conversationId: '',
    countryCode: 'USA',
    coverageProductTypeCodeStr: '',
    customerId: '',
    dealerCustomerId: '',
    dealerName: '', // dealer name string
    deductible: 0,
    deductibleTerm: '',
    dispatchMessage: '',
    dispatchMessageList: [],
    effectiveDt: '', // contract begin date
    email: '',
    errorMessage: '',
    existingAppointment: null,
    expirationDt: '', // contract end date
    externalContractNumber: '',
    failureDt: moment(new Date()).format('MM/DD/YYYY'),
    failureNote: '',
    failureType: 'Standard',
    forcedAppointment: {
      appointmentInfo: {
        appointmentBrand: 'UNKNO',
        appointmentProduct: 'UNSPEC',
        sortOrder: 1,
        clientKey: 'I895',
        svcrName: 'ONPOINT TEST SERVICER',
        serviceCenterID: 'ONPOINT',
        svcrStatus: 'PHONE',
        svcrAddress1: '9900 CORPORATE CAMPUS DR  ',
        svcrAddress2: {
          $: {
            'xsi:nil': 'true',
          },
        },
        svcrCity: 'LOUISVILLE',
        svcrState: 'KENTUCKY',
        svcrZipcode: '40223',
        svcrPhone: '8776684681',
        svcrCountry: {
          $: {
            'xsi:nil': 'true',
          },
        },
        svcrOEMAuthFlag: 'NO',
        svcrRanking: '25.0',
        svcrType: 'PHONE',
        timeSlotData: {
          svcrID: '00000',
          offerID: '',
          availableDate: moment().format('MM/DD/YYYY'),
          timePeriod: '8-17',
          timePeriodSlotDescription: '',
          timePeriodSlot: '',
        },
      },
    },
    hasConcessionFlg: false,
    symptomCode: '',
    symptomCodeList: [],
    filteredProductList: [],
    firstName: '',
    functionalityStatus: 'Functional',
    isBrandValidated: false,
    isBrandValid: null,
    isCancelReasonValidated: false,
    isCancelReasonValid: null,
    isEffective: false,
    isFailureNoteValidated: false,
    isFailureNoteValid: null,
    isModelValidated: false,
    isModelValid: null,
    isPostalCodeValidated: false,
    isPostalCodeValid: null,
    isSubcategoryValidated: false,
    isSubcategoryValid: null,
    isSerialValidated: false,
    isSerialValid: null,
    isSymptomCodeValidated: false,
    isSymptomCodeValid: null,
    kustomerEmail: '',
    kustomerPhone: '',
    lastName: '',
    loading: false,
    lolAvailable: '',
    lolUsed: '',
    lossCodeList: [],
    manufacturers: [],
    model: '', // model number
    newBrand: '', //mfg
    newContract: {},
    newModel: '', // model number
    newProductCode: '', // subcategory code
    newProductTierCode: '',
    newSubcategory: '', // subcategory
    newSerial: '', // serial
    offerId: '',
    orgid: '',
    postalCode: '',
    primaryPhone: '',
    procedureCode: 'Residential Site',
    productCode: '', //subcategory code
    productTierCode: '',
    productPurchaseDate: null,
    purchasePrice: '',
    scrollableTabValue: 'Product Details',
    searchTabIndex: 0,
    secondaryPhone: '',
    selectedAppointment: null,
    selectedContract: null,
    selectedLossCode: null,
    selectedProduct: null,
    selectedServiceOrder: null,
    sequenceNumber: '',
    serial: '', // serial
    serviceElevation: 'Normal',
    serviceLocation: 'In Home',
    serviceNotice: 'First Notice',
    servicerNotes: '',
    serviceOrderClaimStatus: '',
    serviceOrderList: [],
    serviceOrderNumber: '',
    externalAppointmentNumber: '',
    serviceType: 'Repair',
    showAppointmentList: false,
    showBookSuccess: false,
    showCancelSuccess: false,
    showDispatchMessageModal: false,
    showFNOLSuccess: false,
    state: '',
    subCategory: '',
    subcategories: [],
    tierCode: '',
    totalLiability: '',
    unfilteredProductList: [],
    warrantyType: 'Service Contract',
    failureTypeList: [],
    serviceLocationList: [],
    serviceElevationList: [],
    serviceNoticeList: [],
    serviceTypeList: [],
    bookingTypeList: [],
    warrantyTypeList: [],
    functionalityStatusList: [],
    displayForceBook: false,
    showForceAppointmentList: false,
    selectedRepeatOrder: null,
    customerRequestedDate: moment(new Date()).format('MM/DD/YYYY'),
    customerRequestedWindowArray: ['Morning', 'Afternoon', 'Evening', 'All Day'],
    customerRequestedWindow: 'Morning',
    assignmentDialogOpen: false,
    assignmentDialogData: [],
    ForceAssignmentDialogOpen: false,
    ForceAssignmentDialogData: [],
    handleConfirmDialog: false,
    bookingContent: null,
    reasonList: [],
    servicerIndex: null,
    showAppointmentSuccess: false,
    softOrForceBook: '',
    shouldDisplayComponent: false,
    callbackId: null,
    responseInterceptorId: null,
    serviceLocationId: null,
    warrantyTypeId: null,
    serviceTypeId: null,
    isFnolCalled: false,
    showExistingAppointmentDialog: false,
    rescheduleDialog: false,
    reassignDialog: false,
    cancelReasonDialog: false,
    product: '',
    openAccrodian: false,
    isFirstNoticeOfLoss: false,
  }

  componentDidMount = async () => {
    if (window.Kustomer) {
      window.Kustomer.initialize(async (context) => {
        // Leave this for troubleshooting issues with Kustomer object
        console.log('Beginning of component did mount. context: ', context)
        this.setState({ contextType: context.type })

        if (context.type !== 'widget') {
          const { customer, company } = context
          const existingPhones = customer.attributes.phones
          const existingEmails = customer.attributes.emails
          let phone = ''
          let email = ''
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone
            phoneString = phoneString.replace(/\D/g, '')
            phone = phoneString.replace('+', '')
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10)
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email
          }

          this.setState({
            businessName: company,
            customerId: customer.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          })
        }

        if (context.type === 'conversation') {
          const { conversation, customer } = context
          const existingPhones = customer.attributes.phones
          const existingEmails = customer.attributes.emails
          let phone = ''
          let email = ''
          if (Object.keys(existingPhones).length > 0) {
            let phoneString = existingPhones[0].phone
            phoneString = phoneString.replace(/\D/g, '')
            phone = phoneString.replace('+', '')
            if (phone.length > 10) {
              phone = phone.substring(phone.length - 10)
            }
          }
          if (Object.keys(existingEmails).length > 0) {
            email = existingEmails[0].email
          }

          this.setState({
            conversationId: conversation.id,
            kustomerEmail: email,
            kustomerPhone: phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            orgid: customer.relationships.org.data.id,
          })
        }
        this.cancelTokenSource = axios.CancelToken.source()
        this.requestInterceptor = axios.interceptors.request.use(
          (config) => {
            // Add cancel token to the request config
            const token = localStorage.getItem('loginToken')
            config.headers.Authorization = `Bearer ${token}`
            config.cancelToken = this.cancelTokenSource.token
            return config
          },
          (error) => {
            return Promise.reject(error)
          },
        )
        this.responseInterceptor = axios.interceptors.response.use(
          function (response) {
            return response
          },
          (error) => {
            if (error.response && error.response.status === 401) {
              localStorage.clear()
              this.cancelTokenSource.cancel('Unauthorized')
              this.setState({ shouldDisplayComponent: false })
              return Promise.reject(error)
            }
            return Promise.reject(error)
          },
        )

        if (localStorage.getItem('loginToken')) {
          this.setState({ shouldDisplayComponent: true })
        }

        const callbackId = this.context.instance.addEventCallback((message) => {
          // This will be run every time an event is emitted after registering this callback
          if (message.eventType === EventType.LOGIN_FAILURE) {
            // Do something with the result
          }
        })
        const token = localStorage.getItem('loginToken')
        this.setState({ callbackId: callbackId })
        if (token) {
          this.getInitialData()
        }
      })
    }
    // Leave this for troubleshooting issues with Kustomer object
    console.log('End of component did mount.')
  }

  getInitialData() {
    this.getDispatchMessageList()
    this.getContractList()
    this.handleProductSelect(this.state?.product?.tierCode)
    this.getContractSearch()
  }

  componentDidUpdate(prevProps, prevState) {
    document.addEventListener('mousedown', this.handleClickOutside)

    if (!prevState.shouldDisplayComponent && this.state.shouldDisplayComponent) {
      this.getSubcategories()
      this.getManufacturers()
      this.getCancelReasons()
      this.getServiceInformationDropdowns()
    }
    if (prevState.product?.tierCode !== this.state.product?.tierCode) {
      this.handleProductSelect(this.state.product)
    }
  }

  componentWillUnmount() {
    // This will be run on component unmount

    document.removeEventListener('mousedown', this.handleClickOutside)

    if (this.state.callbackId) {
      this.context.instance.removeEventCallback(this.state.callbackId)
    }
    axios.interceptors.response.eject(this.responseInterceptor)
    axios.interceptors.response.eject(this.requestInterceptor)
  }

  handleClickOutside = (event) => {
    if (this.accordianRef.current && !this.accordianRef.current.contains(event.target)) {
      this.setState({ openAccrodian: false })
    }
  }

  handleDepotBookAppointment = async () => {
    let errorMessage = ''
    const {
      address,
      address2,
      brandCode,
      businessName,
      city,
      contractNumber,
      countryCode,
      customerId,
      effectiveDt,
      email,
      expirationDt,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      installDt,
      lastName,
      model,
      postalCode,
      primaryPhone,
      productCode,
      productTierCode,
      secondaryPhone,
      selectedAppointment,
      sequenceNumber,
      serial,
      serviceElevation,
      serviceLocation,
      serviceOrderNumber,
      servicerNotes,
      serviceType,
      state,
      symptomCode,
      warrantyType,
      catCode,
      serviceNotice,
      customerRequestedDate,
      customerRequestedWindow,
      selectedRepeatOrder,
      dealerCustomerId,
      isFnolCalled,
      procedureCode,
      dealerName,
      productPurchaseDate,
      subCategory,
    } = this.state

    const bookingNote = ''
    const sortOrderReason = ''
    const selectedSlot = ''

    if (this.ValidateBookAppointment()) {
      try {
        this.setState({ appointmentWorking: true })
        let warrantyTypeId = this.state.warrantyTypeId
        let serviceTypeId = this.state.serviceTypeId
        let serviceLocationId = this.state.serviceLocationId
        if (warrantyTypeId === null) {
          const data = this.state.warrantyTypeList.filter(
            (item) => item.warrantyTypeName === warrantyType,
          )
          warrantyTypeId = data[0].warrantyTypeId
        }
        if (serviceTypeId === null) {
          const data = this.state.serviceTypeList.filter(
            (item) => item.serviceTypeName === serviceType,
          )
          serviceTypeId = data[0].serviceTypeId
        }
        if (serviceLocationId === null) {
          const data = this.state.serviceLocationList.filter(
            (item) => item.serviceLocationName === procedureCode,
          )
          serviceLocationId = data.length > 0 ? data[0].serviceLocationId : ''
        }
        const response = await bookAppointment(
          'Forced', // sending forced
          firstName,
          lastName,
          businessName,
          address,
          address2,
          city,
          state,
          postalCode,
          primaryPhone,
          secondaryPhone,
          email,
          countryCode,
          customerId,
          sequenceNumber,
          catCode,
          brandCode,
          productCode,
          productTierCode,
          model,
          serial,
          warrantyType,
          effectiveDt,
          expirationDt,
          contractNumber,
          externalContractNumber,
          failureDt,
          serviceType,
          serviceElevation,
          serviceOrderNumber,
          serviceLocation,
          failureType,
          functionalityStatus,
          symptomCode,
          failureNote,
          selectedAppointment,
          servicerNotes,
          serviceNotice,
          customerRequestedDate,
          customerRequestedWindow,
          selectedRepeatOrder,
          bookingNote,
          dealerCustomerId,
          sortOrderReason,
          selectedSlot,
          isFnolCalled,
          installDt,
          procedureCode,
          serviceLocationId,
          warrantyTypeId,
          serviceTypeId,
          dealerName,
          productPurchaseDate,
          subCategory,
        )

        if (response.status === 200) {
          if (response.data.status !== 200) {
            const { data } = response
            errorMessage += `<br/><b>Error while attempting to book depot appointment:</b><br/>${data.status} - ${data.message}`
            let errorDetails = data?.data?.details

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>'
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`
              })
              errorMessage += '</ul>'
            } else {
              errorDetails = data?.data
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              } else {
                errorDetails = data?.data?.data?.details
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              }
            }

            this.setState({ errorMessage, appointmentWorking: false })
            window.location.href = '#errorFound'
          } else {
            const newServiceOrderNumber = response.data.data.callNumber
            const newServiceOrderList = await this.getServiceOrders(contractNumber)
            const newServiceOrderResults = newServiceOrderList.filter(
              (serviceorder) => serviceorder.serviceOrderNumber === newServiceOrderNumber,
            )
            const newServiceOrder =
              newServiceOrderResults.length > 0 ? newServiceOrderResults[0] : null

            await this.getAppointment(contractNumber, newServiceOrderNumber)
            this.setState({
              appointmentWorking: false,
              selectedServiceOrder: newServiceOrder,
              serviceOrderNumber: newServiceOrderNumber,
              showAppointmentList: false,
              selectedAppointment: null,
              showBookSuccess: true,
            })

            window.location.href = '#existingAppointment'

            setTimeout(() => {
              this.setState({
                showBookSuccess: false,
              })
            }, 3000)
          }
        } else {
          errorMessage += `<br/><b>Error attempting to book depot appointment:</b><br/>${response.status} - ${response.message}`
          this.setState({ errorMessage, appointmentWorking: false })
          window.location.href = '#errorFound'
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error when attempting to book depot appointment:</b><br/>${error.message}`,
          appointmentWorking: false,
        })
        window.location.href = '#errorFound'
      }
    }
  }

  handleAppointmentSuccess = async () => {
    this.setState({
      appointmentWorking: false,
      showAppointmentList: false,
      selectedAppointment: null,
      showBookSuccess: true,
      showForceAppointmentList: false,
      displayForceBook: false,
    })

    window.location.href = '#existingAppointment'

    setTimeout(() => {
      this.setState({
        showBookSuccess: false,
      })
    }, 3000)
  }

  handleForceBookAppointment = async () => {
    if (window.confirm('Are you sure you want to force book to OnPoint?')) {
      let errorMessage = ''
      const {
        address,
        address2,
        brandCode,
        businessName,
        city,
        contractNumber,
        countryCode,
        customerId,
        effectiveDt,
        email,
        expirationDt,
        // existingAppointment,
        externalContractNumber,
        failureDt,
        failureNote,
        failureType,
        firstName,
        forcedAppointment,
        functionalityStatus,
        lastName,
        model,
        postalCode,
        primaryPhone,
        productCode,
        productTierCode,
        secondaryPhone,
        sequenceNumber,
        serial,
        serviceElevation,
        serviceLocation,
        serviceOrderNumber,
        servicerNotes,
        serviceType,
        state,
        symptomCode,
        warrantyType,
        catCode,
        serviceNotice,
        customerRequestedDate,
        customerRequestedWindow,
        selectedRepeatOrder,
        dealerCustomerId,
        isFnolCalled,
        procedureCode,
        installDt,
        dealerName,
        productPurchaseDate,
        subCategory,
      } = this.state

      if (this.ValidateBookAppointment()) {
        try {
          const bookingNote = ''
          const sortOrderReason = ''
          const selectedSlot = ''

          let warrantyTypeId = this.state.warrantyTypeId
          let serviceTypeId = this.state.serviceTypeId
          let serviceLocationId = this.state.serviceLocationId
          if (warrantyTypeId === null) {
            const data = this.state.warrantyTypeList.filter(
              (item) => item.warrantyTypeName === warrantyType,
            )
            warrantyTypeId = data[0].warrantyTypeId
          }
          if (serviceTypeId === null) {
            const data = this.state.serviceTypeList.filter(
              (item) => item.serviceTypeName === serviceType,
            )
            serviceTypeId = data[0].serviceTypeId
          }
          if (serviceLocationId === null) {
            const data = this.state.serviceLocationList.filter(
              (item) => item.serviceLocationName === procedureCode,
            )
            serviceLocationId = data.length > 0 ? data[0].serviceLocationId : ''
          }
          this.setState({ appointmentWorking: true })
          const response = await bookAppointment(
            'Forced', // sending forced
            firstName,
            lastName,
            businessName,
            address,
            address2,
            city,
            state,
            postalCode,
            primaryPhone,
            secondaryPhone,
            email,
            countryCode,
            customerId,
            sequenceNumber,
            catCode,
            brandCode,
            productCode,
            productTierCode,
            model,
            serial,
            warrantyType,
            effectiveDt,
            expirationDt,
            contractNumber,
            externalContractNumber,
            failureDt,
            serviceType,
            serviceElevation,
            serviceOrderNumber,
            serviceLocation,
            failureType,
            functionalityStatus,
            symptomCode,
            failureNote,
            forcedAppointment,
            servicerNotes,
            serviceNotice,
            customerRequestedDate,
            customerRequestedWindow,
            selectedRepeatOrder,
            bookingNote,
            dealerCustomerId,
            sortOrderReason,
            selectedSlot,
            isFnolCalled,
            installDt,
            procedureCode,
            serviceLocationId,
            warrantyTypeId,
            serviceTypeId,
            dealerName,
            productPurchaseDate,
            subCategory,
          )

          if (response.status === 200) {
            if (response.data.status !== 200) {
              const { data } = response
              errorMessage += `<br/><b>Error while attempting to force book appointment:</b><br/>${data.status} - ${data.message}`
              let errorDetails = data?.data?.details
              if (errorDetails?.length > 0) {
                errorMessage += '<ul>'
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`
                })
                errorMessage += '</ul>'
              } else {
                errorDetails = data?.data
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>'
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                    })
                    errorMessage += '</ul>'
                  })
                } else {
                  errorMessage += `<ul>${data.data.bookingIndicator} - ${data.data.bookingMessage}</ul>`
                }
              }

              this.setState({ errorMessage, appointmentWorking: false })
              window.location.href = '#errorFound'
            } else {
              const newServiceOrderNumber = response.data.data.callNumber
              const newServiceOrderList = await this.getServiceOrders(contractNumber)
              const newServiceOrderResults = newServiceOrderList.filter(
                (serviceorder) => serviceorder.serviceOrderNumber === newServiceOrderNumber,
              )
              const newServiceOrder =
                newServiceOrderResults.length > 0 ? newServiceOrderResults[0] : null

              await this.getAppointment(contractNumber, newServiceOrderNumber)
              this.setState({
                appointmentWorking: false,
                selectedServiceOrder: newServiceOrder,
                serviceOrderNumber: newServiceOrderNumber,
                showAppointmentList: false,
                selectedAppointment: null,
                showBookSuccess: true,
              })

              window.location.href = '#existingAppointment'

              setTimeout(() => {
                this.setState({
                  showBookSuccess: false,
                })
              }, 3000)
            }
          } else {
            errorMessage += `<br/><b>Error attempting to force book appointment:</b><br/>${response.status} - ${response.message}`
            this.setState({ errorMessage, appointmentWorking: false })
            window.location.href = '#errorFound'
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error when attempting to force book appointment:</b><br/>${error.message}`,
            appointmentWorking: false,
          })
          window.location.href = '#errorFound'
        }
        // }
      }
    }
  }

  handleBookAppointment = async (
    selectedAppointment,
    bookingNote,
    sortOrderReason,
    selectedSlot,
  ) => {
    let errorMessage = ''
    const {
      address,
      address2,
      bookingType,
      brandCode,
      businessName,
      city,
      contractNumber,
      countryCode,
      customerId,
      effectiveDt,
      email,
      expirationDt,
      existingAppointment,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      installDt,
      lastName,
      model,
      postalCode,
      primaryPhone,
      catCode,
      productCode,
      productTierCode,
      secondaryPhone,
      sequenceNumber,
      serial,
      serviceElevation,
      serviceLocation,
      serviceOrderNumber,
      externalAppointmentNumber,
      providerType,
      servicerNotes,
      serviceType,
      state,
      symptomCode,
      warrantyType,
      serviceNotice,
      customerRequestedDate,
      customerRequestedWindow,
      selectedRepeatOrder,
      dealerCustomerId,
      isFnolCalled,
      procedureCode,
      // bookingNote
      dealerName,
      productPurchaseDate,
      subCategory,
    } = this.state

    this.setState({ selectedAppointment: selectedAppointment })

    if (this.ValidateBookAppointment()) {
      if (existingAppointment != null) {
        if (
          window.confirm(
            `There is an existing appointment on ${moment
              .utc(existingAppointment.appointmentDate)
              .format('MM/DD/YYYY')} from ${existingAppointment.appointmentTimeSlot}.
        Are you sure you wish to change to ${moment
          .utc(selectedAppointment.appointmentInfo.timeSlotData.availableDate)
          .format('MM/DD/YYYY')} from ${
              selectedAppointment.appointmentInfo.timeSlotData.timePeriod
            }?`,
          )
        ) {
          try {
            this.setState({ appointmentWorking: true })
            const response = await rebookAppointment(
              bookingType,
              firstName,
              lastName,
              businessName,
              address,
              address2,
              city,
              state,
              postalCode,
              primaryPhone,
              secondaryPhone,
              email,
              countryCode,
              customerId,
              sequenceNumber,
              brandCode,
              catCode,
              productCode,
              productTierCode,
              model,
              serial,
              warrantyType,
              effectiveDt,
              expirationDt,
              contractNumber,
              externalContractNumber,
              failureDt,
              serviceType,
              serviceElevation,
              serviceOrderNumber,
              externalAppointmentNumber,
              providerType,
              serviceLocation,
              failureType,
              functionalityStatus,
              symptomCode,
              failureNote,
              selectedAppointment,
              servicerNotes,
              dealerCustomerId,
              installDt,
              productPurchaseDate,
              subCategory,
            )

            if (response.status === 200) {
              if (response.data.status !== 200) {
                const { data } = response
                errorMessage += `<b>Error while attempting to re-book:</b><br/>${data?.status} - ${data?.message}`
                let errorDetails = data?.data?.details

                if (errorDetails?.length > 0) {
                  errorMessage += '<ul>'
                  errorDetails.forEach((error) => {
                    errorMessage += `<li>${error?.code} - ${error?.message}</li>`
                  })
                  errorMessage += '</ul>'
                } else {
                  errorDetails = data?.data
                  if (errorDetails.length > 0) {
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>'
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                      })
                      errorMessage += '</ul>'
                    })
                  } else {
                    errorDetails = data?.data?.data?.details
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>'
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                      })
                      errorMessage += '</ul>'
                    })
                  }
                }

                this.setState({ errorMessage, appointmentWorking: false })
                window.location.href = '#errorFound'
              } else {
                const newServiceOrderNumber = response.data.data.callNumber
                const externalAppointmentNumber = response.data.data.externalAppointmentNumber
                const newServiceOrderList = await this.getServiceOrders(contractNumber)
                const newServiceOrderResults = newServiceOrderList.filter(
                  (serviceorder) => serviceorder.serviceOrderNumber === newServiceOrderNumber,
                )
                const newServiceOrder =
                  newServiceOrderResults.length > 0 ? newServiceOrderResults[0] : null

                await this.getAppointment(contractNumber, newServiceOrderNumber)
                this.setState({
                  appointmentWorking: false,
                  selectedServiceOrder: newServiceOrder,
                  serviceOrderNumber: newServiceOrderNumber,
                  externalAppointmentNumber: externalAppointmentNumber,
                  showAppointmentList: false,
                  selectedAppointment: null,
                  showBookSuccess: true,
                })

                window.location.href = '#existingAppointment'

                setTimeout(() => {
                  this.setState({
                    showBookSuccess: false,
                  })
                }, 3000)
              }
            } else {
              errorMessage += `<br/><b>Error attempting to re-book appointment:</b><br/>${response.status} - ${response.message}`
              this.setState({ errorMessage, appointmentWorking: false })
              window.location.href = '#errorFound'
            }
          } catch (error) {
            this.setState({
              errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${error.message}`,
              appointmentWorking: false,
            })
            window.location.href = '#errorFound'
          }
        }
      } else {
        try {
          let warrantyTypeId = this.state.warrantyTypeId
          let serviceTypeId = this.state.serviceTypeId
          let serviceLocationId = this.state.serviceLocationId
          if (warrantyTypeId === null) {
            const data = this.state.warrantyTypeList.filter(
              (item) => item.warrantyTypeName === warrantyType,
            )
            warrantyTypeId = data[0].warrantyTypeId
          }
          if (serviceTypeId === null) {
            const data = this.state.serviceTypeList.filter(
              (item) => item.serviceTypeName === serviceType,
            )
            serviceTypeId = data[0].serviceTypeId
          }
          if (serviceLocationId === null) {
            const data = this.state.serviceLocationList.filter(
              (item) => item.serviceLocationName === procedureCode,
            )
            serviceLocationId = data.length > 0 ? data[0].serviceLocationId : ''
          }
          this.setState({
            appointmentWorking: true,
            loading: true,
            handleConfirmDialog: false,
          })
          const response = await bookAppointment(
            bookingType,
            firstName,
            lastName,
            businessName,
            address,
            address2,
            city,
            state,
            postalCode,
            primaryPhone,
            secondaryPhone,
            email,
            countryCode,
            customerId,
            sequenceNumber,
            catCode,
            brandCode,
            productCode,
            productTierCode,
            model,
            serial,
            warrantyType,
            effectiveDt,
            expirationDt,
            contractNumber,
            externalContractNumber,
            failureDt,
            serviceType,
            serviceElevation,
            serviceOrderNumber,
            serviceLocation,
            failureType,
            functionalityStatus,
            symptomCode,
            failureNote,
            selectedAppointment,
            servicerNotes,
            serviceNotice,
            customerRequestedDate,
            customerRequestedWindow,
            selectedRepeatOrder,
            bookingNote,
            dealerCustomerId,
            sortOrderReason,
            selectedSlot,
            isFnolCalled,
            installDt,
            procedureCode,
            serviceLocationId,
            warrantyTypeId,
            serviceTypeId,
            dealerName,
            productPurchaseDate,
            subCategory,
          )
          if (response.status === 200) {
            if (response.data.status !== 200) {
              const { data } = response
              errorMessage += `<br/><b>Error attempting to book appointment:</b><br/>${data.status} - ${data.message}`

              let errorDetails = data?.data?.details

              if (errorDetails?.length > 0) {
                errorMessage += '<ul>'
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`
                })
                errorMessage += '</ul>'
              } else {
                errorDetails = data?.data
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>'
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                    })
                    errorMessage += '</ul>'
                  })
                } else {
                  errorDetails = data?.data?.data?.details
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>'
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                    })
                    errorMessage += '</ul>'
                  })
                }
              }
              this.setState({
                errorMessage,
                appointmentWorking: false,
                loading: false,
                showAppointmentSuccess: false,
              })
              this.closeAssignmentDialog()
              window.location.href = '#errorFound'
            } else {
              this.setState({
                loading: false,
                appointmentWorking: false,
                showAppointmentSuccess: true,
                serviceOrderNumber:
                  response.data.data.serviceOrderNumber || response.data.data.callNumber,
                externalAppointmentNumber: response.data.data.externalAppointmentNumber,
              })
            }
          } else {
            errorMessage += `<br/><b>Error attempting to book appointment:</b><br/>${response.status} - ${response.message}`
            this.setState({
              errorMessage,
              appointmentWorking: false,
              loading: false,
            })
            this.closeAssignmentDialog()
            window.location.href = '#errorFound'
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${
              error?.response?.data?.message || error?.message
            }`,
            appointmentWorking: false,
            loading: false,
            showAppointmentSuccess: false,
          })
          this.closeAssignmentDialog()
          window.location.href = '#errorFound'
        }
      }
    }
  }

  handleForceBook = async (selectedAppointment, bookingNote, sortOrderReason, selectedSlot) => {
    let errorMessage = ''
    const {
      address,
      address2,
      bookingType,
      brandCode,
      businessName,
      city,
      contractNumber,
      countryCode,
      customerId,
      effectiveDt,
      email,
      expirationDt,
      existingAppointment,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      lastName,
      model,
      postalCode,
      primaryPhone,
      catCode,
      productCode,
      productTierCode,
      secondaryPhone,
      sequenceNumber,
      serial,
      serviceElevation,
      serviceLocation,
      serviceOrderNumber,
      externalAppointmentNumber,
      providerType,
      servicerNotes,
      serviceType,
      state,
      symptomCode,
      warrantyType,
      serviceNotice,
      customerRequestedDate,
      customerRequestedWindow,
      selectedRepeatOrder,
      dealerCustomerId,
      procedureCode,
      isFnolCalled,
      installDt,
      dealerName,
      productPurchaseDate,
      subCategory,
    } = this.state

    this.setState({ selectedAppointment: selectedAppointment })
    if (this.ValidateBookAppointment()) {
      if (existingAppointment != null) {
        if (
          window.confirm(
            `There is an existing appointment on ${moment
              .utc(existingAppointment.appointmentDate)
              .format('MM/DD/YYYY')} from ${existingAppointment.appointmentTimeSlot}.
        Are you sure you wish to change to ${moment
          .utc(selectedAppointment.appointmentInfo.timeSlotData.availableDate)
          .format('MM/DD/YYYY')} from ${
              selectedAppointment.appointmentInfo.timeSlotData.timePeriod
            }?`,
          )
        ) {
          try {
            this.setState({ appointmentWorking: true })
            const response = await rebookAppointment(
              bookingType,
              firstName,
              lastName,
              businessName,
              address,
              address2,
              city,
              state,
              postalCode,
              primaryPhone,
              secondaryPhone,
              email,
              countryCode,
              customerId,
              sequenceNumber,
              brandCode,
              catCode,
              productCode,
              productTierCode,
              model,
              serial,
              warrantyType,
              effectiveDt,
              expirationDt,
              contractNumber,
              externalContractNumber,
              failureDt,
              serviceType,
              serviceElevation,
              serviceOrderNumber,
              externalAppointmentNumber,
              providerType,
              serviceLocation,
              failureType,
              functionalityStatus,
              symptomCode,
              failureNote,
              selectedAppointment,
              servicerNotes,
              productPurchaseDate,
              subCategory,
            )

            if (response.status === 200) {
              if (response.data.status !== 200) {
                const { data } = response
                errorMessage += `<b>Error while attempting to re-book:</b><br/>${data?.status} - ${data?.message}`
                let errorDetails = data?.data?.details

                if (errorDetails?.length > 0) {
                  errorMessage += '<ul>'
                  errorDetails.forEach((error) => {
                    errorMessage += `<li>${error?.code} - ${error?.message}</li>`
                  })
                  errorMessage += '</ul>'
                } else {
                  errorDetails = data?.data
                  if (errorDetails.length > 0) {
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>'
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                      })
                      errorMessage += '</ul>'
                    })
                  } else {
                    errorDetails = data?.data?.data?.details
                    errorDetails?.forEach((error) => {
                      errorMessage += '<ul>'
                      Object.keys(error).forEach((key) => {
                        errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                      })
                      errorMessage += '</ul>'
                    })
                  }
                }

                this.setState({ errorMessage, appointmentWorking: false })
                window.location.href = '#errorFound'
              } else {
                const newServiceOrderNumber = response.data.data.callNumber
                const externalAppointmentNumber = response.data.data.externalAppointmentNumber
                const newServiceOrderList = await this.getServiceOrders(contractNumber)
                const newServiceOrderResults = newServiceOrderList.filter(
                  (serviceorder) => serviceorder.serviceOrderNumber === newServiceOrderNumber,
                )
                const newServiceOrder =
                  newServiceOrderResults.length > 0 ? newServiceOrderResults[0] : null

                await this.getAppointment(contractNumber, newServiceOrderNumber)
                this.setState({
                  appointmentWorking: false,
                  serviceOrderList: newServiceOrderList,
                  selectedServiceOrder: newServiceOrder,
                  serviceOrderNumber: newServiceOrderNumber,
                  externalAppointmentNumber: externalAppointmentNumber,
                  showAppointmentList: false,
                  selectedAppointment: null,
                  showBookSuccess: true,
                })

                window.location.href = '#existingAppointment'

                setTimeout(() => {
                  this.setState({
                    showBookSuccess: false,
                  })
                }, 3000)
              }
            } else {
              errorMessage += `<br/><b>Error attempting to re-book appointment:</b><br/>${response.status} - ${response.message}`
              this.setState({ errorMessage, appointmentWorking: false })
              window.location.href = '#errorFound'
            }
          } catch (error) {
            this.setState({
              errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${error.message}`,
              appointmentWorking: false,
            })
            window.location.href = '#errorFound'
          }
        }
      } else {
        this.setState({
          appointmentWorking: true,
          loading: true,
          handleConfirmDialog: false,
        })
        try {
          let warrantyTypeId = this.state.warrantyTypeId
          let serviceTypeId = this.state.serviceTypeId
          let serviceLocationId = this.state.serviceLocationId
          if (warrantyTypeId === null) {
            const data = this.state.warrantyTypeList.filter(
              (item) => item.warrantyTypeName === warrantyType,
            )
            warrantyTypeId = data[0].warrantyTypeId
          }
          if (serviceTypeId === null) {
            const data = this.state.serviceTypeList.filter(
              (item) => item.serviceTypeName === serviceType,
            )
            serviceTypeId = data[0].serviceTypeId
          }
          if (serviceLocationId === null) {
            const data = this.state.serviceLocationList.filter(
              (item) => item.serviceLocationName === procedureCode,
            )
            serviceLocationId = data.length > 0 ? data[0].serviceLocationId : ''
          }
          const response = await axios.post(
            `${process.env.REACT_APP_API_DEVELOPMENT_URL}standard/book/appointment`,
            {
              customer: {
                firstName: firstName,
                lastName: lastName,
                address1: address,
                address2: address2,
                businessName: businessName,
                city: city,
                stateProvince: state,
                zipCode: postalCode,
                cellPhone: primaryPhone,
                otherPhone: '',
                email: email,
                countryCode: countryCode,
                crmKey: customerId,
                repeatCall: serviceNotice === 'Repeat Request' ? 'YES' : 'NO',
                initialAppointmentDate: selectedAppointment?.availableDate
                  ? moment(selectedAppointment?.availableDate).format('YYYY-MM-DD')
                  : '',
                initialAppointmentWindow: selectedSlot,
              },
              product: {
                sequenceNumber: sequenceNumber,
                brandCode: brandCode,
                catCode: catCode,
                productCode: productCode,
                appointmentBrand: '',
                appointmentProduct: '',
                modelNumber: model,
                serialNumber: serial,
                installDate: moment(installDt).format('YYYY-MM-DD'),
                tierCode: productTierCode,
                productPurchaseDate: moment(productPurchaseDate).format('YYYY-MM-DD'),
                productSubCategory: subCategory,
              },
              warranty: {
                warrantyType: warrantyType,
                coverageBeginDate: moment(effectiveDt).format('YYYY-MM-DD'),
                coverageEndDate: moment(expirationDt).format('YYYY-MM-DD'),
                serviceAvengerContractNumber: contractNumber,
                externalContractNumber: externalContractNumber,
                warrantyTypeId: warrantyTypeId,
              },
              service: {
                serviceOrderNumber: serviceOrderNumber,
                externalAppointmentNumber: '',
                serviceCallDate: moment().format('YYYY-MM-DD hh:mm:ss'),
                failureDate: moment(failureDt).format('YYYY-MM-DD'),
                serviceRequestType: serviceType,
                serviceElevation: serviceElevation,
                serviceSite: serviceLocation,
                failureType: failureType,
                functionalityStatus: functionalityStatus,
                symptomCode: symptomCode,
                failureNote: failureNote,
                bookingType: 'forced',
                providerType: 'OPW',
                groupID: '',
                isReschedule: false,
                serviceNotice: serviceNotice,
                relatedTo: serviceNotice === 'Repeat Request' ? selectedRepeatOrder : null, // to be added
                bookingNote: bookingNote,
                dealerId: dealerCustomerId,
                procedureCode: procedureCode ? procedureCode : 'Field Service',
                isFnolCalled: isFnolCalled,
                serviceLocationId: serviceLocationId,
                serviceTypeId: serviceTypeId,
                dealerName: dealerName,
                isForceBooking: 1,
              },
              appointment: {
                offerKey: '',
                appointmentDate: selectedAppointment?.availableDate
                  ? moment(selectedAppointment?.availableDate).format('YYYY-MM-DD')
                  : '',
                appointmentTimeSlot: selectedSlot,
                appointmentDay: moment(selectedAppointment?.availableDate).format('dddd'),
                consumerRequestDate: moment(customerRequestedDate).format('YYYY-MM-DD'),
                consumerRequestTime: customerRequestedWindow,
                specialInstructions: servicerNotes,
                groupID: selectedAppointment.servicerAccount,
                preferredServicerName: 'Preferred Service Provider',
              },
              servicer: {
                // "specialInstructions": "",
                servicerID: selectedAppointment.servicerId,
                servicerAccount: selectedAppointment.servicerAccount,
                servicerName: selectedAppointment.serviceProvider,
                servicerPhone: selectedAppointment.dispatchMethodPhone || '',
                servicerWebsite: selectedAppointment.dispatchMethodEmail || '',
              },
              parts: {
                partDistibutorID: '',
                partDistributorName: '',
                partNumber: '',
                partDescription: '',
                partQuantity: 0,
                partPrice: 0.0,
                shipperCompany: '',
                shipperTracking: '',
              },
              payment: {
                deductible: 0.0,
                poNumber: '',
                poAmount: 0.0,
                authorizationNumber: '',
                billTo: '',
                notToExceedAmount: 500,
              },
              additionalFunctions: {
                status1: '',
                status2: '',
                status3: '',
                status4: '',
                status5: '',
                status6: '',
                status7: '',
                status8: '',
                status9: '',
                status10: '',
              },
              visitFrequency: 'NO',
              forcedBookingFlag: 'YES',
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              timeZoneMinuteDifference: new Date().getTimezoneOffset(),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
              },
            },
          )
          if (response && response.status === 200) {
            this.setState({
              loading: false,
              appointmentWorking: false,
              showAppointmentSuccess: true,
              serviceOrderNumber:
                response.data.data.serviceOrderNumber || response.data.data.callNumber,
              externalAppointmentNumber: response.data.data.externalAppointmentNumber,
            })
          }
        } catch (error) {
          this.setState({
            errorMessage: `<br/><b>Error attempting to book appointment:</b><br/>${
              error?.response?.data?.message || error.message
            }`,
            appointmentWorking: false,
            showAppointmentSuccess: false,
            loading: false,
          })
          this.closeForceAssignmentDialog()
          window.location.href = '#errorFound'
        }
      }
    }
  }

  closeAssignmentDialog = () => {
    this.setState({
      assignmentDialogOpen: false,
      selectedAppointment: null,
      assignmentDialogData: [],
      handleConfirmDialog: false,
      showAppointmentSuccess: false,
      bookingContent: '',
      softOrForceBook: '',
      // serviceOrderNumber: "",
      externalAppointmentNumber: '',
    })
  }

  closeForceAssignmentDialog = () => {
    this.setState({
      ForceAssignmentDialogOpen: false,
      selectedAppointment: null,
      ForceAssignmentDialogData: [],
      displayForceBook: false,
      handleConfirmDialog: false,
      bookingContent: '',
      showAppointmentSuccess: false,
      softOrForceBook: '',
      // serviceOrderNumber: "",
      externalAppointmentNumber: '',
    })
  }

  openConfirmationDialog = async (type, servicerData, subIndex, date) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      },
    }
    const listResponse = await axios.get(
      `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}getReasonList?providerType=OPW&reasonType=SORTORDER`,
      config,
    )
    if (listResponse && listResponse.data.isSuccess) {
      this.setState({ reasonList: listResponse.data.items })
    }
    if (type === 'SoftAvailability') {
      if (servicerData.svcrType === 'WEB') {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SDPROVIDER_URL}getSoftAvailabilityServicerDetail?servicerId=${servicerData.servicerId}`,
          config,
        )
        if (response && response.data.isSuccess) {
          const value = JSON.parse(JSON.stringify(response.data.data))
          const { dispatchMethodPhone, dispatchMethodEmail, notes, dispatchAddress } = value
          const newData = {
            ...servicerData,
            dispatchMethodPhone,
            dispatchAddress,
            dispatchMethodEmail,
            notes,
          }
          this.setState({ bookingContent: newData })
          this.setState({
            handleConfirmDialog: true,
            servicerIndex: subIndex,
            softOrForceBook: 'soft',
          })
        }
      } else {
        this.setState({
          bookingContent: servicerData,
          handleConfirmDialog: true,
          servicerIndex: subIndex,
          softOrForceBook: 'firm',
        })
      }
    } else {
      this.setState({
        bookingContent: {
          ...servicerData,
          availableDate: date,
          day: servicerData.dayOfWeek,
        },
        handleConfirmDialog: true,
        servicerIndex: subIndex,
        softOrForceBook: 'force',
      })
    }
  }

  closeConfirmDialog = () => {
    this.setState({ handleConfirmDialog: false })
  }

  ValidateBookAppointment = () => {
    const { contractStatusCode, selectedServiceOrder, sequenceNumber } = this.state
    if (selectedServiceOrder != null) {
      if (selectedServiceOrder.productSequenceNumber !== sequenceNumber.toString()) {
        alert('The selected service order is not for the selected product.')
        return false
      } else {
        return true
      }
    } else {
      if (contractStatusCode !== 'A') {
        alert('You cannot create a new service order for a contract that is not active.')
        return false
      } else {
        return true
      }
    }
  }

  handleAddProduct = async () => {
    let errorMessage = ''
    const {
      address,
      address2,
      city,
      condition,
      contractNumber,
      countryCode,
      dealerCustomerId,
      email,
      externalContractNumber,
      firstName,
      installDt,
      lastName,
      newBrand,
      newModel,
      newSubcategory,
      newSerial,
      postalCode,
      primaryPhone,
      purchaseDt,
      secondaryPhone,
      serviceLocation,
      state,
      unfilteredProductList,
    } = this.state

    let maxSequence = Math.max.apply(
      Math,
      unfilteredProductList.map(function (product) {
        return product.productSequenceNumber
      }),
    )
    maxSequence++

    try {
      const response = await addProduct(
        externalContractNumber,
        contractNumber,
        dealerCustomerId,
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        postalCode,
        primaryPhone,
        secondaryPhone,
        email,
        countryCode,
        maxSequence,
        newSubcategory,
        newBrand,
        newModel,
        newSerial,
        condition,
        serviceLocation,
        installDt,
        purchaseDt,
      )

      if (response.status === 200) {
        if (response.data.status !== 200) {
          const { data } = response
          errorMessage += `<b>Error while adding product:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage, appointmentWorking: false })
          window.location.href = '#errorFound'
          return null
        } else {
          const contractDetails = await this.getContractDetails(contractNumber)
          const newProductList = contractDetails.product
          const newProductSearch = newProductList.filter(
            (product) => product.productSequenceNumber === maxSequence,
          )
          const newProduct = newProductSearch.length > 0 ? newProductSearch[0] : null

          this.setState({
            unfilteredProductList: newProductList,
          })
          if (this.state.selectedLossCode != null && this.state.selectedLossCode !== '') {
            this.handleLossCodeSelect(this.state.selectedLossCode)
          }
          if (newProduct != null) {
            this.handleProductSelect(newProduct)
          }
        }
      } else {
        errorMessage += `<br/><b>Error attempting to add product:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error attempting to add product:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  handleCancelAppointment = async () => {
    let errorMessage = ''

    if (this.validateCancel()) {
      try {
        this.setState({ appointmentWorking: true, errorMessage: '' })

        const {
          cancelReasonCode,
          contractNumber,
          customerId,
          existingAppointment,
          serviceOrderNumber,
        } = this.state

        const response = await cancelAppointment(cancelReasonCode, customerId, existingAppointment)

        if (response.status === 200) {
          if (response.data?.status !== 200) {
            const { data } = response
            errorMessage += `<b>Error while attempting to cancel:</b><br/>${data?.status} - ${data?.message}`
            let errorDetails = data?.data?.details

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>'
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`
              })
              errorMessage += '</ul>'
            } else {
              errorDetails = data?.data
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              } else {
                errorDetails = data?.data?.data?.details
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              }
            }

            this.setState({ errorMessage, appointmentWorking: false })
            window.location.href = '#errorFound'
          } else {
            const updatedAppointment = await getBookedAppointment(
              contractNumber,
              serviceOrderNumber,
            )

            this.setState({
              existingAppointment: updatedAppointment.data.data[0],
            })

            const updatedServiceOrderList = await this.getServiceOrders(contractNumber)

            const updatedServiceOrderResults = updatedServiceOrderList.filter(
              (serviceorder) => serviceorder.serviceOrderNumber === serviceOrderNumber,
            )

            const updatedServiceOrder =
              updatedServiceOrderResults.length > 0 ? updatedServiceOrderResults[0] : null

            this.setState({
              cancelReasonCode: '',
              appointmentWorking: false,
              selectedAppointment: null,
              selectedServiceOrder: updatedServiceOrder,
              serviceOrderList: updatedServiceOrderList,
              showCancelSuccess: true,
            })

            window.location.href = '#existingAppointment'

            setTimeout(() => {
              this.setState({
                showCancelSuccess: false,
              })
            }, 3000)
          }
        } else {
          errorMessage += `<br/><b>Error before attempting to cancel appointment:</b><br/>${response.status} - ${response.message}`
          this.setState({ errorMessage, appointmentWorking: false })
          window.location.href = '#errorFound'
        }
      } catch (error) {
        this.setState({
          errorMessage: `<br/><b>Error attempting to cancel appointment:</b><br/>${error.message}`,
          appointmentWorking: false,
        })
        window.location.href = '#errorFound'
      }
    }
  }

  handleResetProduct = async () => {
    const { manufacturers, subcategories, selectedProduct } = this.state
    const { mfg, modelNumber, serial, subCategory, subCategoryCode } = selectedProduct
    const brandDetails = manufacturers.find(function (brandDetail) {
      return brandDetail.brand.toUpperCase() === mfg.toUpperCase()
    })

    const subcategoryDetails = subcategories.find(function (subcategory) {
      return (
        // subcategory.subCatDescription.toUpperCase() ===
        // subCategory.toUpperCase()
        subcategory.subCatCode.toUpperCase() === subCategoryCode.toUpperCase()
      )
    })

    this.setState({
      brand: mfg,
      brandCode: brandDetails.brandCode,
      model: modelNumber,
      catCode: subcategoryDetails.catCode,
      productCode: subcategoryDetails.subCatCode,
      productTierCode: subcategoryDetails.tierCode,
      subCategory,
      serial,
    })
  }

  handleCreateFNOL = async () => {
    this.setState({ loading: true })

    let errorMessage = ''
    const {
      address,
      address2,
      city,
      contractNumber,
      countryCode,
      customerId,
      deductible,
      email,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      firstName,
      functionalityStatus,
      hasConcessionFlg,
      lastName,
      primaryPhone,
      sequenceNumber,
      serviceElevation,
      servicerNotes,
      serviceNotice,
      serviceType,
      state,
      symptomCode,
      postalCode,
      isFnolCalled,
      customerRequestedDate,
      customerRequestedWindow,
    } = this.state

    if (this.validatePage()) {
      try {
        const response = await createFNOL(
          address,
          address2,
          city,
          countryCode,
          deductible,
          failureNote,
          failureType,
          functionalityStatus,
          serviceElevation,
          symptomCode,
          contractNumber,
          customerId,
          email,
          externalContractNumber,
          failureDt,
          firstName,
          hasConcessionFlg,
          lastName,
          primaryPhone,
          sequenceNumber,
          serviceNotice,
          servicerNotes,
          serviceType,
          state,
          postalCode,
          isFnolCalled,
          customerRequestedDate,
          customerRequestedWindow,
        )

        if (response.status === 200) {
          const newServiceOrderNumber = response.data.data.claimNumber
          const newServiceOrderList = await this.getServiceOrders(contractNumber)
          const newServiceOrderResults = newServiceOrderList.filter(
            (serviceorder) => serviceorder.serviceOrderNumber === newServiceOrderNumber,
          )
          const newServiceOrder =
            newServiceOrderResults.length > 0 ? newServiceOrderResults[0] : null
          await this.getAppointment(contractNumber, newServiceOrderNumber)
          this.setState({
            appointmentWorking: false,
            claimReimbursementMethod: newServiceOrder.claimReimbursementMethod,
            loading: false,
            serviceOrderList: newServiceOrderList,
            selectedServiceOrder: newServiceOrder,
            serviceOrderClaimStatus: newServiceOrder.claimStatus,
            serviceOrderNumber: newServiceOrderNumber,
            showAppointmentList: false,
            selectedAppointment: null,
            showFNOLSuccess: true,
            isFnolCalled: true, // added
          })
          window.location.href = '#existingAppointment'
          setTimeout(() => {
            this.setState({
              showFNOLSuccess: false,
            })
          }, 5000)
        } else {
          errorMessage += `<br/><b>Error creating FNOL:</b><br/>${response.status} - ${response.message}`
          this.setState({ errorMessage, loading: false })
          window.location.href = '#errorFound'
        }
      } catch (error) {
        this.setState({
          loading: false,
          errorMessage: `<br/><b>Error attempting to create FNOL:</b><br/>${error.message}`,
        })
        window.location.href = '#errorFound'
      }
    } else {
      this.setState({ loading: false })
    }
  }

  handleUpdateProduct = async () => {
    this.setState({ loading: true })

    let errorMessage = ''
    const {
      contractNumber,
      dealerCustomerId,
      externalContractNumber,
      sequenceNumber,
      productPurchaseDate, //  Added This
      productCode,
      brand,
      manufacturers,
      model,
      serial,
      selectedProduct,
      serviceLocation,
    } = this.state

    try {
      const response = await updateProduct(
        contractNumber,
        dealerCustomerId,
        externalContractNumber,
        sequenceNumber,
        productCode,
        brand,
        model,
        serial,
        serviceLocation,
        productPurchaseDate, // added this
      )

      this.setState({ loading: false })

      if (response.status === 200) {
        if (response.data.status !== 200) {
          const { data } = response
          errorMessage += `<b>Error while updating product:</b><br/>`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          const brandDetails = manufacturers.find(function (brandDetail) {
            return brandDetail.brand.toUpperCase() === selectedProduct.mfg.toUpperCase()
          })

          window.location.href = '#errorFound'
          this.setState({
            brand: selectedProduct.mfg.toUpperCase(),
            brandCode: brandDetails.brandCode,
            errorMessage,
            model: selectedProduct.modelNumber,
            serial: selectedProduct.serial,
          })
          return null
        } else {
          const updatedProduct = response.data.data.productDetails[0]
          selectedProduct.mfg = updatedProduct.manufacturer
          selectedProduct.modelNumber = updatedProduct.modelNumber
          selectedProduct.serial = updatedProduct.serialNumber

          const newProductList = await this.getContractDetails(contractNumber)

          this.setState({
            selectedProduct,
            unfilteredProductList: newProductList.product,
          })
        }
      } else {
        errorMessage += `<br/><b>Error attempting to update product:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error attempting to update product:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getContractList = async () => {
    // Leave this for troubleshooting issues with Kustomer object
    console.log(
      'Get contract url:',
      ` /v1/customers/${this.state.customerId}/klasses/contract?pageSize=500`,
    )
    try {
      const responseObj = await window.Kustomer.request({
        url: `/v1/customers/${this.state.customerId}/klasses/contract?pageSize=500`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      })
      // Leave this for troubleshooting issues with Kustomer object
      // console.log('Kustomer contract list response object: ', responseObj);
      this.setState({ contractList: responseObj })
      // this.setState({ selectedContract: this.state.contractList[0]?.attributes?.externalId })
      this.handleContractSelect(this.state.contractList[0]?.attributes?.externalId)
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error when getting contract list from Kustomer:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getServiceAppointments = async (beginDt) => {
    try {
      let errorMessage = this.state.errorMessage
      let appointments = []
      let selectedAppointment = null

      if (this.validatePage()) {
        this.setState({ loading: true })
        const {
          address,
          address2,
          beginSearchDate,
          bookingType,
          brandCode,
          businessName,
          city,
          contractNumber,
          countryCode,
          customerId,
          dealerCustomerId,
          effectiveDt,
          email,
          expirationDt,
          firstName,
          lastName,
          model,
          postalCode,
          primaryPhone,
          procedureCode,
          productCode,
          productTierCode,
          secondaryPhone,
          serial,
          serviceElevation,
          serviceNotice,
          serviceType,
          state,
          warrantyType,
          sequenceNumber,
        } = this.state

        const searchDt = beginDt
          ? moment(beginDt).format('YYYY-MM-DD')
          : moment(beginSearchDate).format('YYYY-MM-DD hh:mm:ss')

        let warrantyTypeId = this.state.warrantyTypeId
        let serviceTypeId = this.state.serviceTypeId
        let serviceLocationId = this.state.serviceLocationId
        if (warrantyTypeId === null) {
          const data = this.state.warrantyTypeList.filter(
            (item) => item.warrantyTypeName === warrantyType,
          )
          warrantyTypeId = data[0].warrantyTypeId
        }
        if (serviceTypeId === null) {
          const data = this.state.serviceTypeList.filter(
            (item) => item.serviceTypeName === serviceType,
          )
          serviceTypeId = data[0].serviceTypeId
        }
        if (serviceLocationId === null) {
          const data = this.state.serviceLocationList.filter(
            (item) => item.serviceLocationName === procedureCode,
          )
          serviceLocationId = data.length > 0 ? data[0].serviceLocationId : ''
        }

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
          },
        }

        // eslint-disable-next-line no-unused-vars
        const contractDetail = await axios.post(
          `${process.env.REACT_APP_API_DEVELOPMENT_URL}verifyContractDetails`,
          {
            serviceAvengerContractNumber: contractNumber,
          },
          config,
        )

        const response = await getAppointments(
          address,
          address2,
          bookingType,
          businessName,
          primaryPhone,
          city,
          countryCode,
          customerId,
          dealerCustomerId,
          email,
          firstName,
          lastName,
          secondaryPhone,
          state,
          postalCode,
          brandCode,
          model,
          productCode,
          productTierCode,
          serial,
          searchDt,
          procedureCode,
          serviceElevation,
          serviceNotice,
          serviceType,
          contractNumber,
          effectiveDt,
          expirationDt,
          warrantyType,
          warrantyTypeId,
          serviceTypeId,
          serviceLocationId,
          sequenceNumber,
        )
        this.setState({ loading: false })

        if (response.status === 200) {
          if (response.data.statusCode !== 200) {
            const { data } = response
            errorMessage += `<br/><b>Error getting appointments:</b><br/>${data.status} - ${data.message}`
            let errorDetails = data?.data?.details
            if (errorDetails?.length > 0) {
              errorMessage += '<ul>'
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`
              })
              errorMessage += '</ul>'
            } else {
              errorDetails = data?.data
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              } else {
                errorDetails = data?.data?.data?.details
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              }
            }

            this.setState({ errorMessage, loading: false })
            window.location.href = '#errorFound'
          } else {
            appointments = response?.data.data

            if (appointments?.length === 0) {
              toast.success('No Availability Found', { duration: 5000 })
            }
            const forcedAppointmentList = appointments.filter(
              (appointment) =>
                appointment?.appointmentInfo?.svcrName?.toUpperCase() === 'NO SERVICERS FOUND',
            )
            let forcedAppointmentDetail
            if (forcedAppointmentList.length > 0) {
              forcedAppointmentDetail = {
                appointmentInfo: {
                  ...forcedAppointmentList[0].appointmentInfo,
                  appointmentBrand: forcedAppointmentList[0].appointmentInfo.appointmentBrand,
                  appointmentProduct: forcedAppointmentList[0].appointmentInfo.appointmentProduct,
                },
              }
            }

            let appointmentList = []

            if (appointments.length === 1) {
              if (appointments.length > 0) {
                appointmentList = appointments.filter(
                  (appointment) =>
                    moment(appointment.appointmentInfo.timeSlotData.availableDate).format(
                      'YYYY-MM-DD',
                    ) !== moment().format('YYYY-MM-DD') &&
                    appointment.appointmentInfo.svcrName?.toUpperCase() !== 'NO SERVICERS FOUND',
                )
              }
              if (appointmentList.length > 0) {
                if (
                  appointments[0].appointmentInfo.svcrStatus?.toUpperCase() ===
                  'ONPOINT SPECIFIC SERVICER'.toUpperCase()
                ) {
                  appointments[0].appointmentInfo.timeSlotData.availableDate =
                    moment().format('YYYY-MM-DD')
                  appointments[0].appointmentInfo.timeSlotData.timePeriod = '8-17'
                  selectedAppointment = appointments[0]
                }
              }
            }

            this.setState({
              appointmentList: appointmentList,
              forcedAppointment: forcedAppointmentDetail,
              selectedAppointment,
              showAppointmentList: appointments.length === 1 ? true : false,
              assignmentDialogOpen: appointments.length > 1 ? true : false,
              assignmentDialogData: appointments,
              softOrForceBook: 'soft',
            })
          }
        }
      }
    } catch (error) {
      this.setState({ loading: false })

      let errorMessage = ''
      errorMessage += '<br/><b>Error with get appointments:</b><br/>' + error.Message
      this.setState({
        errorMessage,
      })
      window.location.href = '#errorFound'
    }
  }

  getDispatchMessageList = async () => {
    let errorMessage = ''
    try {
      const response = await getDispatchMessageList()
      const { data } = response
      if (response.status === 200) {
        if (response.data.status !== 200) {
          errorMessage += `<b>Error while getting dispatch messages:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return null
        } else {
          this.setState({ dispatchMessageList: response.data.data[0] })
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get dispatch messages:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting subcategories:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getContractSearch = async () => {
    const details = await getContractSearch()
    console.log('Opensearch: ', details)
  }

  getSubcategories = async () => {
    let errorMessage = ''
    try {
      const response = await getSubcategories()
      const { data } = response
      if (response.status === 200) {
        if (response.data.status !== 200) {
          errorMessage += `<b>Error while getting subcategories:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return null
        } else {
          this.setState({ subcategories: response.data.data.subcategories })
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get subcategories:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting subcategories:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getCancelReasons = async () => {
    let errorMessage = ''
    try {
      const response = await getCancelReasons()
      const { data } = response
      if (response.status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting cancel reasons:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return null
        } else {
          this.setState({ cancelReasons: response.data.data })
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get cancel reasons:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error when getting cancel reasons:</b><br/>${error}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getManufacturers = async () => {
    let errorMessage = ''
    try {
      const response = await getManufacturers()
      const { data } = response
      if (response.status === 200) {
        if (data.status !== 200) {
          const { data } = response
          errorMessage += `<br/><b>Error getting manufacturers:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return []
        } else {
          this.setState({ manufacturers: response.data.data })
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get manufacturers:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting manufacturers:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getServiceOrders = async (contractNumber) => {
    let errorMessage = ''
    try {
      const response = await getServiceOrders(contractNumber)
      const { data } = response
      if (response.status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting service orders:</b><br/>${data?.status} - ${data?.message}`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails?.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return null
        } else {
          const serviceOrderList = response.data.data
          this.setState({ serviceOrderList })
          this.setState({ existingAppointment: null }) // added this
          return serviceOrderList
        }
      } else {
        errorMessage += `<br/><b>Error attempting to get service orders:</b><br/>${response.status} - ${response.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting service orders:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  handleInputChange(event) {
    event.preventDefault()
    this.resetValidation()

    const { target } = event
    const { id, value } = target
    if (id === 'contractType') {
      this.getManufacturers()
    }

    if (id !== 'subCategory' && id !== 'newSubcategory') {
      if (id === 'warrantyType') {
        const data = this.state.warrantyTypeList.filter((item) => item.warrantyTypeName === value)
        this.setState({ warrantyTypeId: data[0].warrantyTypeId })
      }
      if (id === 'serviceType') {
        const data = this.state.serviceTypeList.filter((item) => item.serviceTypeName === value)
        this.setState({ serviceTypeId: data[0].serviceTypeId })
      }
      if (id === 'procedureCode') {
        const data = this.state.serviceLocationList.filter(
          (item) => item.serviceLocationName === value,
        )
        this.setState({ serviceLocationId: data[0].serviceLocationId })
      }
      this.setState({
        [id]: value,
        showAppointmentList: false,
        selectedAppointment: null,
      })
      // }
    }

    if (id === 'subCategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value
      })
      this.getSymptomCodeList(value)

      this.setState({
        subCategory: value,
        productCode: subCategory.subCatCode,
        productTierCode: subCategory.tierCode,
      })
    }

    if (id === 'newSubcategory') {
      const subCategory = this.state.subcategories.find(function (subcategory) {
        return subcategory.subCatDescription === value
      })

      this.setState({
        newSubcategory: value,
        newProductCode: subCategory.subCatCode,
        newProductTierCode: subCategory.tierCode,
      })
    }

    if (id === 'brand') {
      const brandDetails = this.state.manufacturers.find(function (brandDetail) {
        return brandDetail.brand.toUpperCase() === value
      })
      this.setState({ brand: value, brandCode: brandDetails.brandCode })
    }

    if (id === 'relatedTo') {
      this.setState({ selectedRepeatOrder: value })
    }

    if (id === 'customerRequestedSlot') {
      this.setState({ customerRequestedWindow: value })
    }
  }

  handleFailureDateSelect = (date) => {
    this.setState({
      failureDt: !date
        ? this.state.selectedServiceOrder
          ? moment(this.state.selectedServiceOrder?.claimDateConsumerFiled).format('MM/DD/YYYY')
          : moment(date).format('MM/DD/YYYY')
        : moment(date).format('MM/DD/YYYY'),
    })
  }

  handleProductPurchaseDate = (date) => {
    this.setState({ productPurchaseDate: moment(date).format('MM/DD/YYYY') })
  }

  handleCustomerRequestedDate = (date) => {
    this.setState({
      customerRequestedDate: !date
        ? this.state.selectedServiceOrder
          ? this.state.selectedServiceOrder?.claimDateConsumerFiled <
            this.state.selectedServiceOrder?.preferenceDate1
            ? this.state.selectedServiceOrder?.preferenceDate1
            : moment(date).format('MM/DD/YYYY')
          : moment(date).format('MM/DD/YYYY')
        : moment(date).format('MM/DD/YYYY'),
    })
  }

  handleAppointmentSelect = (appointment) => {
    this.setState({ selectedAppointment: appointment })
  }

  handleSearchTabSelect = (_, tabIndex) => {
    this.setState({ searchTabIndex: tabIndex })
  }

  handleServiceOrderSelect = async (serviceOrder, resetFlg) => {
    this.resetValidation()

    this.setState({ isFirstNoticeOfLoss: false, errorMessage: '' })
    const {
      appointmentNotes,
      address1,
      address2,
      city,
      claimFailureNote,
      claimReimbursementMethod,
      claimStatus,
      claimStorySymptom,
      country,
      email,
      firstName,
      lastName,
      mobileNumber,
      serviceOrderNumber,
      externalAppointmentNumber,
      providerType,
      state,
      zipCode,
    } = serviceOrder

    if (
      serviceOrderNumber === this.state.selectedServiceOrder?.serviceOrderNumber &&
      resetFlg === 1
    ) {
      if (this.state.selectedContract != null) {
        const { customer } = this.state.selectedContract
        const { address, email, firstName, lastName, phone } = customer
        const { address1, address2, city, postalCode, state } = address

        this.setState({
          address: address1,
          address2: address2,
          city,
          claimReimbursementMethod,
          email,
          existingAppointment: null,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          failureType: 'Standard',
          firstName,
          functionalityStatus: 'Functional',
          lastName,
          postalCode,
          primaryPhone: phone,
          selectedAppointment: null,
          selectedServiceOrder: null,
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceOrderClaimStatus: '',
          serviceOrderNumber: '',
          externalAppointmentNumber: '',
          providerType: '',
          serviceType: 'Repair',
          showAppointmentList: false,
          state,
          symptomCode: '',
          warrantyType: 'Service Contract',
        })
        this.setState({ claimReimbursementMethod: '' })
      }
    } else {
      this.setState({
        address:
          address1?.length > 0 && address1 !== this.state.address ? address1 : this.state.address,
        address2:
          address2?.length > 0 && address2 !== this.state.address2 ? address2 : this.state.address2,
        city: city?.length > 0 && city !== this.state.city ? city : this.state.city,
        claimReimbursementMethod,
        countryCode:
          country?.length > 0 && country !== this.state.countryCode
            ? country
            : this.state.countryCode,
        email: email?.length > 0 && email !== this.state.email ? email : this.state.email,
        failureNote: claimFailureNote,
        firstName:
          firstName?.length > 0 && firstName !== this.state.firstName
            ? firstName
            : this.state.firstName,
        lastName:
          lastName?.length > 0 && lastName !== this.state.lastName ? lastName : this.state.lastName,
        postalCode:
          zipCode?.length > 0 && zipCode !== this.state.postalCode
            ? zipCode
            : this.state.postalCode,
        primaryPhone:
          mobileNumber?.length > 0 && mobileNumber !== this.state.primaryPhone
            ? mobileNumber
            : this.state.primaryPhone,
        selectedServiceOrder: serviceOrder,
        servicerNotes: appointmentNotes,
        serviceOrderClaimStatus: claimStatus,
        serviceOrderNumber,
        externalAppointmentNumber,
        providerType,
        serviceType:
          claimStatus?.toUpperCase() === 'Advanced Exchange–FNOL'.toUpperCase()
            ? 'Advanced Exchange'
            : this.state.serviceType,
        state: state?.length > 0 && state !== this.state.state ? state : this.state.state,
        symptomCode: claimStorySymptom,
      })
      await this.getAppointment(this.state.contractNumber, serviceOrderNumber)
    }
  }

  getAppointment = async (contractNumber, serviceOrderNumber) => {
    let errorMessage = this.state.errorMessage
    if (contractNumber?.length > 0 && serviceOrderNumber?.length > 0) {
      try {
        const response = await getBookedAppointment(contractNumber, serviceOrderNumber)
        const { status, data } = response
        if (status === 200) {
          if (data.status !== 200) {
            errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${data?.message}`
            let errorDetails = data?.data?.details

            if (errorDetails?.length > 0) {
              errorMessage += '<ul>'
              errorDetails.forEach((error) => {
                errorMessage += `<li>${error?.code} - ${error?.message}</li>`
              })
              errorMessage += '</ul>'
            } else {
              errorDetails = data?.data
              if (errorDetails.length > 0) {
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              } else {
                errorDetails = data?.data?.data?.details
                errorDetails?.forEach((error) => {
                  errorMessage += '<ul>'
                  Object.keys(error).forEach((key) => {
                    errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                  })
                  errorMessage += '</ul>'
                })
              }
            }

            this.setState({ errorMessage })
            window.location.href = '#errorFound'
          } else {
            const appointmentList = data.data
            if (appointmentList.length > 0) {
              const appointmentDetails = appointmentList[0]
              const {
                failureDate,
                failureType,
                functionalityStatus,
                serviceRequestElevation,
                serviceRequestType,
                warrantyType,
                externalAppointmentNumber,
                providerType,
              } = appointmentDetails
              this.setState({
                existingAppointment: appointmentDetails,
                failureDt: moment.utc(failureDate).format('MM/DD/YYYY'),
                failureType,
                functionalityStatus,
                serviceElevation: this.state.selectedServiceOrder
                  ? this.state.selectedServiceOrder?.claimRequestPriority
                  : serviceRequestElevation,
                serviceType: serviceRequestType,
                warrantyType,
                externalAppointmentNumber,
                providerType,
              })
            } else {
              this.setState({
                existingAppointment: null,
                failureDt: moment(new Date()).format('MM/DD/YYYY'),
                failureType: 'Standard',
                functionalityStatus: 'Functional',
                serviceElevation: 'Normal',
                serviceType: 'Repair',
                warrantyType: 'Service Contract',
                externalAppointmentNumber: null,
                isFirstNoticeOfLoss: true,
              })
            }
          }
        } else {
          errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${status}`
          this.setState({ errorMessage })
          window.location.href = '#errorFound'
        }
      } catch (error) {
        errorMessage += `<br/><b>Error while getting booked appointment:</b><br/>${error.message}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
      }
    }
  }

  handleProductSelect = async (product) => {
    if (product) {
      this.setState({ loading: true, errorMessage: '' })
      const { manufacturers, subcategories } = this.state
      const {
        installDate,
        liabilityRemaining,
        liabilitySpent,
        mfg,
        modelNumber,
        productSequenceNumber,
        purchasePrice,
        serial,
        serviceLocation,
        subCategory,
        totalLiability,
        subCategoryCode,
      } = product
      let message = ''

      this.resetValidation()

      const brandDetails = manufacturers.find(function (brandDetail) {
        return brandDetail.brand.toUpperCase() === mfg.toUpperCase()
      })

      const subcategoryDetails = subcategories.find(function (subcategory) {
        return subcategory.subCatCode.toUpperCase() === subCategoryCode.toUpperCase()
      })

      const brandMessageList = await this.getDispatchMessageListByBrand(mfg)
      if (brandMessageList.length > 0) {
        message = await this.getDipatchMessageByModel(brandMessageList, modelNumber)
      }

      this.setState({
        brand: mfg,
        brandCode: brandDetails.brandCode,
        installDt: installDate,
        lolAvailable: liabilityRemaining,
        lolUsed: liabilitySpent,
        dispatchMessage: message && message.length > 0 ? message : '',
        model: modelNumber,
        catCode: subcategoryDetails?.catCode,
        productCode: subcategoryDetails?.subCatCode,
        productTierCode: subcategoryDetails?.tierCode,
        productPurchaseDate: moment(product.productPurchaseDate).format('MM/DD/YYYY'), // added this
        subCategory,
        purchasePrice,
        selectedAppointment: null,
        selectedProduct: product,
        sequenceNumber: productSequenceNumber,
        serial,
        serviceLocation,
        showAppointmentList: false,
        showDispatchMessageModal: message.length > 0 ? true : false,
        totalLiability: totalLiability,
      })
      await this.getSymptomCodeList(subCategory)

      this.setState({
        loading: false,
        showDispatchMessageModal: message.length > 0 ? true : false,
      })
    }
  }

  //This uses match to compare values. Some values were showing as !== even though they looked the same
  getDispatchMessageListByBrand = async (brandCode) => {
    const filteredList = this.state.dispatchMessageList.filter(function (message) {
      return message.brandCode?.toUpperCase().match(brandCode.toUpperCase())
    })

    return filteredList
  }

  //This uses match to compare values. Some values were showing as !== even though they looked the same
  getDipatchMessageByModel = async (messageList, model) => {
    const message = messageList.find((msg) => {
      return msg.model.toUpperCase().normalize().match(model.toUpperCase().normalize())
    })

    return message?.message ? message.message : ''
  }

  handleContractSelect = async (contractNumber) => {
    this.setState({ loading: true, errorMessage: '' })
    this.resetValidation()

    try {
      const selectedContract = await this.getContractDetails(contractNumber)

      this.setState({ loading: false })

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract
        const { address, email, firstName, lastName, phone } = customer
        const { address1, address2, city, countryCode, postalCode, state } = address
        const { productType, terms } = coverage
        const { deductible } = terms[0]
        const { type, amount } = deductible
        const { code, description } = status
        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          countryCode,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(moment().format('MM/DD/YYYY')) ? false : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: product[0]?.totalLiability,
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
          coverageDescription: coverage?.description,
          TotalCoverage: terms[0]?.termMonths,
          Industry: product[0]?.category,
          zipCode: postalCode,
          brandCode: '',
        })

        await this.getServiceOrders(contractNumber)
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage
      errorMessage += `<br/><b>Error while selecting contract:</b><br/>${error.message}`
      this.setState({
        errorMessage,
      })
      window.location.href = '#errorFound'
    }
  }

  handleNewContractSelect = async (contract) => {
    this.setState({ loading: true, errorMessage: '' })
    this.resetValidation()

    try {
      const selectedContract = await this.getContractDetails(contract.contractNumber)
      this.setState({ loading: false })

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          contractNumber,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract
        const { address, email, firstName, lastName, phone } = customer
        const { address1, address2, city, postalCode, state } = address
        const { productType, terms } = coverage
        const { deductible } = terms[0]
        const { type, amount } = deductible
        const { code, description } = status

        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(moment().format('MM/DD/YYYY')) ? false : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          newContract: selectedContract,
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: '',
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
          zipCode: postalCode,
        })

        await this.getServiceOrders(contractNumber)
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage
      errorMessage += `<br/><b>Error while getting new contract details:</b><br/>${error.message}`
      this.setState({
        errorMessage,
      })
      window.location.href = '#errorFound'
    }
  }

  handleNewContractDetails = async (contractNumber) => {
    this.setState({ loading: true, errorMessage: '' })
    this.resetValidation()

    try {
      const selectedContract = await this.getContractDetails(contractNumber)

      this.setState({ loading: false })

      if (selectedContract != null && selectedContract !== undefined) {
        const {
          components,
          coverage,
          customer,
          dealerName,
          dealerStoreNumber,
          effectiveDate,
          expirationDate,
          product,
          referenceNumber,
          status,
        } = selectedContract
        const { address, email, firstName, lastName, phone } = customer
        const { address1, address2, city, postalCode, state } = address
        const { productType, terms } = coverage
        const { deductible } = terms[0]
        const { type, amount } = deductible
        const { code, description } = status

        this.setState({
          address: address1,
          address2: address2,
          brand: '',
          city,
          contractNumber: contractNumber,
          contractStatus: description,
          contractStatusCode: code,
          coverageProductTypeCodeStr: productType.code,
          dealerName,
          dealerCustomerId: dealerStoreNumber,
          deductible: amount,
          deductibleTerm: type,
          effectiveDt: moment(effectiveDate).format('MM/DD/YYYY'),
          email,
          expirationDt: moment(expirationDate).format('MM/DD/YYYY'),
          externalContractNumber: referenceNumber,
          failureDt: moment(new Date()).format('MM/DD/YYYY'),
          failureNote: '',
          symptomCode: '',
          filteredProductList: [],
          firstName,
          functionalityStatus: 'Functional',
          isEffective: moment(effectiveDate).isAfter(moment().format('MM/DD/YYYY')) ? false : true,
          lastName,
          lolAvailable: '',
          lolUsed: '',
          lossCodeList: components.losscodes,
          model: '',
          newContract: selectedContract,
          postalCode,
          primaryPhone: phone,
          productCode: '',
          productTierCode: '',
          purchasePrice: '',
          selectedAppointment: null,
          selectedContract,
          selectedLossCode: null,
          selectedProduct: null,
          sequenceNumber: '',
          serial: '',
          serviceElevation: 'Normal',
          serviceNotice: 'First Notice',
          servicerNotes: '',
          serviceType: 'Repair',
          state,
          totalLiability: '',
          unfilteredProductList: product,
          warrantyType: 'Service Contract',
        })

        await this.getServiceOrders(contractNumber)
      }
    } catch (error) {
      let errorMessage = this.state.errorMessage
      errorMessage += `<br/><b>Error while getting new contract details:</b><br/>${error.message}`
      this.setState({
        errorMessage,
      })
      window.location.href = '#errorFound'
    }
  }

  handleLossCodeSelect = async (lossCode) => {
    this.resetValidation()
    this.setState({ loading: true, errorMessage: '' })

    if (lossCode != null && lossCode !== '') {
      const { subcategories, unfilteredProductList } = this.state
      const subCategory = subcategories.find(function (subcategory) {
        return subcategory.subCatCode === lossCode.code
      })
      const productCode = subCategory?.subCatCode // DW
      const subCategoryDescription = subCategory?.subCatDescription?.toUpperCase()
      const filteredProductList = unfilteredProductList?.filter(function (product) {
        return product.subCategory?.toUpperCase() === subCategory?.subCatDescription?.toUpperCase()
      })
      this.setState({
        brand: '',
        filteredProductList: filteredProductList,
        model: '',
        productCode,
        productTierCode: subCategory?.tierCode,
        newProductCode: productCode,
        newProductTierCode: subCategory?.tierCode,
        subCategory: subCategoryDescription,
        newSubcategory: subCategoryDescription,
        newTierCode: subCategory?.tierCode,
        purchasePrice: '',
        lolUsed: '',
        lolAvailable: '',
        selectedAppointment: null,
        selectedLossCode: lossCode,
        selectedProduct: null,
        sequenceNumber: '',
        serial: '',
        totalLiability: '',
      })
      this.resetValidation()
      await this.getSymptomCodeList(subCategory?.subCatDescription)
    }
    this.setState({ loading: false })
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
  }

  validateCancel = () => {
    const { cancelReasonCode } = this.state

    this.setState({
      errorMessage: '',
      isCancelReasonValidated: true,
      isCancelReasonValid: cancelReasonCode?.trim().length === 0 ? false : true,
    })

    return cancelReasonCode.length > 0
  }

  validatePage = () => {
    const {
      brand,
      model,
      serial,
      subCategory,
      postalCode,
      selectedProduct,
      symptomCode,
      failureNote,
      selectedRepeatOrder,
      serviceNotice,
    } = this.state

    let errorMessage = ''
    let hasDifference =
      (selectedProduct.mfg.toUpperCase() !== brand.toUpperCase() && brand.length > 0) ||
      (selectedProduct.modelNumber.toUpperCase() !== model.toUpperCase() && model.length > 0) ||
      (selectedProduct.serial.toUpperCase() !== serial.toUpperCase() && serial.length > 0) ||
      (selectedProduct.subCategory !== subCategory && subCategory.length > 0)

    if (hasDifference) {
      errorMessage = 'Please update the product before continuing.'
      window.location.href = '#errorFound'
    }

    // Check for Repeat Request and selectedRepeatOrder being null or empty
    if (
      serviceNotice === 'Repeat Request' &&
      (!selectedRepeatOrder || selectedRepeatOrder.trim() === '')
    ) {
      errorMessage = 'Please select a repeat order before continuing.'
      window.location.href = '#errorFound'
    }

    this.setState({
      errorMessage,
      isBrandValid: brand.length === 0 || brand.toUpperCase() === 'UNKNOWN' ? false : true,
      isBrandValidated: true,
      isModelValid: model.length === 0 || model.toUpperCase() === 'UNKNOWN' ? false : true,
      isModelValidated: true,
      isSerialValid: serial.length === 0 || serial.toUpperCase() === 'UNKNOWN' ? false : true,
      isSerialValidated: true,
      isSubcategoryValid:
        subCategory.length === 0 || subCategory.toUpperCase() === 'UNKNOWN' ? false : true,
      isSubcategoryValidated: true,
      isPostalCodeValidated: true,
      isPostalCodeValid: postalCode.length < 5 ? false : true,
      isSymptomCodeValidated: true,
      isSymptomCodeValid: symptomCode.length === 0 ? false : true,
      isFailureNoteValidated: true,
      isFailureNoteValid: failureNote.length === 0 ? false : true,
    })

    if (
      brand.length === 0 ||
      brand.toUpperCase() === 'UNKNOWN' ||
      model.length === 0 ||
      model.toUpperCase() === 'UNKNOWN' ||
      serial.length === 0 ||
      serial.toUpperCase() === 'UNKNOWN' ||
      postalCode.length === 0 ||
      subCategory.toUpperCase() === 'UNKNOWN' ||
      hasDifference
    ) {
      window.location.href = '#ProductError'
    }
    return (
      brand.length > 0 &&
      brand.toUpperCase() !== 'UNKNOWN' &&
      model.length > 0 &&
      model.toUpperCase() !== 'UNKNOWN' &&
      serial.length > 0 &&
      serial.toUpperCase() !== 'UNKNOWN' &&
      postalCode.length > 0 &&
      failureNote.length > 0 &&
      subCategory.toUpperCase() !== 'UNKNOWN' &&
      symptomCode.length > 0 &&
      !hasDifference &&
      !(
        serviceNotice === 'Repeat Request' &&
        (!selectedRepeatOrder || selectedRepeatOrder.trim() === '')
      )
    )
  }

  resetValidation = () => {
    this.setState({
      isBrandValid: null,
      isBrandValidated: false,
      isModelValid: null,
      isModelValidated: false,
      isSerialValid: null,
      isSerialValidated: false,
      isSubcategoryValid: null,
      isSubcategoryValidated: false,
      isPostalCodeValidated: false,
      isPostalCodeValid: null,
      isSymptomCodeValid: null,
      isSymptomCodeValidated: false,
      isFailureNoteValid: null,
      isFailureNoteValidated: false,
      showAppointmentList: false,
      selectedAppointment: null,
    })
  }

  getContractDetails = async (contractNumber) => {
    try {
      let errorMessage = ''

      const response = await getContractDetails(contractNumber)
      const { status, data, statusText } = response

      if (status === 200) {
        if (data.status !== 200) {
          errorMessage += `<b>Error while getting contract details:</b><br/>${data?.status} - ${data?.message}`
          errorMessage += `<br/>Attempted to search for contract ${contractNumber}.`
          let errorDetails = data?.data?.details

          if (errorDetails?.length > 0) {
            errorMessage += '<ul>'
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`
            })
            errorMessage += '</ul>'
          } else {
            errorDetails = data?.data
            if (errorDetails.length > 0) {
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            } else {
              errorDetails = data?.data?.data?.details
              errorDetails?.forEach((error) => {
                errorMessage += '<ul>'
                Object.keys(error).forEach((key) => {
                  errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                })
                errorMessage += '</ul>'
              })
            }
          }

          this.setState({ errorMessage })
          window.location.href = '#errorFound'
          return null
        } else {
          return data.data.contract
        }
      } else {
        errorMessage = this.state.errorMessage
        errorMessage += `<br/><b>Error while getting contract details:</b><br/> ${status} - ${statusText}`
        this.setState({ errorMessage })
        window.location.href = '#errorFound'
        return null
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting contract details:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getSymptomCodeList = async (subcategory) => {
    try {
      const { subcategories } = this.state

      let errorMessage = this.state.errorMessage
      const category = subcategories.find(function (category) {
        return category.subCatDescription?.toUpperCase() === subcategory?.toUpperCase()
      })

      if (category != null) {
        if (category?.tierCode != null && category?.tierCode !== '') {
          const { tierCode } = category
          const response = await getFailureTypes(tierCode)
          const { status, data } = response

          if (status === 200) {
            if (data.status !== 200) {
              errorMessage += `<br/><b>Error while getting failure types:</b><br/>${data?.data?.code} - ${data?.data?.message}`
              errorMessage += `<br/>Attempted to search for tier code ${tierCode}.`

              let errorDetails = data?.data?.details

              if (errorDetails?.length > 0) {
                errorMessage += '<ul>'
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error?.code} - ${error?.message}</li>`
                })
                errorMessage += '</ul>'
              } else {
                errorDetails = data?.data
                if (errorDetails.length > 0) {
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>'
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                    })
                    errorMessage += '</ul>'
                  })
                } else {
                  errorDetails = data?.data?.data?.details
                  errorDetails?.forEach((error) => {
                    errorMessage += '<ul>'
                    Object.keys(error).forEach((key) => {
                      errorMessage += `<li>${key} - ${error[key].toString()}</li>`
                    })
                    errorMessage += '</ul>'
                  })
                }
              }

              this.setState({ errorMessage })
              window.location.href = '#errorFound'
            } else {
              const list = data.data.failures
              this.setState({ symptomCodeList: list.length > 0 ? list : [] })
            }
          } else {
            errorMessage += `<br/><b>Error while getting failure types:</b><br/>${status}`
            this.setState({ errorMessage })
            window.location.href = '#errorFound'
          }
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting failure types:</b><br/>${error.message}`,
      })
      window.location.href = '#errorFound'
    }
  }

  getNextWeek = async () => {
    let beginDt = new Date(this.state.beginSearchDate)
    beginDt.setDate(beginDt.getDate() + 7)
    await this.getServiceAppointments(beginDt)
    this.setState({
      beginSearchDate: moment(beginDt).format('MM/DD/YYYY'),
      selectedAppointment: null,
    })
  }

  getLastWeek = async () => {
    let beginDt = new Date(this.state.beginSearchDate)
    beginDt.setDate(beginDt.getDate() - 7)
    await this.getServiceAppointments(beginDt)
    this.setState({
      beginSearchDate: moment(beginDt).format('MM/DD/YYYY'),
      selectedAppointment: null,
    })
  }

  getPreviousButton() {
    if (moment(this.state.beginSearchDate).isSameOrBefore(moment().format('MM/DD/YYYY'))) {
      return null
    } else {
      return (
        <Button
          className='ms-1 me-1 RoundedButton'
          disabled={this.state.loading}
          onClick={() => {
            this.getLastWeek()
          }}
          size='sm'
          variant='primary'
        >
          Appointments Prior Week
        </Button>
      )
    }
  }

  getNextButton() {
    if (
      moment(this.state.beginSearchDate).isSameOrAfter(
        moment().add(56, 'days').format('MM/DD/YYYY'),
      )
    ) {
      return null
    } else {
      return (
        <Button
          className='ms-1 me-1 RoundedButton'
          disabled={this.state.loading}
          onClick={() => {
            this.getNextWeek()
          }}
          size='sm'
          variant='primary'
        >
          Appointments Next Week
        </Button>
      )
    }
  }

  microsoftLogin = async () => {
    const msalInstance = this.context.instance
    // Initiate the login process
    try {
      const response = await msalInstance.loginPopup(loginRequest)
      let res
      // Log the payload of the token request
      let obj = {
        token: response.accessToken,
        provider: 'microsoft',
      }
      if (response) {
        res = await axios.post(`${process.env.REACT_APP_LOGIN_URL}users/login`, obj)
      }

      if (res?.data?.isSuccess) {
        localStorage.removeItem('roleType')
        window.localStorage.setItem('loginToken', res?.data?.data?.token)
        window.localStorage.setItem('email', res?.data?.data?.user?.email)
        window.localStorage.setItem('servicerId', res?.data?.data?.user?.userId)
        window.localStorage.setItem('roleType', res?.data?.data?.user?.roleType)
        this.getInitialData()
        this.setState({ shouldDisplayComponent: true })
      }
    } catch (error) {}
  }

  getDisplay() {
    const {
      address,
      address2,
      appointmentList,
      appointmentWorking,
      beginDt,
      beginSearchDate,
      bookingType,
      brand,
      cancelReasons,
      cancelReasonCode,
      city,
      claimReimbursementMethod,
      condition,
      contractList,
      contractNumber,
      contractStatus,
      contractStatusCode,
      contractType,
      coverageProductTypeCodeStr,
      dealerName,
      deductible,
      deductibleTerm,
      dispatchMessage,
      effectiveDt,
      email,
      endDt,
      errorMessage,
      existingAppointment,
      expirationDt,
      externalContractNumber,
      failureDt,
      failureNote,
      failureType,
      filteredProductList,
      firstName,
      functionalityStatus,
      hasConcessionFlg,
      isBrandValid,
      isBrandValidated,
      isCancelReasonValid,
      isCancelReasonValidated,
      isEffective,
      isFailureNoteValid,
      isFailureNoteValidated,
      isModelValid,
      isModelValidated,
      isPostalCodeValid,
      isPostalCodeValidated,
      isSubcategoryValid,
      isSubcategoryValidated,
      isSerialValid,
      isSerialValidated,
      isSymptomCodeValid,
      isSymptomCodeValidated,
      kustomerEmail,
      kustomerPhone,
      lastName,
      loading,
      lolAvailable,
      lolUsed,
      lossCodeList,
      manufacturers,
      model,
      postalCode,
      primaryPhone,
      procedureCode,
      productPurchaseDate,
      purchasePrice,
      searchTabIndex,
      secondaryPhone,
      selectedAppointment,
      selectedContract,
      selectedLossCode,
      selectedProduct,
      selectedServiceOrder,
      serial,
      serviceElevation,
      servicerNotes,
      serviceNotice,
      serviceOrderClaimStatus,
      serviceOrderList,
      serviceOrderNumber,
      serviceType,
      showAppointmentList,
      showForceAppointmentList,
      showBookSuccess,
      showCancelSuccess,
      showDispatchMessageModal,
      showFNOLSuccess,
      state,
      subCategory,
      subcategories,
      symptomCode,
      symptomCodeList,
      totalLiability,
      unfilteredProductList,
      warrantyType,
      failureTypeList,
      serviceLocationList,
      serviceElevationList,
      serviceNoticeList,
      serviceTypeList,
      bookingTypeList,
      warrantyTypeList,
      functionalityStatusList,
      displayForceBook,
      selectedRepeatOrder,
      customerRequestedDate,
      customerRequestedWindow,
      customerRequestedWindowArray,
      assignmentDialogData,
      assignmentDialogOpen,
      ForceAssignmentDialogData,
      ForceAssignmentDialogOpen,
      bookingContent,
      handleConfirmDialog,
      reasonList,
      servicerIndex,
      externalAppointmentNumber,
      showAppointmentSuccess,
      softOrForceBook,
      shouldDisplayComponent,
      showExistingAppointmentDialog,
      rescheduleDialog,
      reassignDialog,
      cancelReasonDialog,
      isClicked = {
        'Product Details': false,
        'Service Orders': false,
        'Service Information': false,
      },
      anchorEl,
      SelectedContractNumber = contractList[0]?.attributes?.externalId,
      showNewContractModal = false,
      ProductSubCategory,
      ProductModelNumber,
      coverageDescription,
      scrollableTabValue,
      TotalCoverage,
      Industry,
    } = this.state

    const smoothScroll = (targetId) => {
      this.setState({ openAccrodian: false })
      this.setState({ searchTabIndex: 0 })
      this.setState({ scrollableTabValue: targetId })
      this.setState((prevState) => {
        const updatedIsClicked = { ...prevState.isClicked }

        // Set the clicked button to true
        updatedIsClicked[targetId] = true
        Object.keys(updatedIsClicked).forEach((key) => {
          if (key !== targetId) {
            updatedIsClicked[key] = false
          }
        })

        return { isClicked: updatedIsClicked }
      })

      switch (targetId) {
        case 'Product Details':
          this.productDetailsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
          break
        case 'Service Orders':
          this.serviceOrderRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
          break
        case 'Service Information':
          this.serviceInformationRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
          break
        default:
          this.setState({ scrollableTabValue: 'Product Details' })
          break
      }
    }

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget })
    }

    const handleClose = () => {
      this.setState({ anchorEl: null })
    }

    const handleSelectedContractNumber = (event) => {
      event.stopPropagation()
      this.setState((pre) => ({ SelectedContractNumber: event.target.value }))
      this.handleContractSelect(event.target.value)
    }

    const handleAddNewContract = (fun) => {
      this.setState({ showNewContractModal: true })
      handleClose()
    }

    const updateShowNewContractModal = (value) => {
      this.setState({ showNewContractModal: value })
    }

    const getProductSubCategory = (ProductSubCategory, ProductModelNumber, product) => {
      if (
        this.state.ProductSubCategory !== ProductSubCategory ||
        this.state.ProductModelNumber !== ProductModelNumber
      ) {
        this.setState({
          ProductSubCategory: ProductSubCategory,
          ProductModelNumber: ProductModelNumber,
          product: product,
        })
      }
    }

    const handleAccordianClick = () => {
      this.setState((prevState) => ({
        openAccrodian: !prevState.openAccrodian,
      }))
    }
    const lolUsedMoneyFormat = parseFloat(lolUsed) > 0 ? '$' + parseFloat(lolUsed).toFixed(2) : '$0'

    const lolAvailableMoneyFormat =
      parseFloat(lolAvailable) > 0 ? '$' + parseFloat(lolAvailable).toFixed(2) : '$0'

    const totalLiabilityMoneyFormat =
      parseFloat(totalLiability) > 0 ? '$' + parseFloat(totalLiability).toFixed(2) : '$0'

    return (
      <Fragment>
        {!shouldDisplayComponent ? (
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Button className='loginBtn' onClick={() => this.microsoftLogin()}>
              Sign In For Dispatch
            </Button>
          </Grid>
        ) : (
          <React.Fragment>
            <Tabs
              value={searchTabIndex}
              onChange={this.handleSearchTabSelect}
              variant='fullWidth'
              centered
            >
              <Tab label='Dispatch' value={0} />
              <Tab label='Search' value={1} />
            </Tabs>

            {searchTabIndex === 0 && (
              <React.Fragment>
                <Form noValidate>
                  <Row className='mt-1 ps-3 pe-3'>
                    <Col md={12}>
                      <Modal show={showDispatchMessageModal} keyboard={false}>
                        <Modal.Header>
                          <Modal.Title>Important Dispatch Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row>
                            <Col>
                              <Alert variant='warning'>
                                <Alert.Heading>
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                  &nbsp;Notice
                                </Alert.Heading>
                                <p>{dispatchMessage}</p>
                              </Alert>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant='primary'
                            onClick={() => this.setState({ showDispatchMessageModal: false })}
                          >
                            I Understand.
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                  <Row
                    md={12}
                    className='sticky-top'
                    bg='white'
                    fixed='top'
                    style={{ backgroundColor: '#FFF', top: 0, zIndex: 1030 }}
                  >
                    <div style={{ display: 'flex' }}>
                      <Accordion
                        ref={this.accordianRef}
                        expanded={this.state.openAccrodian}
                        onClick={handleAccordianClick}
                        sx={{
                          '& .MuiAccordionDetails-root': {
                            padding: 0,
                            width: '100%',
                          },
                          '&.MuiPaper-root': {
                            width: '100%',
                          },
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              width: '100%',
                              padding: '5px 0px',
                              height: '2rem',
                            },
                            '& .MuiAccordionSummary-content': { margin: 0 },
                          }}
                          expandIcon={<ExpandMoreIcon sx={{ fontWeight: 700, color: '#1e384b' }} />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          <List
                            sx={{
                              display: 'flex',
                              '&.MuiList-root ': { width: '100%' },
                              padding: 0,
                            }}
                          >
                            {SelectedContractNumber?.length > 0 && (
                              <ListItem
                                sx={{
                                  padding: '0px 16px 0px 0px',
                                  margin: 0,
                                  width: '56%',
                                }}
                                key='selectedContractNumber'
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    width: '7.6rem',
                                    fontSize: '14px',
                                    marginLeft: '0.5rem',
                                  }}
                                  primary={'Contract Number: '}
                                  sx={{ flex: 'none' }}
                                />
                                <FormControl>
                                  <Select
                                    sx={{
                                      '& .MuiSelect-select': {
                                        padding: '5px 10px',
                                      },
                                    }}
                                    labelId='demo-multiple-name-label'
                                    id='demo-multiple-name'
                                    value={SelectedContractNumber || ''}
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={handleSelectedContractNumber}
                                    MenuProps={{
                                      PaperProps: {
                                        className: 'custom-scrollbar',
                                      },
                                    }}
                                  >
                                    {contractList?.map((item) => (
                                      <MenuItem
                                        value={item?.attributes?.externalId}
                                        key={item?.attributes?.externalId}
                                      >
                                        {item?.attributes?.externalId}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </ListItem>
                            )}
                            {contractStatus?.length > 0 && (
                              <ListItem
                                sx={{
                                  padding: '0px 0px 0px 0px',
                                  margin: 0,
                                  width: '35%',
                                }}
                                key='contractStatus'
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    width: '3.3rem',
                                    fontSize: '14px',
                                  }}
                                  primary={'Status : '}
                                  sx={{ flex: 'none' }}
                                />
                                <ListItemText
                                  secondaryTypographyProps={{
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    marginTop: '2px',
                                    color: contractStatus[0] !== 'A' ? '#dc3545' : '#1e384b',
                                  }}
                                  secondary={contractStatus}
                                  sx={{ flex: 'none' }}
                                />
                              </ListItem>
                            )}

                            {firstName?.length > 0 && (
                              <ListItem
                                sx={{
                                  padding: '0px 0px 0px 0px',
                                  margin: 0,
                                  width: '70%',
                                }}
                                key='firstName'
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    width: '4.6rem',
                                    fontSize: '14px',
                                  }}
                                  primary={'Full Name: '}
                                  sx={{ flex: 'none' }}
                                ></ListItemText>
                                <ListItemText
                                  secondaryTypographyProps={{
                                    fontSize: '13px',
                                    marginTop: '2px',
                                  }}
                                  secondary={firstName + ' ' + lastName}
                                  sx={{ flex: 'none' }}
                                ></ListItemText>
                              </ListItem>
                            )}
                            {address?.length > 0 && (
                              <ListItem
                                sx={{ padding: '0px 0px 0px 0px', margin: 0 }}
                                key='address'
                              >
                                <ListItemText
                                  primaryTypographyProps={{
                                    width: '4rem',
                                    fontSize: '14px',
                                  }}
                                  primary={'Address : '}
                                  sx={{ flex: 'none' }}
                                ></ListItemText>
                                <ListItemText
                                  secondaryTypographyProps={{
                                    fontSize: '13px',
                                    marginTop: '2px',
                                  }}
                                  secondary={
                                    `${address ? address + ', ' : ''}` +
                                    `${city ? city + ', ' : ''}` +
                                    `${state ? state + ', ' : ''}` +
                                    postalCode
                                  }
                                ></ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </AccordionSummary>
                        <div
                          style={{
                            border: '2px solid #e8eaf0',
                            borderRadius: '5px',
                            padding: '1rem 1rem 0.5rem 0.5rem',
                            width: '100%',
                          }}
                        >
                          <AccordionDetails
                            sx={{ '& .MuiAccordionSummary-content': { margin: 0 } }}
                          >
                            <div style={{ display: 'flex', width: '100%' }}>
                              <div style={{ width: '70%' }}>
                                <Typography sx={{ fontSize: '1.3rem' }}>Contract Info</Typography>
                                <div
                                  style={{
                                    backgroundColor: '#FBFBFB',
                                    overflow: 'auto',
                                    minHeight: '28vh',
                                    width: '100%',
                                    display: 'flex',
                                  }}
                                >
                                  <div
                                    xs={7}
                                    style={{
                                      padding: '4px 0px 4px 12px',
                                      overflow: 'none',
                                      width: '60%',
                                    }}
                                  >
                                    <List sx={{ padding: 0 }}>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='status'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '3.6rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Status : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                            fontWeight: 600,
                                            color:
                                              contractStatus[0] !== 'A' ? '#dc3545' : '#1e384b',
                                          }}
                                          secondary={contractStatus}
                                          sx={{ flex: 'none' }}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='industry'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '4.2rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Industry : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={Industry}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='coverageDescription'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '3.1rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Type : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            minWidth: '4rem',
                                            fontSize: '13px',
                                          }}
                                          secondary={coverageDescription}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='effectiveDt'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '6.5rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Effective Date : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={effectiveDt}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='expirationDt'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '7rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Expiration Date : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={expirationDt}
                                          sx={{ flex: 'none' }}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='dealerName'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '3.7rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Dealer : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={dealerName}
                                        />
                                      </ListItem>
                                    </List>
                                  </div>
                                  <div
                                    xs={5}
                                    style={{
                                      padding: '4px 0px',
                                      overflow: 'none',
                                      width: '40%',
                                    }}
                                  >
                                    <List sx={{ padding: 0 }}>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='coverageLength'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '5rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Coverage : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={
                                            TotalCoverage ? TotalCoverage / 12 + ' Year' : null
                                          }
                                          sx={{ flex: 'none' }}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='deductible'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '5rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Deductible : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={deductible}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='lolUsed'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '5rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'LOL Used : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondary={lolUsedMoneyFormat}
                                          sx={{ flex: 'none' }}
                                          secondaryTypographyProps={{
                                            noWrap: true,
                                            maxWidth: '100%',
                                            fontSize: '13px',
                                          }}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='lolAvailable'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '7rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'LOL Availability : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondary={lolAvailableMoneyFormat}
                                          sx={{ flex: 'none' }}
                                          secondaryTypographyProps={{
                                            noWrap: true,
                                            maxWidth: '100%',
                                            fontSize: '13px',
                                          }}
                                        />
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='totalLiability'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '6.2rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Total Liability : '}
                                          sx={{ flex: 'none' }}
                                        />
                                        <ListItemText
                                          secondary={totalLiabilityMoneyFormat}
                                          sx={{ flex: 'none' }}
                                          secondaryTypographyProps={{
                                            noWrap: true,
                                            maxWidth: '100%',
                                            fontSize: '13px',
                                          }}
                                        />
                                      </ListItem>
                                    </List>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: '60%', marginLeft: '-1.8rem' }}>
                                <Typography
                                  sx={{
                                    fontSize: '1.3rem',
                                    paddingLeft: '0px 0px 5px 0px',
                                    marginLeft: '-0.5rem',
                                  }}
                                >
                                  Customer Details
                                </Typography>
                                <div
                                  className='mt-0'
                                  style={{
                                    backgroundColor: '#FBFBFB',
                                    overflow: 'auto',
                                    minHeight: '28vh',
                                  }}
                                >
                                  <div xs={12} style={{ padding: '4px 0px', overflow: 'none' }}>
                                    <List sx={{ padding: '0px' }}>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='fullName'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '4.8rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Full Name: '}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={firstName + ' ' + lastName}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='fullAddress'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '4.2rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Address : '}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={
                                            `${address ? address + ', ' : ''}` +
                                            `${city ? city + ', ' : ''}` +
                                            `${state ? state + ', ' : ''}` +
                                            postalCode
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='email'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '3rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Email : '}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={email}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                      </ListItem>
                                      <ListItem
                                        sx={{
                                          padding: '0px 16px 0px 0px',
                                          margin: 0,
                                        }}
                                        key='primaryPhone'
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            width: '6.5rem',
                                            fontSize: '14px',
                                          }}
                                          primary={'Primary Phone : '}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                        <ListItemText
                                          secondaryTypographyProps={{
                                            fontSize: '13px',
                                          }}
                                          secondary={primaryPhone}
                                          sx={{ flex: 'none' }}
                                        ></ListItemText>
                                      </ListItem>
                                    </List>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </div>
                      </Accordion>
                      <div style={{ padding: '0px 0px 0px 5px', width: '4.5%' }}>
                        <Button
                          id='basic-button'
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup='true'
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          style={{
                            backgroundColor: '#e8eaf0',
                            color: 'black',
                            fontWeight: 600,
                          }}
                        >
                          . . .
                        </Button>
                        <Menu
                          id='basic-menu'
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => window.location.reload()}>
                            Start a New Service
                          </MenuItem>
                          <MenuItem onClick={handleAddNewContract}>Add a New Contract</MenuItem>
                        </Menu>
                      </div>
                    </div>

                    <Row id='NavBarId' className='d-flex justify-content-center'>
                      <Col md={12} className='d-flex justify-content-center'>
                        <Tabs
                          variant='scrollable'
                          scrollButtons={false}
                          aria-label='wrapped label tabs example'
                          value={scrollableTabValue}
                        >
                          <Tab
                            value='Product Details'
                            label='Product Details'
                            disableRipple
                            onClick={() => smoothScroll('Product Details')}
                            style={{
                              color: `${isClicked['Product Details'] ? '#007AB2' : '#1e384b'}`,
                              borderBottom: `${
                                isClicked['Product Details']
                                  ? ' 4px solid #007AB2'
                                  : '1px solid #FFF'
                              }`,
                              textTransform: 'none',
                              fontFamily: 'Open Sans, sans-serif',
                              fontSize: '18px',
                              fontWeight: 600,
                            }}
                          />
                          <Tab
                            disableRipple
                            value='Service Orders'
                            label='Service Orders'
                            style={{
                              color: `${isClicked['Service Orders'] ? '#007AB2' : '#1e384b'}`,
                              borderBottom: `${
                                isClicked['Service Orders']
                                  ? ' 4px solid #007AB2'
                                  : '1px solid #FFF'
                              }`,
                              textTransform: 'none',
                              fontFamily: 'Open Sans, sans-serif',
                              fontSize: '18px',
                              fontWeight: 600,
                            }}
                            onClick={() => smoothScroll('Service Orders')}
                          />
                          <Tab
                            disableRipple
                            value='Service Information'
                            label='Service Information'
                            style={{
                              color: `${isClicked['Service Information'] ? '#007AB2' : '#1e384b'}`,
                              borderBottom: `${
                                isClicked['Service Information']
                                  ? ' 4px solid #007AB2'
                                  : '1px solid #FFF'
                              }`,
                              textTransform: 'none',
                              fontFamily: 'Open Sans, sans-serif',
                              fontSize: '18px',
                              fontWeight: 600,
                            }}
                            onClick={() => smoothScroll('Service Information')}
                            sx={{ textTransform: 'capitalize' }}
                          />
                        </Tabs>
                      </Col>
                      <Row className='mt-2 mb-2'>
                        <Col className='text-center'>
                          {ProductSubCategory && ProductModelNumber ? (
                            <>{'(' + ProductSubCategory + ' ' + ProductModelNumber + ')'}</>
                          ) : null}
                        </Col>
                      </Row>
                    </Row>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <ContractModal
                        handleNewContractDetails={this.handleNewContractDetails}
                        kustomerEmail={kustomerEmail}
                        kustomerPhone={kustomerPhone}
                        key={contractNumber}
                        showNewContractModal={showNewContractModal}
                        updateShowNewContractModal={updateShowNewContractModal}
                      ></ContractModal>
                    </Col>
                  </Row>

                  <Row></Row>

                  <div ref={this.productDetailsRef}>
                    <Row className='mt-0 ps-3 pe-3'>
                      {selectedContract &&
                      isEffective &&
                      coverageProductTypeCodeStr.toUpperCase() === 'RSC' ? (
                        <Col md={6}>
                          <LossCodeList
                            handleLossCodeSelect={this.handleLossCodeSelect}
                            loading={loading}
                            lossCodeList={lossCodeList}
                            selectedLossCode={selectedLossCode}
                          />
                        </Col>
                      ) : null}

                      {(selectedContract &&
                        isEffective &&
                        selectedLossCode &&
                        coverageProductTypeCodeStr.toUpperCase() === 'RSC') ||
                      (selectedContract &&
                        isEffective &&
                        coverageProductTypeCodeStr.toUpperCase() !== 'RSC') ? (
                        <Col md={12} id='Product Details'>
                          <ProductList
                            condition={condition}
                            handleAddProduct={this.handleAddProduct}
                            handleInputChange={this.handleInputChange.bind(this)}
                            handleProductSelect={this.handleProductSelect}
                            isExpired={contractStatusCode !== 'A' ? true : false}
                            loading={loading}
                            manufacturers={manufacturers}
                            subCategory={subCategory}
                            productList={
                              coverageProductTypeCodeStr.toUpperCase() === 'RSC'
                                ? filteredProductList
                                : unfilteredProductList
                            }
                            selectedLossCode={
                              selectedLossCode?.code ? selectedLossCode?.code.toUpperCase() : null
                            }
                            selectedProduct={selectedProduct}
                            subcategories={subcategories}
                            getProductSubCategory={getProductSubCategory}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                  <Row className='mt-3 ps-3 pe-3'>
                    {selectedProduct ? (
                      <Col md={12}>
                        <span id='ProductError'></span>
                        <Product
                          beginDt={beginDt}
                          brand={brand.toUpperCase()}
                          endDt={endDt}
                          handleInputChange={this.handleInputChange.bind(this)}
                          handleUpdateProduct={this.handleUpdateProduct}
                          handleResetProduct={this.handleResetProduct}
                          isBrandValidated={isBrandValidated}
                          isBrandValid={isBrandValid}
                          isModelValidated={isModelValidated}
                          isModelValid={isModelValid}
                          isSubcategoryValidated={isSubcategoryValidated}
                          isSubcategoryValid={isSubcategoryValid}
                          isSerialValidated={isSerialValidated}
                          isSerialValid={isSerialValid}
                          loading={loading}
                          manufacturers={manufacturers}
                          model={model}
                          subCategory={subCategory.toUpperCase()}
                          productPurchaseDate={productPurchaseDate} // added this
                          handleProductPurchaseDate={this.handleProductPurchaseDate} //added this
                          purchasePrice={purchasePrice}
                          selectedLossCode={
                            selectedLossCode?.code ? selectedLossCode?.code.toUpperCase() : null
                          }
                          selectedProduct={selectedProduct}
                          serial={serial}
                          subcategories={subcategories}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <div ref={this.serviceOrderRef}>
                    {selectedProduct ? (
                      <Row className='mt-0 ps-3 pe-3' id='Service Orders'>
                        <Col id='Service Orders'>
                          <ServiceOrderList
                            contractNumber={contractNumber}
                            deductible={deductible}
                            deductibleTerm={deductibleTerm}
                            externalContractNumber={externalContractNumber}
                            serviceOrderList={serviceOrderList}
                            selectedServiceOrder={selectedServiceOrder}
                            handleServiceOrderSelect={this.handleServiceOrderSelect.bind(this)}
                          />
                        </Col>
                      </Row>
                    ) : null}

                    {selectedProduct ? (
                      <Row className='mt-0 ps-3 pe-3'>
                        <Col md={12} id='existingAppointment'>
                          <ExistingAppointment
                            existingAppointment={existingAppointment}
                            appointmentWorking={appointmentWorking}
                            cancelReasons={cancelReasons}
                            cancelReasonCode={cancelReasonCode}
                            contractNumber={contractNumber}
                            handleCancelAppointment={this.handleCancelAppointment.bind(this)}
                            handleInputChange={this.handleInputChange.bind(this)}
                            getAppointment={this.getAppointment.bind(this)}
                            isCancelReasonValid={isCancelReasonValid}
                            isCancelReasonValidated={isCancelReasonValidated}
                            serviceOrderNumber={serviceOrderNumber}
                            setRescheduleDialogParent={this.setRescheduleDialogParent}
                            setReassignDialogParent={this.setReassignDialogParent}
                            setCancelDialogParent={this.setCancelDialogParent}
                          ></ExistingAppointment>
                        </Col>
                      </Row>
                    ) : null}
                  </div>

                  {showCancelSuccess ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Alert variant='success'>
                          <h1>Success!</h1>
                          <p>Appointment was cancelled successfully.</p>
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {showBookSuccess ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Alert variant='success'>
                          <h1>Success!</h1>
                          <p>Appointment was booked successfully.</p>
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {showFNOLSuccess ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Alert variant='success'>
                          <h1>Success!</h1>
                          <p>{`FNOL claim ${serviceOrderNumber} created for contract ${contractNumber}.`}</p>
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  <div ref={this.serviceInformationRef}>
                    <Row className='mt-4 ps-3 pe-3'>
                      {selectedContract && selectedProduct ? (
                        <Col md={6} className='pe-5'>
                          <Service
                            bookingType={bookingType}
                            contractType={contractType}
                            failureNote={failureNote}
                            failureDt={failureDt}
                            failureType={failureType}
                            symptomCode={symptomCode}
                            symptomCodeList={symptomCodeList}
                            functionalityStatus={functionalityStatus}
                            handleFailureDateSelect={this.handleFailureDateSelect.bind(this)}
                            handleInputChange={this.handleInputChange.bind(this)}
                            isFailureNoteValid={isFailureNoteValid}
                            isFailureNoteValidated={isFailureNoteValidated}
                            isSymptomCodeValid={isSymptomCodeValid}
                            isSymptomCodeValidated={isSymptomCodeValidated}
                            serviceElevation={serviceElevation}
                            servicerNotes={servicerNotes}
                            serviceNotice={serviceNotice}
                            serviceOrderNumber={serviceOrderNumber}
                            serviceType={serviceType}
                            warrantyType={warrantyType}
                            failureTypeList={failureTypeList}
                            serviceLocationList={serviceLocationList}
                            serviceElevationList={serviceElevationList} // added this after warranty type
                            serviceNoticeList={serviceNoticeList}
                            serviceTypeList={serviceTypeList}
                            bookingTypeList={bookingTypeList}
                            warrantyTypeList={warrantyTypeList}
                            functionalityStatusList={functionalityStatusList}
                            serviceOrderList={serviceOrderList} // added this
                            selectedRepeatOrder={selectedRepeatOrder} // added this
                            procedureCode={procedureCode}
                            selectedServiceOrder={selectedServiceOrder}
                          />
                        </Col>
                      ) : null}
                      {selectedContract && selectedProduct ? (
                        <Col md={6} className='ps-5'>
                          <Customer
                            symptomCode={symptomCode}
                            symptomCodeList={symptomCodeList}
                            failureDt={failureDt}
                            isSymptomCodeValid={isSymptomCodeValid}
                            isSymptomCodeValidated={isSymptomCodeValidated}
                            handleFailureDateSelect={this.handleFailureDateSelect.bind(this)}
                            isFailureNoteValid={isFailureNoteValid}
                            isFailureNoteValidated={isFailureNoteValidated}
                            failureNote={failureNote}
                            servicerNotes={servicerNotes}
                            address={address}
                            address2={address2}
                            city={city}
                            email={email}
                            firstName={firstName}
                            handleInputChange={this.handleInputChange.bind(this)}
                            isPostalCodeValid={isPostalCodeValid}
                            isPostalCodeValidated={isPostalCodeValidated}
                            lastName={lastName}
                            postalCode={postalCode}
                            primaryPhone={primaryPhone}
                            secondaryPhone={secondaryPhone}
                            state={state}
                            customerRequestedDate={customerRequestedDate}
                            customerRequestedWindow={customerRequestedWindow}
                            customerRequestedWindowArray={customerRequestedWindowArray}
                            handleCustomerRequestedDate={this.handleCustomerRequestedDate}
                            selectedServiceOrder={selectedServiceOrder}
                          ></Customer>
                        </Col>
                      ) : null}
                    </Row>
                  </div>

                  {errorMessage.length > 0 ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Alert id='errorFound' variant='danger'>
                          {parse(errorMessage)}
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {showAppointmentList &&
                  appointmentList.length > 0 &&
                  appointmentList[0].appointmentInfo.svcrStatus?.toUpperCase() ===
                    'ONPOINT SPECIFIC SERVICER'.toUpperCase() &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
                    <Fragment>
                      <Row className='mt-3 ps-3 pe-3 mb-3'>
                        <Col md={3}>
                          <Card>
                            <Card.Header className='bg-purple text-center'>
                              <h5 className='text-white'>Depot</h5>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                Book to {appointmentList[0].appointmentInfo?.svcrName}
                              </Card.Text>
                              <Col className='text-center'>
                                <Button
                                  className='ms-1 mb-1 RoundedButton'
                                  disabled={loading}
                                  onClick={() =>
                                    this.handleDepotBookAppointment(selectedAppointment)
                                  }
                                  size='sm'
                                  variant='primary'
                                >
                                  Book
                                </Button>
                              </Col>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : null}
                  {showAppointmentList &&
                  appointmentList.length === 0 &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
                    <Fragment>
                      <Row className='mt-3 ps-3 pe-3 mb-3'>
                        <Col md={3}>
                          <Card>
                            <Card.Header className='bg-purple text-center text-white'>
                              <h5>Dispatch Alert!</h5>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                Currently, no appointments exist for this particular dispatch.
                                Please Force Book Directly to ONPOINT for further research!
                              </Card.Text>
                              <Col className='text-center'>
                                <Button
                                  className='ms-1 mb-1 RoundedButton'
                                  disabled={loading}
                                  onClick={() => this.handleForceBookAppointment()}
                                  size='sm'
                                  variant='primary'
                                >
                                  Force Book to OnPoint
                                </Button>
                              </Col>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : null}
                  {showAppointmentList &&
                  appointmentList.length > 0 &&
                  appointmentList[0].appointmentInfo.svcrStatus?.toUpperCase() !==
                    'ONPOINT SPECIFIC SERVICER'.toUpperCase() &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) ? (
                    <Fragment>
                      <Modal show={showAppointmentList} keyboard={false} size='xl'>
                        <Modal.Header>
                          <Modal.Title>Assign Servicer</Modal.Title>
                          <CloseButton
                            style={{ 'margin-right': '0px' }}
                            onClick={() => this.setState({ showAppointmentList: false })}
                          />
                        </Modal.Header>
                        <Modal.Body style={{ 'min-height': '300px', 'text-align': 'center' }}>
                          <AppointmentList
                            selectedContract={selectedContract}
                            handleNextWeek={this.getNextWeek}
                            handleLastWeek={this.getLastWeek}
                            appointmentList={appointmentList}
                            appointmentWorking={appointmentWorking}
                            beginSearchDate={beginSearchDate}
                            handleAppointmentSelect={this.handleAppointmentSelect}
                            handleBookAppointment={this.handleBookAppointment.bind(this)}
                            handleAppointmentSuccess={this.handleAppointmentSuccess.bind(this)}
                            key={beginSearchDate}
                            procedureCode={procedureCode}
                            selectedAppointment={selectedAppointment}
                            loading={this.state.loading}
                            bookingNote={this.state.bookingNote}
                            resetNotes={this.resetNotes}
                            handleInputChange={this.handleInputChange.bind(this)}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <div style={{ width: '100%' }}>
                            <div
                              style={{
                                width: '50%',
                                float: 'left',
                                'text-align': 'left',
                              }}
                            >
                              <div
                                style={{
                                  float: 'left',
                                  'margin-right': '20px',
                                  'line-height': '1.5',
                                  'font-size': '0.8rem',
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    'font-size': '1rem',
                                    'margin-right': '5px',
                                    float: 'left',
                                  }}
                                  icon={faCalendarMinus}
                                />{' '}
                                Soft Availability
                              </div>
                              <div
                                style={{
                                  float: 'left',
                                  'margin-right': '20px',
                                  'line-height': '1.5',
                                  'font-size': '0.8rem',
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: '#0dcaf0',
                                    'font-size': '1rem',
                                    'margin-right': '5px',
                                    float: 'left',
                                  }}
                                  icon={faCalendarPlus}
                                />{' '}
                                Firm Availability
                              </div>
                            </div>
                            <div
                              style={{
                                width: '50%',
                                float: 'left',
                                'text-align': 'right',
                              }}
                            ></div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </Fragment>
                  ) : null}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Outbound Shipment Only'.toUpperCase() &&
                  (deductible === 0 ||
                    (deductible > 0 &&
                      claimReimbursementMethod.toUpperCase() === 'Concession'.toUpperCase()) ||
                    (deductible > 0 &&
                      serviceNotice.toUpperCase() === 'Repeat Request'.toUpperCase()) ||
                    serviceNotice.toUpperCase() === 'Armadillo'.toUpperCase() ||
                    (deductible > 0 &&
                      serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
                      serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
                      selectedServiceOrder &&
                      selectedServiceOrder &&
                      serviceOrderClaimStatus.toUpperCase() !== 'Deductible-FNOL'.toUpperCase())) &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-2 ps-3 pe-3'>
                      <div
                        style={{
                          marginBottom: '5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.getServiceAppointments('')}
                          size='md'
                          variant='primary'
                        >
                          Find Appointment
                        </Button>
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.showForceBook()}
                          size='md'
                          variant='primary'
                        >
                          Force Book
                        </Button>
                      </div>
                    </Row>
                  ) : null}
                  {
                    <Fragment>
                      <Modal show={displayForceBook} keyboard={false} size='xl'>
                        <Modal.Header>
                          <Modal.Title>Force Book</Modal.Title>
                          <CloseButton
                            style={{ 'margin-right': '0px' }}
                            onClick={() => {
                              this.setState({
                                displayForceBook: false,
                                appointmentList: [],
                              })
                            }}
                          />
                        </Modal.Header>
                        <Modal.Body style={{ 'min-height': '300px', 'text-align': 'center' }}>
                          <ForceBook
                            findForceBook={this.getForceBookServicers}
                            appointmentWorking={appointmentWorking}
                          />
                          {showForceAppointmentList ? (
                            <ForceAppointmentList
                              selectedContract={selectedContract}
                              handleNextWeek={this.getForceNextWeek}
                              handleLastWeek={this.getForcePreviousWeek}
                              appointmentList={appointmentList}
                              appointmentWorking={appointmentWorking}
                              beginSearchDate={beginSearchDate}
                              handleAppointmentSelect={this.handleAppointmentSelect}
                              handleBookAppointment={this.handleForceBook.bind(this)}
                              handleAppointmentSuccess={this.handleAppointmentSuccess.bind(this)}
                              key={beginSearchDate}
                              procedureCode={procedureCode}
                              selectedAppointment={selectedAppointment}
                              loading={this.state.loading}
                            />
                          ) : null}
                        </Modal.Body>
                      </Modal>
                    </Fragment>
                  }
                  {assignmentDialogOpen && (
                    <AssignmentDialog
                      data={assignmentDialogData}
                      assignmentDialogOpen={assignmentDialogOpen}
                      handleClose={() => this.closeAssignmentDialog()}
                      openConfirmationDialog={this.openConfirmationDialog}
                      appointmentWorking={appointmentWorking}
                    />
                  )}
                  {ForceAssignmentDialogOpen && (
                    <ForceAssignmentDialog
                      data={ForceAssignmentDialogData}
                      ForceAssignmentDialogOpen={ForceAssignmentDialogOpen}
                      handleClose={() => this.closeForceAssignmentDialog()}
                      openConfirmationDialog={this.openConfirmationDialog}
                      appointmentWorking={appointmentWorking}
                    />
                  )}
                  {handleConfirmDialog && (
                    <ConfirmationDialog
                      handleConfirmDialog={handleConfirmDialog}
                      bookingContent={bookingContent}
                      reasonList={reasonList}
                      servicerIndex={servicerIndex}
                      handleClose={() => this.closeConfirmDialog()}
                      loading={this.state.loading}
                      appointmentWorking={appointmentWorking}
                      handleBookAppointment={this.handleBookAppointment.bind(this)}
                      handleForceBook={this.handleForceBook.bind(this)}
                      softOrForceBook={softOrForceBook}
                    />
                  )}
                  {showAppointmentSuccess && (
                    <AppointmentSuccessDialog
                      selectedContract={selectedContract}
                      showAppointmentSuccess={showAppointmentSuccess}
                      serviceOrderNumber={serviceOrderNumber}
                      externalAppointmentNumber={externalAppointmentNumber}
                      handleCloseSoft={() => this.closeAssignmentDialog()}
                      handleCloseForce={() => this.closeForceAssignmentDialog()}
                      bookingContent={bookingContent}
                      softOrForceBook={softOrForceBook}
                    />
                  )}
                  {showExistingAppointmentDialog && (
                    <ExistingAppointmentDialog
                      contractNumber={this.state.contractNumber}
                      showExistingAppointmentDialog={showExistingAppointmentDialog}
                      rescheduleDialogParent={rescheduleDialog}
                      reassignDialogParent={reassignDialog}
                      cancelDialogParent={cancelReasonDialog}
                      setRescheduleDialogParent={this.setRescheduleDialogParent}
                      closeRescheduleDialogParent={this.closeRescheduleDialogParent}
                      closeReassignDialogParent={this.closeReassignDialogParent}
                      closeCancelDialogParent={this.closeCancelDialogParent}
                      existingAppointment={existingAppointment}
                      getServicerOrderParent={() => {
                        this.getServiceOrders(contractNumber)
                        this.setState({
                          selectedServiceOrder: null,
                          existingAppointment: null,
                        })
                      }}
                    />
                  )}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Outbound Shipment Only'.toUpperCase() &&
                  deductible > 0 &&
                  claimReimbursementMethod.toUpperCase() !== 'Concession'.toUpperCase() &&
                  serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
                  serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
                  selectedServiceOrder &&
                  serviceOrderClaimStatus.toUpperCase() === 'Deductible-FNOL'.toUpperCase() &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col md={5}>
                        <Alert variant='info'>
                          <strong>Deductible not paid:</strong> The deductible is still pending
                          collection.
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() === 'Advanced Exchange'.toUpperCase() &&
                  selectedServiceOrder &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col md={5}>
                        <Alert variant='info'>
                          <strong>Advanced Exchange:</strong> No appointment needs scheduled.
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() === 'Advanced Exchange'.toUpperCase() &&
                  !selectedServiceOrder &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col xs='2'>
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.handleCreateFNOL()}
                          size='sm'
                          variant='primary'
                        >
                          Begin Exchange
                        </Button>
                      </Col>
                      <Col xs='1'>
                        <Form.Check
                          type='checkbox'
                          onChange={() => {
                            this.setState({ hasConcessionFlg: !hasConcessionFlg })
                          }}
                          label='Concession'
                          checked={hasConcessionFlg}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() === 'Standard Exchange'.toUpperCase() &&
                  !selectedServiceOrder &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.handleCreateFNOL()}
                          size='sm'
                          variant='primary'
                        >
                          Initiate Standard Exchange
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  {!showAppointmentList &&
                  serviceType.toUpperCase() === 'Outbound Shipment Only'.toUpperCase() &&
                  !selectedServiceOrder &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col>
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.handleCreateFNOL()}
                          size='sm'
                          variant='primary'
                        >
                          Initiate Outbound Shipment Only
                        </Button>
                      </Col>
                    </Row>
                  ) : null}

                  {!showAppointmentList &&
                  serviceType.toUpperCase() !== 'Advanced Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Standard Exchange'.toUpperCase() &&
                  serviceType.toUpperCase() !== 'Outbound Shipment Only'.toUpperCase() &&
                  deductible > 0 &&
                  serviceNotice.toUpperCase() !== 'Repeat Request'.toUpperCase() &&
                  serviceNotice.toUpperCase() !== 'Armadillo'.toUpperCase() &&
                  !selectedServiceOrder &&
                  (!existingAppointment ||
                    (existingAppointment?.forcedBookingFlag === 'NO' &&
                      existingAppointment?.appointmentTimeSlot !== 'CANCELLED')) &&
                  selectedProduct &&
                  !appointmentWorking ? (
                    <Row className='mt-3 ps-3 pe-3'>
                      <Col xs='2'>
                        <Button
                          className='ms-1 mb-1 RoundedButton'
                          disabled={loading}
                          onClick={() => this.handleCreateFNOL()}
                          size='sm'
                          variant='primary'
                        >
                          Collect Deductible
                        </Button>
                      </Col>
                      <Col xs='1'>
                        <Form.Check
                          type='checkbox'
                          onChange={() => {
                            this.setState({ hasConcessionFlg: !hasConcessionFlg })
                          }}
                          label='Concession'
                          checked={hasConcessionFlg}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Form>
                <Row>
                  <Col className='text-center'>
                    <small>V2.08.11.22</small>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {searchTabIndex === 1 && <Panel />}
          </React.Fragment>
        )}
      </Fragment>
    )
  }

  getWidgetDisplay() {
    return (
      <Fragment>
        <Navbar bg='white' fixed='top'>
          <Container>
            <Navbar.Brand>
              <Image src={logo} fluid />
            </Navbar.Brand>
            <Navbar.Toggle />
            withStyles
            <Navbar.Collapse className='justify-content-end'>
              <Navbar.Text>
                <Button className='RoundedButton' onClick={() => window.location.reload()}>
                  <FontAwesomeIcon icon={faPlus} /> Start a New Service
                </Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Row className='mt-3'>
          <Col md={6}>
            <Alert variant='warning'>
              Service can only be scheduled from a customer conversation.
            </Alert>
          </Col>
        </Row>
      </Fragment>
    )
  }

  showPage() {
    const { contextType } = this.state

    return (
      <Container fluid className='AppSetting'>
        {contextType !== 'widget' ? this.getDisplay() : null}
        {contextType === 'widget' ? this.getWidgetDisplay() : null}
      </Container>
    )
  }

  showNoAccess() {
    return (
      <Container className='mt-4'>
        <Row>
          <Col>
            <Alert variant='danger'>You do not have access.</Alert>
          </Col>
        </Row>
      </Container>
    )
  }

  getServiceInformationDropdowns = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      },
    }
    const arr = [
      'failureTypeList',
      'serviceLocationList',
      'serviceElevationList',
      'serviceNoticeList',
      'bookingTypeList',
      'functionalityStatusList',
      'warrantyTypeList',
      'serviceTypeList',
    ]

    for (const item of arr) {
      try {
        const template = item.charAt(0).toUpperCase() + item.slice(1, item.length)
        const url = `${process.env.REACT_APP_API_SERVICE_INFORMATION_DROPDOWN_URL}${
          'get' + template
        }`
        const response = await axios.get(url, config)

        if (response && response?.data?.isSuccess) {
          this.setState({ [item]: response?.data?.items })
        }
      } catch (error) {
        // Handle errors if any
        console.error(`Error fetching data for ${item}:`, error.message)
        this.setState({
          errorMessage: `<br /><b>Error with getting ${item}:</b><br />${error.message}`,
        })
        window.location.href = '#errorFound'
      }
    }
  }

  showForceBook = () => {
    if (this.validatePage()) {
      this.setState({ displayForceBook: true })
      this.setState({ loading: false })
    }
  }

  getForceBookServicers = async (option, value, beginDt) => {
    this.setState({ appointmentWorking: true })
    try {
      if (this.validatePage()) {
        let selectedAppointment = null

        // const token = '2fbd315d-9a1c-4583-b188-7f354efd8b07';

        // Axios request configuration
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('loginToken')}`,
          },
        }

        // eslint-disable-next-line no-unused-vars
        const contractDetail = await axios.post(
          `${process.env.REACT_APP_API_DEVELOPMENT_URL}verifyContractDetails`,
          {
            serviceAvengerContractNumber: this.state.contractNumber,
          },
          config,
        )
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
        const offsetInMinutes = new Date().getTimezoneOffset()
        const reqBody = {
          [option]: value,
          customer: {
            zipCode: this.state.zipCode,
          },
          product: {
            brandCode: this.state.brand,
            tierCode: this.state?.product?.tierCode,
            productCode: this.state.productCode,
          },
          service: {
            beginSearchDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            numberOfDays: 7,
          },
          warranty: {
            contractNumber: this.state.contractNumber,
          },
          type: 'forced',
          timeZone: timeZone,
          timeZoneMinuteDifference: offsetInMinutes,
        }
        const response = await axios.post(
          `${process.env.REACT_APP_API_SDPROVIDER_URL}v2/getWeeklyAvailabilityByServicer`,
          reqBody,
          config,
        )
        if (response && response?.data?.isSuccess && response?.data?.data) {
          const today = beginDt ? beginDt : moment()
          const dateArray = []
          let currentDay = null
          // Loop through the next 6 days
          for (let i = 0; i < 14; i++) {
            const currentDate = today.clone().add(i, 'days')
            currentDay = currentDate.format('dddd')

            dateArray.push({
              date: currentDate.format('YYYY-MM-DD'),
              day: currentDate.format('dddd'),
              // data: response?.data?.data?.currentDay,
              availability: response?.data?.data[currentDay] || [],
            })
          }

          selectedAppointment = dateArray[0]
          this.setState({
            appointmentWorking: false,
            displayForceBook: false,
            ForceAssignmentDialogOpen: true,
            ForceAssignmentDialogData: dateArray,
            selectedAppointment,
            softOrForceBook: 'force',
          })
        } else {
          toast.error(response.data.message || 'Something Went Wrong', {
            duration: 5000,
          })
          this.setState({
            appointmentWorking: false,
          })
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error with getting ${'Force-Book-Appointments'}:</b><br/>${
          error.message
        }`,
        appointmentWorking: false,
        displayForceBook: false,
      })
      window.location.href = '#errorFound'
    }
  }

  getForceNextWeek = () => {
    const futureDate = moment(
      this.state.appointmentList[this.state.appointmentList.length - 1]?.date,
    )
    let newArray = [...this.state.appointmentList]
    let date
    for (let i = 0; i < 7; i++) {
      const currentDate = futureDate.clone().add(i + 1, 'days')
      date = currentDate.format('YYYY-MM-DD')
      newArray[i].date = date
    }
    let selectedAppointment = newArray[0]
    this.setState({
      appointmentList: newArray,
      selectedAppointment, // first date
      showForceAppointmentList: true,
      beginSearchDate: newArray[newArray.length - 1]?.date,
    })
  }

  getForcePreviousWeek = () => {
    const futureDate = moment(this.state.appointmentList[0]?.date)
    let newArray = [...this.state.appointmentList]
    let date
    for (let i = 0; i < 7; i++) {
      const currentDate = futureDate.clone().subtract(i + 1, 'days')
      date = currentDate.format('YYYY-MM-DD')
      newArray[6 - i].date = date
    }
    let selectedAppointment = newArray[0]
    this.setState({
      appointmentList: newArray,
      selectedAppointment, // first date
      showForceAppointmentList: true,
      beginSearchDate: newArray[0]?.date,
    })
  }

  resetNotes = () => {
    this.setState({ bookingNote: '' })
  }

  setRescheduleDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: true,
      rescheduleDialog: true,
    })
  }

  closeRescheduleDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: false,
      rescheduleDialog: false,
    })
  }

  setReassignDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: true,
      reassignDialog: true,
    })
  }

  closeReassignDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: false,
      reassignDialog: false,
    })
  }

  setCancelDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: true,
      cancelReasonDialog: true,
    })
  }

  closeCancelDialogParent = () => {
    this.setState({
      showExistingAppointmentDialog: false,
      cancelReasonDialog: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.contextType !== '' && this.state.orgid === process.env.REACT_APP_ORG_ID
          ? this.showPage()
          : this.showNoAccess()}
      </React.Fragment>
      // this.showPage()
    )
  }
}

export default App
