import React from 'react'
import moment from 'moment'
import { Col, Row, Spinner, Modal, Button, Form, CloseButton } from 'react-bootstrap'
import ForceAppointmentDay from './ForceAppointmentDay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

class ForceAppointmentList extends React.Component {
  state = {
    dates: [],
    showPopupAppointment: false,
    selectedAppointment: null,
    showAppointmentSuccess: false,
    servicerData: null,
    displayDate: null,
  }
  componentDidMount() {
    this.filterDates()
  }

  componentDidUpdate(prevProps) {
    if (this.props.appointmentList !== prevProps.appointmentList) {
      this.setState({ dates: this.props.appointmentList })
    }
  }

  componentWillUnmount() {
    this.setState({ dates: [] })
  }

  filterDates() {
    this.setState({ dates: this.props.appointmentList })
  }

  displayGrid() {
    const {
      beginSearchDate,
      bookedAppointment,
      handleNextWeek,
      handleLastWeek,
      handleAppointmentSelect,
      selectedAppointment,
    } = this.props
    let showLast = true
    if (moment(beginSearchDate).isSameOrBefore(moment().format('MM/DD/YYYY'))) {
      showLast = false
    }
    return this.state.dates.map((date, i) => {
      return (
        <ForceAppointmentDay
          key={date.date}
          date={date}
          showLast={showLast}
          index={i}
          total={this.state.dates.length}
          handleNextWeek={handleNextWeek}
          handleLastWeek={handleLastWeek}
          handleAppointmentSelect={handleAppointmentSelect}
          selectedAppointment={selectedAppointment}
          bookedAppointment={bookedAppointment}
          showPopupAppointmentDetail={this.showPopupAppointmentDetail.bind(this)}
        />
      )
    })
  }

  showPopupAppointmentDetail = async (appointment, data, date) => {
    this.setState({
      showPopupAppointment: true,
      selectedAppointment: appointment,
      servicerData: data,
      displayDate: date,
    })
  }

  render() {
    const {
      appointmentWorking,
      loading,
      handleBookAppointment,
      handleAppointmentSuccess,
      selectedContract,
    } = this.props
    const {
      showPopupAppointment,
      showAppointmentSuccess,
      selectedAppointment,
      serviceOrderNumber,
      externalAppointmentNumber,
      displayDate,
      servicerData,
      bookingNote,
    } = this.state
    const { customer } = selectedContract
    const { email, firstName, lastName, phone } = customer
    return (
      <React.Fragment>
        <Modal
          show={showPopupAppointment}
          keyboard={false}
          size='sm'
          style={{ 'margin-top': '10px' }}
        >
          <Modal.Header>
            {selectedAppointment && (
              <Modal.Title
                as={Row}
                style={{
                  'font-size': '0.9rem',
                  'text-align': 'end',
                }}
              >
                <CloseButton
                  style={{ 'margin-right': '5px' }}
                  onClick={() => {
                    this.setState({
                      showPopupAppointment: false,
                      selectedAppointment: null,
                      bookingNote: '',
                    })
                  }}
                />
                <div
                  style={{
                    margin: 'auto',
                    width: '100%',
                    'text-align': 'center',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      'font-size': '3.5rem',
                      background: 'aliceblue',
                      padding: '10px 15px 10px 15px',
                      'border-radius': '50%',
                      'margin-bottom': '5px',
                    }}
                    icon={faCalendar}
                  />
                </div>
                <p
                  style={{
                    'text-align': 'center',
                    'margin-bottom': '0px',
                  }}
                >
                  Do you want to schedule appointment for{' '}
                  {moment(displayDate).format('dddd, MMMM DD, YYYY')} ?
                </p>
              </Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                'background-color': 'aliceblue',
                'border-radius': '10px',
              }}
            >
              {selectedAppointment && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'font-weight': 'bold',
                    'margin-bottom': '5px',
                    'padding-top': '5px',
                    'padding-bottom': '5px',
                    'background-color': 'aqua',
                    'border-top-left-radius': '10px',
                    'border-top-right-radius': '10px',
                  }}
                >
                  {servicerData.svcrName}
                </p>
              )}
              {selectedAppointment && (
                <p
                  style={{
                    'text-align': 'center',
                    'font-size': '0.6rem',
                    'font-weight': 'bold',
                    'margin-bottom': '5px',
                  }}
                >
                  <b style={{ 'margin-right': '3px' }}>Date :</b>
                  {displayDate}
                </p>
              )}
            </div>
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '5px',
                  'margin-top': '10px',
                  'text-align': 'center',
                }}
              >
                <b>Enter Notes Here</b>
              </p>
            )}
            {selectedAppointment && (
              <Form.Group controlId='appointmentNotes'>
                <Col xs={12}>
                  <Form.Control
                    as='textarea'
                    value={bookingNote}
                    onChange={(event) => {
                      this.setState({ bookingNote: event.target.value })
                    }}
                    size='xl'
                    tabIndex='7'
                  />
                </Col>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ width: '100%', 'text-align': 'center' }}>
              <Button
                style={{
                  'margin-right': '20px',
                }}
                className='cancleButton'
                onClick={() =>
                  this.setState({ showPopupAppointment: false, selectedAppointment: null })
                }
              >
                No
              </Button>
              <Button
                variant='primary'
                onClick={async () => {
                  this.setState({ showPopupAppointment: false })
                  let resp = await handleBookAppointment(
                    selectedAppointment,
                    servicerData,
                    displayDate,
                    bookingNote,
                  )
                  if (
                    resp &&
                    (resp.serviceOrderNumber !== null || resp.serviceOrderNumber !== undefined)
                  ) {
                    this.setState({
                      showAppointmentSuccess: true,
                      serviceOrderNumber: resp.serviceOrderNumber,
                      externalAppointmentNumber: resp.externalAppointmentNumber,
                    })
                  }
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showAppointmentSuccess}
          keyboard={false}
          size='sm'
          style={{ 'margin-top': '50px' }}
        >
          <Modal.Header>
            {selectedAppointment && (
              <Modal.Title
                as={Row}
                style={{
                  'font-size': '0.8rem',
                  'text-align': 'center',
                  'font-weight': 'bold',
                }}
              >
                <div
                  style={{
                    margin: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      'font-size': '3.5rem',
                      float: 'left',
                      background: 'aliceblue',
                      padding: '10px 15px 10px 15px',
                      'border-radius': '50%',
                      'margin-bottom': '5px',
                    }}
                    icon={faCalendar}
                  />
                </div>
                <p style={{ 'margin-bottom': '0' }}>
                  {servicerData.svcrName} has been scheduled for
                </p>
                {serviceOrderNumber && (
                  <p style={{ 'margin-bottom': '0' }}>Service Order : {serviceOrderNumber}</p>
                )}
                <p style={{ 'margin-bottom': '0' }}>{displayDate}</p>
                {externalAppointmentNumber && externalAppointmentNumber.split('-').length > 1 && (
                  <p style={{ 'margin-bottom': '0' }}>
                    {servicerData.svcrName} Order Number : {externalAppointmentNumber.split('-')[1]}
                  </p>
                )}
              </Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '10px',
                  'text-align': 'center',
                }}
              >
                Dispatch notification has been sent to the Consumer {firstName || ''}{' '}
                {lastName || ''} at
              </p>
            )}
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '5px',
                  'text-align': 'center',
                }}
              >
                {phone || ''}
              </p>
            )}
            {selectedAppointment && (
              <p
                style={{
                  'font-size': '0.6rem',
                  'font-weight': 'bold',
                  'margin-bottom': '0',
                  'margin-top': '5px',
                  'text-align': 'center',
                }}
              >
                and {email || ''}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ width: '100%', 'text-align': 'center' }}>
              <Button
                variant='primary'
                onClick={() => {
                  handleAppointmentSuccess()
                  this.setState({
                    showAppointmentSuccess: false,
                  })
                }}
              >
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        {loading === false ? (
          <>
            {appointmentWorking && (
              <Row className='mt-5 ps-3 pe-3'>
                <Col>
                  <Spinner animation='border'></Spinner>
                  <br />
                  <span className='spinner-text'>Booking appointment...</span>
                </Col>
              </Row>
            )}
            {!appointmentWorking && <Row className='ps-3 pe-3 mt-2'>{this.displayGrid()}</Row>}
          </>
        ) : (
          <Spinner style={{ 'margin-top': '100px' }} animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        )}
      </React.Fragment>
    )
  }
}

export default ForceAppointmentList
